import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { Illustration, Card, Box } from '@src/common/components';
import { t } from '@src/messages';

type ErrorBoundaryType = 'global' | 'view';

interface IErrorBoundaryProps extends RouteComponentProps {
  type: ErrorBoundaryType;
  onError?: (error: Error, info: React.ErrorInfo) => void;
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundaryBase extends React.PureComponent<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  override componentDidUpdate(prevProps: IErrorBoundaryProps) {
    const isDifferentLocation = this.props.location !== prevProps.location;
    const { hasError } = this.state;

    if (hasError && isDifferentLocation) {
      this.setState({
        hasError: false,
      });
    }
  }

  override componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.onError?.(error, info);
  }

  override render() {
    const { children, type } = this.props;
    const { hasError } = this.state;

    const errorBoundaries: Record<ErrorBoundaryType, React.ReactNode> = {
      global: (
        <Box pt="small" pl="small" pr="small" pb="small">
          <Card hasNoHeader fullHeight>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="94.5vh"
            >
              <Illustration
                type="page-not-available"
                size="large"
                title={t.common.errorBoundary.title()}
                subtitle={t.common.errorBoundary.subtitle()}
              />
            </Box>
          </Card>
        </Box>
      ),
      view: (
        <Card hasNoHeader fullHeight>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Illustration
              type="page-not-available"
              size="large"
              title={t.common.errorBoundary.title()}
              subtitle={t.common.errorBoundary.subtitle()}
            />
          </Box>
        </Card>
      ),
    };

    if (hasError) {
      return errorBoundaries[type];
    }

    return children;
  }
}

export const ErrorBoundary = withRouter(ErrorBoundaryBase);
