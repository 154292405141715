import React from 'react';

import { EmptySection } from '../EmptySection';
import { Card } from '../Card';

interface INeedAspClientRegisterProps {
  title: string;
  description: string;
}

export const NeedAspClientRegister = ({
  title,
  description,
}: INeedAspClientRegisterProps) => (
  <Card fullHeight>
    <EmptySection
      type="no-setup"
      size="large"
      title={title}
      subtitle={description}
    />
  </Card>
);
