import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import styled, { createGlobalStyle } from 'styled-components';
import { ApolloProvider } from '@apollo/client';
import { IconSettings } from '@salesforce/design-system-react';

import '@src/assets/styles/atlas-font.css';
import {
  ChannelLimitsProvider,
  LaunchDarklyProvider,
  ToastProvider,
  UserProvider,
} from '@src/common/components';

import { bffApolloClient, I18nextProvider } from './appSetup';
import { Routes } from './Routes';
import { ErrorBoundary } from './ErrorBoundary';
import { PermissionDenied } from './PermissionDenied';
import { CookiesBlocked } from './CookiesBlocked';
import { InternalError } from './InternalError';
import { WindowSizeProvider } from './common/components/WindowSizeProvider';

const history = createBrowserHistory();

const handleError = (error: Error, info: React.ErrorInfo) => {
  console.log('Error: ', error, ' Info: ', info);
};

const GlobalStyle = createGlobalStyle`
  html, body, #react-root {
    height: 100%;

    .slds-popover_tooltip[id$='--last'] {
      &::before, &::after {
        left: initial;
        right: 1.2rem;
      }
    },
  }

  .rtl {
    direction: rtl
  }

  .slds-nav-vertical__action:focus {
    text-decoration: none;
  }
`;

const PreloadAtlasRegular = styled.span({
  opacity: 0,
  fontFamily: 'AtlasGroteskWeb',
  fontWeight: 400,
});
const PreloadAtlasMedium = styled.span({
  opacity: 0,
  fontFamily: 'AtlasGroteskWeb',
  fontWeight: 500,
});

export const App = () => {
  const sessionContent = (
    <WindowSizeProvider>
      <UserProvider>
        <LaunchDarklyProvider>
          <I18nextProvider>
            <Switch>
              <Route path="/permission-denied" component={PermissionDenied} />
              <IconSettings iconPath="/assets/icons">
                <ToastProvider>
                  <ChannelLimitsProvider>
                    <GlobalStyle />
                    <PreloadAtlasRegular />
                    <PreloadAtlasMedium />
                    <ErrorBoundary type="global" onError={handleError}>
                      <Routes />
                    </ErrorBoundary>
                  </ChannelLimitsProvider>
                </ToastProvider>
              </IconSettings>
            </Switch>
          </I18nextProvider>
        </LaunchDarklyProvider>
      </UserProvider>
    </WindowSizeProvider>
  );
  const noContent = <CookiesBlocked />;
  return (
    <Router history={history}>
      <Switch>
        <Route path="/error" component={InternalError} />
        <ApolloProvider client={bffApolloClient}>
          {navigator.cookieEnabled ? sessionContent : noContent}
        </ApolloProvider>
      </Switch>
    </Router>
  );
};
