import React, { ReactNode, createContext } from 'react';

import { IChannelLimitsResponse as BffChannelLimitsResponse } from '@shared/bff';

import { CHANNEL_LIMITS_QUERY, IChannelLimitsResponse } from '../../graphql';
import { IQueryHandlerResult, QueryHandler } from '../QueryHandler';

interface IChannelLimitsProviderProps {
  children?: ReactNode;
}

interface IChannelLimitsContext extends BffChannelLimitsResponse {
  updateChannelLimits: () => void;
}

// @ts-expect-error default value is not needed
export const ChannelLimitsContext = createContext<IChannelLimitsContext>();

export const ChannelLimitsProvider = ({
  children,
}: IChannelLimitsProviderProps) => {
  return (
    <QueryHandler query={CHANNEL_LIMITS_QUERY} levitate>
      {({
        data: { channelLimits },
        refetch,
      }: IQueryHandlerResult<IChannelLimitsResponse>) => {
        const { viberChannels, whatsAppChannels } = channelLimits;
        return (
          <ChannelLimitsContext.Provider
            value={{
              viberChannels,
              whatsAppChannels,
              updateChannelLimits: refetch,
            }}
          >
            {children}
          </ChannelLimitsContext.Provider>
        );
      }}
    </QueryHandler>
  );
};
