import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { t } from '@src/messages';
import { IChannelLimits } from '@shared/bff';

import { Channel, ICrListItemFragment, IListItemFragment } from '../../types';
import { channelRequestPath } from '../../utils';
import { PageHeader } from '../PageHeader';
import { Button } from '../Button';
import { Box } from '../Box';
import { Card } from '../Card';
import { ChannelLimitTag } from '../ChannelLimitTag';
import { ScopedNotification } from '../ScopedNotification';
import { ChannelLimitsContext } from '../ChannelLimitsProvider';
import { NeedAspClientRegister } from '../NeedAspClientRegister';
import { UserContext } from '../UserProvider';

import { PendingChannels } from './PendingChannels';
import { OnboardedChannels } from './OnboardedChannels';

interface IChannelListProps {
  channelType: Channel.ViberBusiness | Channel.Whatsapp | Channel.KakaoTalk;
  needToRegisterAccount?: boolean;
  pendingChannels: ICrListItemFragment[];
  onboardedChannels: IListItemFragment[];
}

const CardWrapper = styled(Card)({
  minHeight: '40vh',
});

export const ChannelsList = ({
  channelType,
  needToRegisterAccount,
  pendingChannels,
  onboardedChannels,
}: IChannelListProps) => {
  const history = useHistory();
  const { viberChannels, whatsAppChannels } = useContext(ChannelLimitsContext);
  const { user } = useContext(UserContext);
  const MapChannelLimitations: Record<Channel, IChannelLimits> = {
    [Channel.ViberBusiness]: viberChannels,
    [Channel.Whatsapp]: whatsAppChannels,
    [Channel.RCS]: { consumed: 0, purchased: 0, registered: 0 },
    [Channel.KakaoTalk]: { consumed: 0, purchased: 2, registered: 0 },
  };

  const { consumed, purchased } = MapChannelLimitations[channelType];
  const noPurchasedChannels = purchased === 0;
  const channelsLimitReached = consumed >= purchased;
  const readOnlyAccess = user.permissions.view && !user.permissions.update;

  return (
    <>
      <PageHeader
        channelType={channelType}
        sectionType="channel"
        title={
          <Box display="inline-flex">
            {t.common.channels()}
            <Box mr="x-small" />
            <ChannelLimitTag
              consumed={consumed}
              purchased={purchased}
              inverted
            />
          </Box>
        }
        actions={
          <Button
            id="new-channel-button"
            label={t.common.actions.newChannel()}
            variant="brand"
            disabled={
              readOnlyAccess ||
              needToRegisterAccount ||
              noPurchasedChannels ||
              channelsLimitReached
            }
            onClick={() => {
              history.push(channelRequestPath(channelType));
            }}
          />
        }
      />
      {readOnlyAccess && (
        <>
          <Box mt="small" />
          <ScopedNotification variant="info">
            {t.common.readOnlyAccessNotification()}
          </ScopedNotification>
        </>
      )}
      {channelsLimitReached && !noPurchasedChannels && (
        <>
          <Box mt="small" />
          <ScopedNotification variant="warning">
            {t.common.channelLimitNotification()}
          </ScopedNotification>
        </>
      )}
      {noPurchasedChannels && (
        <>
          <Box mt="small" />
          <ScopedNotification variant="info">
            {t.common.noPurchasedAppsNotification()}
          </ScopedNotification>
        </>
      )}
      <Box mt="small" />
      {needToRegisterAccount && channelType !== Channel.KakaoTalk ? (
        <NeedAspClientRegister
          title={t.common.components.needAccountRegister[channelType].title()}
          description={t.common.components.needAccountRegister[
            channelType
          ].description()}
        />
      ) : (
        <>
          <CardWrapper
            heading={t.common.listings.headers.pendingChannels()}
            fullHeight
          >
            <PendingChannels
              channelType={channelType}
              pendingChannels={pendingChannels}
              id="pending-channels"
            />
          </CardWrapper>
          <Box mt="small" />
          <CardWrapper
            heading={t.common.listings.headers.onboardedChannels()}
            fullHeight
          >
            <OnboardedChannels
              channelType={channelType}
              onboardedChannels={onboardedChannels}
              id="onboarded-channels"
            />
          </CardWrapper>
        </>
      )}
    </>
  );
};
