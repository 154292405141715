import React, { ReactNode, useContext } from 'react';
import {
  Datepicker,
  IDatepickerProps,
  OnDatepickerChangeCallback,
  Tooltip,
} from '@salesforce/design-system-react';
import {
  FieldHelperProps,
  FieldInputProps,
  useField,
  useFormikContext,
} from 'formik';
import styled from 'styled-components';

import { formatUserInputDate, isIsoWeekday, uuid } from '@src/common/utils';

import { Box } from '../Box';
import { UserContext } from '../UserProvider';

import { RequiredFieldLabel } from './RequiredFieldLabel';

const DatepickerWrapper = styled.div({
  '& .slds-dropdown-trigger': {
    width: '100%',
  },
});

interface IDatepickerFieldProps<TObject> extends IDatepickerProps {
  name: keyof TObject & string;
  tooltip?: ReactNode;
}

const fieldToDatepicker = (
  { value, onBlur, ...field }: FieldInputProps<string>,
  touched: boolean,
  { setValue, setTouched }: FieldHelperProps<string>,
  {
    tooltip,
    labels: rawLabels,
    required,
    ...props
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IDatepickerFieldProps<any>,
  locale: string
) => {
  // Label can only be as a string, but we need to handle required fields
  const labelText =
    required && rawLabels?.label ? (
      <RequiredFieldLabel>{rawLabels.label}</RequiredFieldLabel>
    ) : (
      rawLabels?.label
    );
  const label = tooltip ? (
    <Box display="inline-flex">
      <Box pr="x-small" display="inline">
        {labelText}
      </Box>
      <Tooltip
        id={`${uuid()}-level-help-tooltip`}
        align="top left"
        content={tooltip}
        variant="learnMore"
      />
    </Box>
  ) : (
    labelText
  );

  const onChange: OnDatepickerChangeCallback = async (_, { date }) => {
    const selectedDate = formatUserInputDate(date, 'LL');
    // We need to wait until value is set. Otherwise it will set the field to touched before the value is set, resulting in an error
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await setValue(selectedDate);
    setTouched(true);
  };

  const onClose = () => {
    if (!value && !touched) {
      return;
    }
    setTouched(true);
  };

  const dateFormatter = (date: Date) => formatUserInputDate(date, 'LL');
  const dateParser = (dateString: string) => new Date(dateString);

  return {
    value: value && value.length > 0 ? new Date(value) : '',
    labels: {
      ...rawLabels,
      label: label as string,
    },
    isIsoWeekday: isIsoWeekday(locale),
    formatter: dateFormatter,
    parser: dateParser,
    ...field,
    ...props,
    onChange,
    onClose,
  };
};

// any is required for component to work properly in cases
// where no generic parameter is provided
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DatepickerField<TObject = any>(
  props: IDatepickerFieldProps<TObject>
) {
  const [field, meta, helpers] = useField(props.name);
  const { error, touched } = meta;
  const { submitCount } = useFormikContext();
  const { user } = useContext(UserContext);
  const { locale } = user;

  return (
    <DatepickerWrapper>
      <Datepicker
        {...fieldToDatepicker(field, touched, helpers, props, locale)}
        align="right"
      />
      {(touched || submitCount > 0) && (
        <div className="slds-has-error">
          <div className="slds-form-element__help slds-has-error">{error}</div>
        </div>
      )}
    </DatepickerWrapper>
  );
}
