import React from 'react';
import { IStep } from '@salesforce/design-system-react';
import { t } from 'messages';

import { CrState } from '@shared/bff';

import { ProgressCard } from '../ProgressCard';

interface IChannelProgressProps {
  state: CrState;
  steps: IStep[];
}

export const ChannelProgress = ({ state, steps }: IChannelProgressProps) => {
  const hideChannelProgress = [CrState.REJECTED, CrState.ROLLBACK].includes(
    state
  );

  if (hideChannelProgress) {
    return null;
  }

  return (
    <ProgressCard
      heading={t.whatsapp.channels.approvalProgress.title()}
      description={t.whatsapp.channels.approvalProgress.subtitle()}
      steps={steps}
      currentStepId={
        state === CrState.BOT_CR_UNSPECIFIED_STATE ? undefined : state
      }
    />
  );
};
