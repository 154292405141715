import React, { ReactNode } from 'react';
import { DataTableCell as SFDataTableCell } from '@salesforce/design-system-react';
import styled from 'styled-components';

interface ICellProps {
  children?: ReactNode;
}

// @ts-expect-error no description provided before lint rule
const SFDataTableCellWrapper = styled(SFDataTableCell)({
  whiteSpace: 'normal !important',
  paddingLeft: '16px !important',
});

export const Cell = ({ children }: ICellProps) => {
  return <SFDataTableCellWrapper>{children}</SFDataTableCellWrapper>;
};

Cell.displayName = SFDataTableCell.displayName;
