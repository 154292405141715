import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { channelPath, homePath } from '@src/common/utils';
import { useFeatureFlags } from '@src/common/utils/hooks';
import { Channel } from '@src/common/types';
import { HomeView } from '@src/modules/home';
import { Routes as WhatsAppRoutes } from '@src/modules/whatsapp';
import { Routes as ViberRoutes } from '@src/modules/viberBusiness';
import { Routes as KakaoTalkRoutes } from '@src/modules/kakaotalk';

import { TestFormik } from './TestFormik';

export const Subroutes = () => {
  const { viberBusiness, kakaoTalk } = useFeatureFlags();

  return (
    <Switch>
      {window.ENVIRONMENT === 'localhost' && (
        <Route path="/testFormik" component={TestFormik} />
      )}
      <Route path={homePath} component={HomeView} />
      <Route path={channelPath(Channel.Whatsapp)}>
        <WhatsAppRoutes path={channelPath(Channel.Whatsapp)} />
      </Route>
      {viberBusiness && (
        <Route path={channelPath(Channel.ViberBusiness)}>
          <ViberRoutes path={channelPath(Channel.ViberBusiness)} />
        </Route>
      )}
      {kakaoTalk && (
        <Route path={channelPath(Channel.KakaoTalk)}>
          <KakaoTalkRoutes path={channelPath(Channel.KakaoTalk)} />
        </Route>
      )}
      <Route render={() => <Redirect to={homePath} />} />
    </Switch>
  );
};
