import { setLocale } from 'yup';

import { t } from '@src/messages';

const { mixed, string, array } = t.common.validation;

export const initializeYupErrorMessages = () => {
  setLocale({
    mixed: {
      default: mixed.default(),
      required: mixed.required(),
    },
    string: {
      email: string.email(),
      url: string.url(),
      length: ({ length }) => string.length(length.toLocaleString()),
      min: ({ min }) => string.min(min.toLocaleString()),
      max: ({ max }) => string.max(max.toLocaleString()),
    },
    array: {
      max: ({ max }) => array.max(max.toLocaleString()),
    },
  });
};
