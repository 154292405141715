import { t } from '@src/messages';

export type ErrorCode = 'MAX_APPS_REACHED' | 'MAX_CHANNELS_REACHED';

export const errorCodes: ErrorCode[] = [
  'MAX_APPS_REACHED',
  'MAX_CHANNELS_REACHED',
];

export const senderErrorCodesExplainer: Record<ErrorCode, () => string> = {
  MAX_APPS_REACHED: t.common.errors.maxAppsReached,
  MAX_CHANNELS_REACHED: t.common.errors.maxChannelsReached,
};
