import { useFlags } from 'launchdarkly-react-client-sdk';

interface IFeatureFlags {
  sfPilot: boolean;
  sfGa: boolean;
  viberBusiness: boolean;
  channelLimits: boolean;
  autoReply: boolean;
  allowAccountCreationWithNoPurchasedChannels: boolean;
  deleteWaTemplate: boolean;
  whatsAppSamples: boolean;
  emailNotifications: boolean;
  extraDocuments: boolean;
  whatsAppQualityInformation: boolean;
  kakaoTalk: boolean;
  disabledCountryCodes: string;
  whatsAppNewTemplateCategories: boolean;
  whatsAppChannelMakeCompanyDetailsOptional: boolean;
}

export const useFeatureFlags = (): IFeatureFlags => useFlags() as IFeatureFlags;
