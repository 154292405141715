import React from 'react';

import { Illustration, Card, Box } from '@src/common/components';

export const CookiesBlocked = () => {
  return (
    <Box pt="small" pl="small" pr="small" pb="small" height="100vh">
      <Card hasNoHeader fullHeight>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Illustration
            type="page-not-available"
            size="large"
            title="We can't authenticate your request..."
            subtitle="Please enable cookies in your browser"
          />
        </Box>
      </Card>
    </Box>
  );
};
