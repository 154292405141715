import React, { ReactNode } from 'react';
import {
  DataTable as SFDataTable,
  DataTableColumn as SFDataTableColumn,
} from '@salesforce/design-system-react';
import styled from 'styled-components';

import { LinkCell } from './LinkCell';
import { FormattedCell } from './FormattedCell';
import { LanguagesCell } from './LanguagesCell';
import { Cell } from './Cell';

export enum CellType {
  Link = 'link',
  Formatted = 'formatted',
  Languages = 'languages',
  Default = 'default',
}

export enum SortDirection {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export interface IDataTableSorting {
  property: string;
  sortDirection: SortDirection;
}

export interface IColumn {
  type?: CellType;
  property: string;
  width: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPath?: (item: any) => string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: (item: any, rawData?: any) => ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatTitle?: (item: any) => string;
  sortable?: boolean;
  isSorted?: boolean;
  sortDirection?: SortDirection;
  columnId?: string;
  truncate?: boolean;
}

interface IDataTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>;
  columns: IColumn[];
  noDataComponent?: ReactNode;
  handleSort?: (newSorting: IDataTableSorting) => void;
  id?: string;
}

const DataTableWrapper = styled(SFDataTable)({
  '& .slds-cell-fixed': {
    paddingLeft: 8,
  },
});

const cellComponent: Record<CellType, (column: IColumn) => ReactNode> = {
  [CellType.Link]: (column) => (
    <LinkCell
      truncate={column.truncate}
      getPath={column.getPath}
      {...(column.columnId ? { columnId: column.columnId } : {})}
    />
  ),
  [CellType.Formatted]: (column) => (
    <FormattedCell
      format={column.format}
      formatTitle={column.formatTitle}
      {...(column.columnId ? { columnId: column.columnId } : {})}
    />
  ),
  [CellType.Languages]: (column) => (
    <LanguagesCell
      format={column.format}
      formatTitle={column.formatTitle}
      {...(column.columnId ? { columnId: column.columnId } : {})}
    />
  ),
  [CellType.Default]: () => <Cell />,
};

export const DataTable = ({
  items,
  columns,
  noDataComponent,
  handleSort,
  id,
  ...props
}: IDataTableProps) => {
  const columnComponents = columns.map((column) => {
    return (
      <SFDataTableColumn
        key={column.label}
        label={column.label}
        property={column.property}
        width={column.width}
        sortable={column.sortable}
        isSorted={column.isSorted}
        sortDirection={column.sortDirection}
        columnId={column.columnId}
        truncate={column.truncate}
      >
        {column.type ? cellComponent[column.type](column) : null}
      </SFDataTableColumn>
    );
  });
  return (
    <div id={id}>
      <DataTableWrapper
        items={items}
        fixedHeader
        fixedLayout
        onSort={handleSort}
        {...props}
      >
        {columnComponents}
      </DataTableWrapper>
      {items.length === 0 && noDataComponent}
    </div>
  );
};
