import { gql } from '@apollo/client';

import { IActivity } from '@shared/bff';

import { ACTIVITY_FRAGMENT } from '../../fragments';

export interface IActivitiesQueryResponse {
  activities: IActivity[];
}

export const ACTIVITIES_QUERY = gql`
  query Activities($targetType: ActivityTargetType!, $targetId: String!) {
    activities(targetType: $targetType, targetId: $targetId) {
      ...ActivityFragment
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
