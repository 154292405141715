import React, { ReactNode } from 'react';
import {
  FieldInputProps,
  useField,
  useFormikContext,
  FieldMetaProps,
  FieldHelperProps,
} from 'formik';
import {
  Textarea,
  ITextareaProps,
  Tooltip,
} from '@salesforce/design-system-react';
import styled from 'styled-components';

import { uuid } from '@src/common/utils';

import { Box } from '../Box';
import { Grid, GridItem } from '../Grid';

import { RequiredFieldLabel } from './RequiredFieldLabel';
import { CharactersLimit } from './CharactersLimit';

interface IRegExpReplacement {
  searchValue: RegExp;
  replacer: (substring: string) => string;
}
export type RegexReplacements = Array<IRegExpReplacement>;

interface ITextareaFieldProps<TObject> extends ITextareaProps {
  name: keyof TObject & string;
  regexConstraint?: RegExp;
  regexReplace?: RegexReplacements;
  tooltip?: ReactNode;
  rightActions?: ReactNode;
  id?: string;
}

const TextareaInput = styled(Textarea)({
  minHeight: '100px',
});

const fieldToTextarea = (
  {
    onChange: fieldOnChange,
    onBlur: fieldOnBlur,
    ...field
  }: FieldInputProps<string>,
  isSubmitting: boolean,
  submitCount: number,
  { error, touched }: FieldMetaProps<string>,
  { setValue, setTouched }: FieldHelperProps<string>,
  {
    disabled,
    tooltip,
    label: rawLabel,
    regexConstraint,
    regexReplace,
    required,
    ...props
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ITextareaFieldProps<any>
): ITextareaProps => {
  // Something is weird with the Formik types + SalesForce.
  // It should be possible to assign formik.onChange to
  // SalesForce.onChange, but TypeScript does not like it.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange: any = (e: any) => {
    let newValue = e.target.value;
    if (regexConstraint && typeof newValue === 'string') {
      newValue = newValue.replaceAll(regexConstraint, '');
    }

    if (regexReplace && typeof newValue === 'string') {
      regexReplace.forEach(
        (replacement) =>
          (newValue = (newValue as string).replaceAll(
            replacement.searchValue,
            replacement.replacer
          ))
      );
    }

    setValue(newValue);
  };
  const onBlur = () => {
    let newValue = field.value;
    if (typeof newValue === 'string') {
      newValue = newValue.trim();
    }
    setValue(newValue);

    if (!field.value && !touched) {
      return;
    }
    setTouched(true);
  };

  const labelText = required ? (
    <RequiredFieldLabel>{rawLabel}</RequiredFieldLabel>
  ) : (
    rawLabel
  );
  const label = tooltip ? (
    <Box display="inline-flex">
      <Box pr="x-small" display="inline">
        {labelText}
      </Box>
      <Tooltip
        id={`${uuid()}-level-help-tooltip`}
        align="top left"
        content={tooltip}
        variant="learnMore"
      />
    </Box>
  ) : (
    labelText
  );

  return {
    disabled: disabled ?? isSubmitting,
    errorText: touched || submitCount > 0 ? error : '',
    // Unfortunately Textarea does not support tooltip next to label
    // Label can only be as a string, but we need to incorporate tooltip according to designs
    label: label as string,
    onChange,
    onBlur,
    ...field,
    ...props,
  };
};

// any is required for component to work properly in cases
// where no generic parameter is provided
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function TextareaField<TObject = any>({
  maxLength,
  rightActions,
  id,
  ...props
}: ITextareaFieldProps<TObject>) {
  const [field, meta, helpers] = useField(props.name);
  const { isSubmitting, submitCount } = useFormikContext();

  return (
    <>
      <TextareaInput
        id={id}
        maxLength={maxLength}
        {...fieldToTextarea(
          field,
          isSubmitting,
          submitCount,
          meta,
          helpers,
          props
        )}
      >
        <Grid>
          <GridItem cols={rightActions ? 4 : 12}>
            <CharactersLimit value={field.value} maxLength={maxLength} />
          </GridItem>
          {rightActions && <GridItem cols={8}>{rightActions}</GridItem>}
        </Grid>
      </TextareaInput>
    </>
  );
}
