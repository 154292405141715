import { Crop } from 'react-image-crop';

interface IOutputParams {
  width: number | number[];
  height: number | number[];
}

const getOutputSize = (
  size: number | number[],
  originalSize: number
): number => {
  if (typeof size === 'number') {
    return size;
  } else {
    const [minSize, maxSize] = size;

    return originalSize > maxSize
      ? maxSize
      : originalSize < minSize
      ? minSize
      : originalSize;
  }
};

export const getCroppedImg = (
  image: HTMLImageElement,
  crop: Crop,
  mimeType: string,
  outputParams: IOutputParams
): Promise<Blob> => {
  const canvas = document.createElement('canvas');
  const imageSizes = image.getBoundingClientRect();
  const scaleX = image.naturalWidth / imageSizes.width;
  const scaleY = image.naturalHeight / imageSizes.height;

  const originalWidth = crop.width * scaleX;
  const originalHeight = crop.height * scaleY;

  const outputWidth = getOutputSize(outputParams.width, originalWidth);
  const outputHeight = getOutputSize(outputParams.height, originalHeight);

  canvas.width = outputWidth;
  canvas.height = outputHeight;
  const ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        originalWidth,
        originalHeight,
        0,
        0,
        outputWidth,
        outputHeight
      );

      canvas.toBlob((blob) => {
        const newBlob = blob as Blob;
        resolve(newBlob);
      }, mimeType);
    } else {
      reject('Canvas context is null');
    }
  });
};

interface IMinCropRequirements {
  image: HTMLImageElement;
  crop: Crop;
  minWidth: number;
  minHeight: number;
}

export const minCropRequirements = ({
  image,
  crop,
  minWidth,
  minHeight,
}: IMinCropRequirements): boolean => {
  const { width = 0, height = 0 } = crop;
  const xScale = image.naturalWidth / image.width;
  const yScale = image.naturalHeight / image.height;

  return (
    image.naturalWidth > 0 &&
    image.naturalHeight > 0 &&
    (Math.ceil(width * xScale) < minWidth ||
      Math.ceil(height * yScale) < minHeight)
  );
};

export const getBase64StringMimeType = (base64String: string) =>
  base64String.split(',')[0].replace('data:', '').split(';')[0];

export const getMimeTypeExtension = (mimeType: string) =>
  mimeType.split('/')[1];
