/* eslint-disable */

/*
 * This file has been generated using en-US.json
 *
 * DO NOT MODIFY
 */
import i18n, { TOptionsBase } from 'i18next';

export const t = {
common: {
appTitle(options?: TOptionsBase): string {

return i18n.t('common.appTitle', options);
},
actions: {
cancel(options?: TOptionsBase): string {

return i18n.t('common.actions.cancel', options);
},
close(options?: TOptionsBase): string {

return i18n.t('common.actions.close', options);
},
newChannel(options?: TOptionsBase): string {

return i18n.t('common.actions.newChannel', options);
},
submitRequest(options?: TOptionsBase): string {

return i18n.t('common.actions.submitRequest', options);
},
submit(options?: TOptionsBase): string {

return i18n.t('common.actions.submit', options);
},
save(options?: TOptionsBase): string {

return i18n.t('common.actions.save', options);
},
newTemplate(options?: TOptionsBase): string {

return i18n.t('common.actions.newTemplate', options);
},
addLanguage(options?: TOptionsBase): string {

return i18n.t('common.actions.addLanguage', options);
},
editTemplate(options?: TOptionsBase): string {

return i18n.t('common.actions.editTemplate', options);
},
deleteTemplate(options?: TOptionsBase): string {

return i18n.t('common.actions.deleteTemplate', options);
},
viewTemplate(options?: TOptionsBase): string {

return i18n.t('common.actions.viewTemplate', options);
},
editChannelDetails(options?: TOptionsBase): string {

return i18n.t('common.actions.editChannelDetails', options);
},
hideChanges(options?: TOptionsBase): string {

return i18n.t('common.actions.hideChanges', options);
},
showChanges(options?: TOptionsBase): string {

return i18n.t('common.actions.showChanges', options);
},
hideCurrent(options?: TOptionsBase): string {

return i18n.t('common.actions.hideCurrent', options);
},
showCurrent(options?: TOptionsBase): string {

return i18n.t('common.actions.showCurrent', options);
},
goToRequest(options?: TOptionsBase): string {

return i18n.t('common.actions.goToRequest', options);
},
goToChannel(options?: TOptionsBase): string {

return i18n.t('common.actions.goToChannel', options);
},
delete(options?: TOptionsBase): string {

return i18n.t('common.actions.delete', options);
},
remove(options?: TOptionsBase): string {

return i18n.t('common.actions.remove', options);
},
saveDraft(options?: TOptionsBase): string {

return i18n.t('common.actions.saveDraft', options);
},
editDraft(options?: TOptionsBase): string {

return i18n.t('common.actions.editDraft', options);
},
editRejected(options?: TOptionsBase): string {

return i18n.t('common.actions.editRejected', options);
},
newAspClient(options?: TOptionsBase): string {

return i18n.t('common.actions.newAspClient', options);
},
contactSinchSupport(options?: TOptionsBase): string {

return i18n.t('common.actions.contactSinchSupport', options);
},
},
links: {
toBotCrDetails(options?: TOptionsBase): string {

return i18n.t('common.links.toBotCrDetails', options);
},
toBotDetails(options?: TOptionsBase): string {

return i18n.t('common.links.toBotDetails', options);
},
},
channelNames: {
Whatsapp(options?: TOptionsBase): string {

return i18n.t('common.channelNames.Whatsapp', options);
},
ViberBusiness(options?: TOptionsBase): string {

return i18n.t('common.channelNames.ViberBusiness', options);
},
KakaoTalk(options?: TOptionsBase): string {

return i18n.t('common.channelNames.KakaoTalk', options);
},
RCS(options?: TOptionsBase): string {

return i18n.t('common.channelNames.RCS', options);
},
},
noPendingChannels: {
title(options?: TOptionsBase): string {

return i18n.t('common.noPendingChannels.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.noPendingChannels.description', options);
},
},
noVerifiedChannels: {
title(options?: TOptionsBase): string {

return i18n.t('common.noVerifiedChannels.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.noVerifiedChannels.description', options);
},
},
noPendingAccounts: {
title(options?: TOptionsBase): string {

return i18n.t('common.noPendingAccounts.title', options);
},
description: {
Whatsapp(options?: TOptionsBase): string {

return i18n.t('common.noPendingAccounts.description.Whatsapp', options);
},
common(options?: TOptionsBase): string {

return i18n.t('common.noPendingAccounts.description.common', options);
},
},
},
noOnboardedAccounts: {
title(options?: TOptionsBase): string {

return i18n.t('common.noOnboardedAccounts.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.noOnboardedAccounts.description', options);
},
},
noActivities: {
title(options?: TOptionsBase): string {

return i18n.t('common.noActivities.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.noActivities.description', options);
},
},
rejectScopedNotification: {
description(options?: TOptionsBase): string {

return i18n.t('common.rejectScopedNotification.description', options);
},
link(options?: TOptionsBase): string {

return i18n.t('common.rejectScopedNotification.link', options);
},
},
permissionDeniedNotification(options?: TOptionsBase): string {

return i18n.t('common.permissionDeniedNotification', options);
},
readOnlyAccessNotification(options?: TOptionsBase): string {

return i18n.t('common.readOnlyAccessNotification', options);
},
channelLimitNotification(options?: TOptionsBase): string {

return i18n.t('common.channelLimitNotification', options);
},
noPurchasedAppsNotification(options?: TOptionsBase): string {

return i18n.t('common.noPurchasedAppsNotification', options);
},
channels(options?: TOptionsBase): string {

return i18n.t('common.channels', options);
},
channelProperties(options?: TOptionsBase): string {

return i18n.t('common.channelProperties', options);
},
channelConfiguration(options?: TOptionsBase): string {

return i18n.t('common.channelConfiguration', options);
},
channelDesign(options?: TOptionsBase): string {

return i18n.t('common.channelDesign', options);
},
contactInformation(options?: TOptionsBase): string {

return i18n.t('common.contactInformation', options);
},
accountInformation(options?: TOptionsBase): string {

return i18n.t('common.accountInformation', options);
},
requestChange(options?: TOptionsBase): string {

return i18n.t('common.requestChange', options);
},
messageTemplates(options?: TOptionsBase): string {

return i18n.t('common.messageTemplates', options);
},
accounts(options?: TOptionsBase): string {

return i18n.t('common.accounts', options);
},
accountDetails(options?: TOptionsBase): string {

return i18n.t('common.accountDetails', options);
},
chatMessaging(options?: TOptionsBase): string {

return i18n.t('common.chatMessaging', options);
},
chatMessagingChannels(options?: TOptionsBase): string {

return i18n.t('common.chatMessagingChannels', options);
},
home(options?: TOptionsBase): string {

return i18n.t('common.home', options);
},
optional(options?: TOptionsBase): string {

return i18n.t('common.optional', options);
},
emailAddress(options?: TOptionsBase): string {

return i18n.t('common.emailAddress', options);
},
queryHandler: {
loading(options?: TOptionsBase): string {

return i18n.t('common.queryHandler.loading', options);
},
accessDenied(options?: TOptionsBase): string {

return i18n.t('common.queryHandler.accessDenied', options);
},
error(options?: TOptionsBase): string {

return i18n.t('common.queryHandler.error', options);
},
noData(options?: TOptionsBase): string {

return i18n.t('common.queryHandler.noData', options);
},
},
requestNewChannel(options?: TOptionsBase): string {

return i18n.t('common.requestNewChannel', options);
},
setup(options?: TOptionsBase): string {

return i18n.t('common.setup', options);
},
validation: {
mixed: {
default(options?: TOptionsBase): string {

return i18n.t('common.validation.mixed.default', options);
},
required(options?: TOptionsBase): string {

return i18n.t('common.validation.mixed.required', options);
},
},
string: {
email(options?: TOptionsBase): string {

return i18n.t('common.validation.string.email', options);
},
url(options?: TOptionsBase): string {

return i18n.t('common.validation.string.url', options);
},
length(length: string,options?: TOptionsBase): string {

return i18n.t('common.validation.string.length', { ...options, length });
},
min(min: string,options?: TOptionsBase): string {

return i18n.t('common.validation.string.min', { ...options, min });
},
max(max: string,options?: TOptionsBase): string {

return i18n.t('common.validation.string.max', { ...options, max });
},
},
phoneNumber: {
e164(options?: TOptionsBase): string {

return i18n.t('common.validation.phoneNumber.e164', options);
},
countryCode(options?: TOptionsBase): string {

return i18n.t('common.validation.phoneNumber.countryCode', options);
},
subscriberNumber(options?: TOptionsBase): string {

return i18n.t('common.validation.phoneNumber.subscriberNumber', options);
},
},
url: {
httpHttps(options?: TOptionsBase): string {

return i18n.t('common.validation.url.httpHttps', options);
},
https(options?: TOptionsBase): string {

return i18n.t('common.validation.url.https', options);
},
facebookInstagram(options?: TOptionsBase): string {

return i18n.t('common.validation.url.facebookInstagram', options);
},
whatsapp(options?: TOptionsBase): string {

return i18n.t('common.validation.url.whatsapp', options);
},
variable(options?: TOptionsBase): string {

return i18n.t('common.validation.url.variable', options);
},
},
array: {
max(max: string,options?: TOptionsBase): string {

return i18n.t('common.validation.array.max', { ...options, max });
},
},
test: {
unique(options?: TOptionsBase): string {

return i18n.t('common.validation.test.unique', options);
},
change(options?: TOptionsBase): string {

return i18n.t('common.validation.test.change', options);
},
},
},
components: {
imageResolver: {
defaultModalTitle(options?: TOptionsBase): string {

return i18n.t('common.components.imageResolver.defaultModalTitle', options);
},
resolverDescription: {
bot(options?: TOptionsBase): string {

return i18n.t('common.components.imageResolver.resolverDescription.bot', options);
},
template(options?: TOptionsBase): string {

return i18n.t('common.components.imageResolver.resolverDescription.template', options);
},
},
dropZoneLabel(options?: TOptionsBase): string {

return i18n.t('common.components.imageResolver.dropZoneLabel', options);
},
uploadButtonDescription(options?: TOptionsBase): string {

return i18n.t('common.components.imageResolver.uploadButtonDescription', options);
},
replaceButtonDescription(options?: TOptionsBase): string {

return i18n.t('common.components.imageResolver.replaceButtonDescription', options);
},
},
fileUploader: {
multiple: {
dropZoneLabel(options?: TOptionsBase): string {

return i18n.t('common.components.fileUploader.multiple.dropZoneLabel', options);
},
uploadButtonDescription(options?: TOptionsBase): string {

return i18n.t('common.components.fileUploader.multiple.uploadButtonDescription', options);
},
},
single: {
dropZoneLabel(options?: TOptionsBase): string {

return i18n.t('common.components.fileUploader.single.dropZoneLabel', options);
},
uploadButtonDescription(options?: TOptionsBase): string {

return i18n.t('common.components.fileUploader.single.uploadButtonDescription', options);
},
replaceButtonDescription(options?: TOptionsBase): string {

return i18n.t('common.components.fileUploader.single.replaceButtonDescription', options);
},
},
},
businessProfilePreviewer: {
heading(options?: TOptionsBase): string {

return i18n.t('common.components.businessProfilePreviewer.heading', options);
},
readMore(options?: TOptionsBase): string {

return i18n.t('common.components.businessProfilePreviewer.readMore', options);
},
showLess(options?: TOptionsBase): string {

return i18n.t('common.components.businessProfilePreviewer.showLess', options);
},
},
charactersLimit(options?: TOptionsBase): string {

return i18n.t('common.components.charactersLimit', options);
},
changeRequestInfoSection: {
crCreated(options?: TOptionsBase): string {

return i18n.t('common.components.changeRequestInfoSection.crCreated', options);
},
crState(options?: TOptionsBase): string {

return i18n.t('common.components.changeRequestInfoSection.crState', options);
},
changeRequest(options?: TOptionsBase): string {

return i18n.t('common.components.changeRequestInfoSection.changeRequest', options);
},
},
accountSelect: {
label(options?: TOptionsBase): string {

return i18n.t('common.components.accountSelect.label', options);
},
tooltips: {
channels(options?: TOptionsBase): string {

return i18n.t('common.components.accountSelect.tooltips.channels', options);
},
templates(options?: TOptionsBase): string {

return i18n.t('common.components.accountSelect.tooltips.templates', options);
},
},
placeholder(options?: TOptionsBase): string {

return i18n.t('common.components.accountSelect.placeholder', options);
},
},
needAccountRegister: {
Whatsapp: {
title(options?: TOptionsBase): string {

return i18n.t('common.components.needAccountRegister.Whatsapp.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.components.needAccountRegister.Whatsapp.description', options);
},
},
ViberBusiness: {
title(options?: TOptionsBase): string {

return i18n.t('common.components.needAccountRegister.ViberBusiness.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.components.needAccountRegister.ViberBusiness.description', options);
},
},
},
needAccountWithActiveChannel: {
title(options?: TOptionsBase): string {

return i18n.t('common.components.needAccountWithActiveChannel.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.components.needAccountWithActiveChannel.description', options);
},
},
aspClientProgressIndicator: {
heading(options?: TOptionsBase): string {

return i18n.t('common.components.aspClientProgressIndicator.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.components.aspClientProgressIndicator.description', options);
},
},
},
errorBoundary: {
title(options?: TOptionsBase): string {

return i18n.t('common.errorBoundary.title', options);
},
subtitle(options?: TOptionsBase): string {

return i18n.t('common.errorBoundary.subtitle', options);
},
},
notFound: {
title(options?: TOptionsBase): string {

return i18n.t('common.notFound.title', options);
},
subtitle(options?: TOptionsBase): string {

return i18n.t('common.notFound.subtitle', options);
},
},
errors: {
maxAppsReached(options?: TOptionsBase): string {

return i18n.t('common.errors.maxAppsReached', options);
},
maxChannelsReached(options?: TOptionsBase): string {

return i18n.t('common.errors.maxChannelsReached', options);
},
},
toasts: {
somethingWentWrong(options?: TOptionsBase): string {

return i18n.t('common.toasts.somethingWentWrong', options);
},
commentCreatedSuccess(options?: TOptionsBase): string {

return i18n.t('common.toasts.commentCreatedSuccess', options);
},
saveDraftSuccess(options?: TOptionsBase): string {

return i18n.t('common.toasts.saveDraftSuccess', options);
},
submitRequestSuccess(options?: TOptionsBase): string {

return i18n.t('common.toasts.submitRequestSuccess', options);
},
uploadRejected(options?: TOptionsBase): string {

return i18n.t('common.toasts.uploadRejected', options);
},
},
notificationTypeLabels: {
UNSPECIFIED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.UNSPECIFIED', options);
},
CUSTOMER_CREATED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_CREATED', options);
},
BOT_CREATED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.BOT_CREATED', options);
},
BOT_EDITED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.BOT_EDITED', options);
},
BOT_STARTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.BOT_STARTED', options);
},
BOT_STOPPED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.BOT_STOPPED', options);
},
CHANGE_REQUEST_CREATED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_CREATED', options);
},
CHANGE_REQUEST_EDITED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_EDITED', options);
},
CHANGE_REQUEST_IN_REVIEW_QUEUE(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_IN_REVIEW_QUEUE', options);
},
CHANGE_REQUEST_BEING_REVIEWED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_BEING_REVIEWED', options);
},
CHANGE_REQUEST_APPROVED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_APPROVED', options);
},
CHANGE_REQUEST_IN_PROGRESS(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_IN_PROGRESS', options);
},
CHANGE_REQUEST_DONE(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_DONE', options);
},
CHANGE_REQUEST_REJECTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_REJECTED', options);
},
CHANGE_REQUEST_CANCELLED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_CANCELLED', options);
},
CHANGE_REQUEST_BEING_ROLLED_BACK(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_BEING_ROLLED_BACK', options);
},
CUSTOMER_RCS_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_RCS_ONBOARDING_REQUESTED', options);
},
CUSTOMER_WA_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_WA_ONBOARDING_REQUESTED', options);
},
CUSTOMER_RCS_ONBOARDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_RCS_ONBOARDED', options);
},
CUSTOMER_WA_ONBOARDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_WA_ONBOARDED', options);
},
CUSTOMER_UPDATED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_UPDATED', options);
},
CUSTOMER_ACTIVATED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_ACTIVATED', options);
},
CUSTOMER_DISABLED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_DISABLED', options);
},
BOT_COMMENT_ADDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.BOT_COMMENT_ADDED', options);
},
CR_COMMENT_ADDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CR_COMMENT_ADDED', options);
},
BOT_TEST(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.BOT_TEST', options);
},
BOT_VERIFIED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.BOT_VERIFIED', options);
},
BOT_REGISTERED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.BOT_REGISTERED', options);
},
ASP_CLIENT_WA_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_WA_ONBOARDING_REQUESTED', options);
},
ASP_CLIENT_WA_ONBOARDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_WA_ONBOARDED', options);
},
CHANGE_REQUEST_SET_TO_DRAFT(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_SET_TO_DRAFT', options);
},
CUSTOMER_COMMENT_ADDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_COMMENT_ADDED', options);
},
ASP_CLIENT_COMMENT_ADDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_COMMENT_ADDED', options);
},
ASP_CLIENT_WA_PENDING_FB_VERIFICATION(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_WA_PENDING_FB_VERIFICATION', options);
},
ASP_CLIENT_WA_REJECTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_WA_REJECTED', options);
},
CUSTOMER_WA_PENDING_FB_VERIFICATION(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_WA_PENDING_FB_VERIFICATION', options);
},
CUSTOMER_WA_REJECTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_WA_REJECTED', options);
},
CHANGE_REQUEST_TERMINATED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_TERMINATED', options);
},
CHANGE_REQUEST_SIGNATURE_PENDING(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_SIGNATURE_PENDING', options);
},
CUSTOMER_VIBER_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_VIBER_ONBOARDING_REQUESTED', options);
},
CUSTOMER_VIBER_ONBOARDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CUSTOMER_VIBER_ONBOARDED', options);
},
ASP_CLIENT_VIBER_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_VIBER_ONBOARDING_REQUESTED', options);
},
ASP_CLIENT_VIBER_ONBOARDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_VIBER_ONBOARDED', options);
},
CHANGE_REQUEST_WA_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_WA_ONBOARDING_REQUESTED', options);
},
CHANGE_REQUEST_WA_PENDING_FB_VERIFICATION(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_WA_PENDING_FB_VERIFICATION', options);
},
CHANGE_REQUEST_WABAID_PROVIDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_WABAID_PROVIDED', options);
},
CHANGE_REQUEST_MULTIPLE_WABA_PER_CLIENT(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.CHANGE_REQUEST_MULTIPLE_WABA_PER_CLIENT', options);
},
ACCOUNT_KT_ONBOARDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ACCOUNT_KT_ONBOARDED', options);
},
ACCOUNT_KT_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ACCOUNT_KT_ONBOARDING_REQUESTED', options);
},
ASP_CLIENT_KT_ONBOARDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_KT_ONBOARDED', options);
},
ASP_CLIENT_KT_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_KT_ONBOARDING_REQUESTED', options);
},
ASP_CLIENT_RCS_ONBOARDED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_RCS_ONBOARDED', options);
},
ASP_CLIENT_RCS_ONBOARDING_REQUESTED(options?: TOptionsBase): string {

return i18n.t('common.notificationTypeLabels.ASP_CLIENT_RCS_ONBOARDING_REQUESTED', options);
},
},
changeRequestStateLabels: {
DRAFT(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.DRAFT', options);
},
REVIEW_QUEUE(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.REVIEW_QUEUE', options);
},
BOT_CR_UNSPECIFIED_STATE(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.BOT_CR_UNSPECIFIED_STATE', options);
},
REVIEW(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.REVIEW', options);
},
APPROVED(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.APPROVED', options);
},
IN_PROGRESS(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.IN_PROGRESS', options);
},
DONE(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.DONE', options);
},
ROLLBACK(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.ROLLBACK', options);
},
REJECTED(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.REJECTED', options);
},
SIGNATURE_PENDING(options?: TOptionsBase): string {

return i18n.t('common.changeRequestStateLabels.SIGNATURE_PENDING', options);
},
},
navigationOutConfirmDialog: {
title(options?: TOptionsBase): string {

return i18n.t('common.navigationOutConfirmDialog.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('common.navigationOutConfirmDialog.description', options);
},
leave(options?: TOptionsBase): string {

return i18n.t('common.navigationOutConfirmDialog.leave', options);
},
},
listings: {
headers: {
pendingChannels(options?: TOptionsBase): string {

return i18n.t('common.listings.headers.pendingChannels', options);
},
onboardedChannels(options?: TOptionsBase): string {

return i18n.t('common.listings.headers.onboardedChannels', options);
},
activityHistory(options?: TOptionsBase): string {

return i18n.t('common.listings.headers.activityHistory', options);
},
pendingAspClients(options?: TOptionsBase): string {

return i18n.t('common.listings.headers.pendingAspClients', options);
},
onboardedAspClients(options?: TOptionsBase): string {

return i18n.t('common.listings.headers.onboardedAspClients', options);
},
},
columns: {
channelName(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.channelName', options);
},
accountName(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.accountName', options);
},
status(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.status', options);
},
changeRequestStatus(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.changeRequestStatus', options);
},
timestamp(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.timestamp', options);
},
event(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.event', options);
},
comment(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.comment', options);
},
updated(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.updated', options);
},
quality(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.quality', options);
},
dailyLimit(options?: TOptionsBase): string {

return i18n.t('common.listings.columns.dailyLimit', options);
},
},
},
},
home: {
heading(options?: TOptionsBase): string {

return i18n.t('home.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.description', options);
},
checklistHeading(options?: TOptionsBase): string {

return i18n.t('home.checklistHeading', options);
},
complete(options?: TOptionsBase): string {

return i18n.t('home.complete', options);
},
documentationLink(options?: TOptionsBase): string {

return i18n.t('home.documentationLink', options);
},
emailNotifications: {
heading(options?: TOptionsBase): string {

return i18n.t('home.emailNotifications.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.emailNotifications.description', options);
},
setupDescription(options?: TOptionsBase): string {

return i18n.t('home.emailNotifications.setupDescription', options);
},
removeHeading(options?: TOptionsBase): string {

return i18n.t('home.emailNotifications.removeHeading', options);
},
removeWarning(options?: TOptionsBase): string {

return i18n.t('home.emailNotifications.removeWarning', options);
},
infoOptIn(options?: TOptionsBase): string {

return i18n.t('home.emailNotifications.infoOptIn', options);
},
infoRemove(options?: TOptionsBase): string {

return i18n.t('home.emailNotifications.infoRemove', options);
},
infoEmail(options?: TOptionsBase): string {

return i18n.t('home.emailNotifications.infoEmail', options);
},
},
newCategories: {
description(options?: TOptionsBase): string {

return i18n.t('home.newCategories.description', options);
},
secondDescription(options?: TOptionsBase): string {

return i18n.t('home.newCategories.secondDescription', options);
},
thirdDescription(options?: TOptionsBase): string {

return i18n.t('home.newCategories.thirdDescription', options);
},
},
setupAssistant: {
whatsApp: {
stepOne: {
heading(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepOne.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepOne.description', options);
},
substepOne(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepOne.substepOne', options);
},
substepTwo(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepOne.substepTwo', options);
},
substepThree(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepOne.substepThree', options);
},
substepFour(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepOne.substepFour', options);
},
hint(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepOne.hint', options);
},
accountsLink(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepOne.accountsLink', options);
},
},
stepTwo: {
heading(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepTwo.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepTwo.description', options);
},
substepOne(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepTwo.substepOne', options);
},
substepTwo(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepTwo.substepTwo', options);
},
substepThree(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepTwo.substepThree', options);
},
substepFour(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepTwo.substepFour', options);
},
hint(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepTwo.hint', options);
},
channelsLink(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepTwo.channelsLink', options);
},
},
stepThree: {
heading(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepThree.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepThree.description', options);
},
substepOne(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepThree.substepOne', options);
},
substepTwo(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepThree.substepTwo', options);
},
substepThree(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepThree.substepThree', options);
},
hint(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepThree.hint', options);
},
templatesLink(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.whatsApp.stepThree.templatesLink', options);
},
},
},
viber: {
stepOne: {
heading(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepOne.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepOne.description', options);
},
substepOne(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepOne.substepOne', options);
},
substepTwo(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepOne.substepTwo', options);
},
substepThree(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepOne.substepThree', options);
},
hint(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepOne.hint', options);
},
accountsLink(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepOne.accountsLink', options);
},
},
stepTwo: {
heading(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepTwo.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepTwo.description', options);
},
substepOne(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepTwo.substepOne', options);
},
substepTwo(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepTwo.substepTwo', options);
},
substepThree(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepTwo.substepThree', options);
},
hint(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepTwo.hint', options);
},
channelsLink(options?: TOptionsBase): string {

return i18n.t('home.setupAssistant.viber.stepTwo.channelsLink', options);
},
},
},
},
suffix: {
heading(options?: TOptionsBase): string {

return i18n.t('home.suffix.heading', options);
},
link(options?: TOptionsBase): string {

return i18n.t('home.suffix.link', options);
},
},
enterpriseDetails: {
heading(options?: TOptionsBase): string {

return i18n.t('home.enterpriseDetails.heading', options);
},
labels: {
enterprise(options?: TOptionsBase): string {

return i18n.t('home.enterpriseDetails.labels.enterprise', options);
},
viberChannels(options?: TOptionsBase): string {

return i18n.t('home.enterpriseDetails.labels.viberChannels', options);
},
whatsappChannels(options?: TOptionsBase): string {

return i18n.t('home.enterpriseDetails.labels.whatsappChannels', options);
},
},
tooltips: {
enterprise(options?: TOptionsBase): string {

return i18n.t('home.enterpriseDetails.tooltips.enterprise', options);
},
viberChannels(options?: TOptionsBase): string {

return i18n.t('home.enterpriseDetails.tooltips.viberChannels', options);
},
whatsappChannels(options?: TOptionsBase): string {

return i18n.t('home.enterpriseDetails.tooltips.whatsappChannels', options);
},
},
},
promoCards: {
baloon: {
heading(options?: TOptionsBase): string {

return i18n.t('home.promoCards.baloon.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.promoCards.baloon.description', options);
},
link(options?: TOptionsBase): string {

return i18n.t('home.promoCards.baloon.link', options);
},
},
fruit: {
heading(options?: TOptionsBase): string {

return i18n.t('home.promoCards.fruit.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('home.promoCards.fruit.description', options);
},
link(options?: TOptionsBase): string {

return i18n.t('home.promoCards.fruit.link', options);
},
},
},
},
whatsapp: {
preview: {
header(options?: TOptionsBase): string {

return i18n.t('whatsapp.preview.header', options);
},
toggleDescription(options?: TOptionsBase): string {

return i18n.t('whatsapp.preview.toggleDescription', options);
},
},
fieldLabels: {
aboutBusiness(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.aboutBusiness', options);
},
account(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.account', options);
},
vertical(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.vertical', options);
},
channelName(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.channelName', options);
},
channelDescription(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.channelDescription', options);
},
countryCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.countryCode', options);
},
phoneNumberCountry(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.phoneNumberCountry', options);
},
phone(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.phone', options);
},
phoneNumberWarning(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.phoneNumberWarning', options);
},
provisionNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.provisionNumber', options);
},
address(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.address', options);
},
email(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.email', options);
},
websiteOne(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.websiteOne', options);
},
websiteTwo(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.websiteTwo', options);
},
websiteTwoOptional(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.websiteTwoOptional', options);
},
channelStatus(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.channelStatus', options);
},
brandLogo(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.brandLogo', options);
},
imageName(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.imageName', options);
},
verificationNeeded(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.verificationNeeded', options);
},
companyInformation(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.companyInformation', options);
},
verificationDescription(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.verificationDescription', options);
},
startButton(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.startButton', options);
},
verifyButton(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.verifyButton', options);
},
verificationCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.verificationCode', options);
},
expireIn(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.expireIn', options);
},
whatsappPhoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.whatsappPhoneNumber', options);
},
addCommentButton(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.addCommentButton', options);
},
comment(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.comment', options);
},
noName(options?: TOptionsBase): string {

return i18n.t('whatsapp.fieldLabels.noName', options);
},
},
tooltips: {
account(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.account', options);
},
channelName(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.channelName', options);
},
channelDescription(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.channelDescription', options);
},
phoneNumberType(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.phoneNumberType', options);
},
selectRegion(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.selectRegion', options);
},
countryCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.countryCode', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.phoneNumber', options);
},
brandLogo(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.brandLogo', options);
},
companyInfoAbout(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.companyInfoAbout', options);
},
companyInfoIndustry(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.companyInfoIndustry', options);
},
contactInfoAddress(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.contactInfoAddress', options);
},
verificationNeeded(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.verificationNeeded', options);
},
quality(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.quality', options);
},
dailyLimits(options?: TOptionsBase): string {

return i18n.t('whatsapp.tooltips.dailyLimits', options);
},
},
stateLabels: {
ACTIVE(options?: TOptionsBase): string {

return i18n.t('whatsapp.stateLabels.ACTIVE', options);
},
INACTIVE(options?: TOptionsBase): string {

return i18n.t('whatsapp.stateLabels.INACTIVE', options);
},
NEW(options?: TOptionsBase): string {

return i18n.t('whatsapp.stateLabels.NEW', options);
},
TEST(options?: TOptionsBase): string {

return i18n.t('whatsapp.stateLabels.TEST', options);
},
DELETED(options?: TOptionsBase): string {

return i18n.t('whatsapp.stateLabels.DELETED', options);
},
SUSPENDED(options?: TOptionsBase): string {

return i18n.t('whatsapp.stateLabels.SUSPENDED', options);
},
PROVISIONING(options?: TOptionsBase): string {

return i18n.t('whatsapp.stateLabels.PROVISIONING', options);
},
PROVISIONING_FAILED(options?: TOptionsBase): string {

return i18n.t('whatsapp.stateLabels.PROVISIONING_FAILED', options);
},
},
qualityLabels: {
UNKNOWN(options?: TOptionsBase): string {

return i18n.t('whatsapp.qualityLabels.UNKNOWN', options);
},
RED(options?: TOptionsBase): string {

return i18n.t('whatsapp.qualityLabels.RED', options);
},
YELLOW(options?: TOptionsBase): string {

return i18n.t('whatsapp.qualityLabels.YELLOW', options);
},
GREEN(options?: TOptionsBase): string {

return i18n.t('whatsapp.qualityLabels.GREEN', options);
},
},
dailyLimitLabels: {
UNKNOWN(options?: TOptionsBase): string {

return i18n.t('whatsapp.dailyLimitLabels.UNKNOWN', options);
},
TIER_NOT_SET(options?: TOptionsBase): string {

return i18n.t('whatsapp.dailyLimitLabels.TIER_NOT_SET', options);
},
TIER_50(options?: TOptionsBase): string {

return i18n.t('whatsapp.dailyLimitLabels.TIER_50', options);
},
TIER_1K(options?: TOptionsBase): string {

return i18n.t('whatsapp.dailyLimitLabels.TIER_1K', options);
},
TIER_10K(options?: TOptionsBase): string {

return i18n.t('whatsapp.dailyLimitLabels.TIER_10K', options);
},
TIER_100K(options?: TOptionsBase): string {

return i18n.t('whatsapp.dailyLimitLabels.TIER_100K', options);
},
TIER_UNLIMITED(options?: TOptionsBase): string {

return i18n.t('whatsapp.dailyLimitLabels.TIER_UNLIMITED', options);
},
},
changeRequestProgressLabels: {
DRAFT(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.DRAFT', options);
},
REVIEW_QUEUE(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.REVIEW_QUEUE', options);
},
SIGNATURE_PENDING(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.SIGNATURE_PENDING', options);
},
BOT_CR_UNSPECIFIED_STATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.BOT_CR_UNSPECIFIED_STATE', options);
},
REVIEW(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.REVIEW', options);
},
APPROVED(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.APPROVED', options);
},
IN_PROGRESS(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.IN_PROGRESS', options);
},
DONE(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.DONE', options);
},
ROLLBACK(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.ROLLBACK', options);
},
REJECTED(options?: TOptionsBase): string {

return i18n.t('whatsapp.changeRequestProgressLabels.REJECTED', options);
},
},
botForm: {
upload: {
logoModalTitle(options?: TOptionsBase): string {

return i18n.t('whatsapp.botForm.upload.logoModalTitle', options);
},
loadingDescription(options?: TOptionsBase): string {

return i18n.t('whatsapp.botForm.upload.loadingDescription', options);
},
},
phone: {
sinch(options?: TOptionsBase): string {

return i18n.t('whatsapp.botForm.phone.sinch', options);
},
personal(options?: TOptionsBase): string {

return i18n.t('whatsapp.botForm.phone.personal', options);
},
},
addComment: {
title(options?: TOptionsBase): string {

return i18n.t('whatsapp.botForm.addComment.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.botForm.addComment.description', options);
},
},
placeholder: {
countryCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.botForm.placeholder.countryCode', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.botForm.placeholder.phoneNumber', options);
},
},
},
verifyBotModal: {
header(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyBotModal.header', options);
},
title(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyBotModal.title', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyBotModal.phoneNumber', options);
},
verificationMethod(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyBotModal.verificationMethod', options);
},
sms(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyBotModal.sms', options);
},
voice(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyBotModal.voice', options);
},
sendCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyBotModal.sendCode', options);
},
},
verifyErrors: {
invalidNumberOrVName(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyErrors.invalidNumberOrVName', options);
},
incorrectCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyErrors.incorrectCode', options);
},
genericError(options?: TOptionsBase): string {

return i18n.t('whatsapp.verifyErrors.genericError', options);
},
},
verticalTypeLabels: {
AUTOMOTIVE(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.AUTOMOTIVE', options);
},
BEAUTY_SPA_SALON(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.BEAUTY_SPA_SALON', options);
},
CLOTHING_AND_APPAREL(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.CLOTHING_AND_APPAREL', options);
},
EDUCATION(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.EDUCATION', options);
},
ENTERTAINMENT(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.ENTERTAINMENT', options);
},
EVENT_PLANNING_AND_SERVICE(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.EVENT_PLANNING_AND_SERVICE', options);
},
FINANCE_AND_BANKING(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.FINANCE_AND_BANKING', options);
},
FOOD_AND_GROCERY(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.FOOD_AND_GROCERY', options);
},
PUBLIC_SERVICE(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.PUBLIC_SERVICE', options);
},
HOTEL_AND_LODGING(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.HOTEL_AND_LODGING', options);
},
MEDICAL_AND_HEALTH(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.MEDICAL_AND_HEALTH', options);
},
NON_PROFIT(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.NON_PROFIT', options);
},
PROFESSIONAL_SERVICES(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.PROFESSIONAL_SERVICES', options);
},
SHOPPING_AND_RETAIL(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.SHOPPING_AND_RETAIL', options);
},
TRAVEL_AND_TRANSPORTATION(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.TRAVEL_AND_TRANSPORTATION', options);
},
RESTAURANT(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.RESTAURANT', options);
},
OTHER(options?: TOptionsBase): string {

return i18n.t('whatsapp.verticalTypeLabels.OTHER', options);
},
},
aspClients: {
register: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.register.heading', options);
},
descriptionOne(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.register.descriptionOne', options);
},
descriptionTwo(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.register.descriptionTwo', options);
},
documentationLink(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.register.documentationLink', options);
},
},
details: {
scopedNotification: {
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.details.scopedNotification.description', options);
},
link(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.details.scopedNotification.link', options);
},
},
},
fields: {
name(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.fields.name', options);
},
nameTooltip(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.fields.nameTooltip', options);
},
status(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.fields.status', options);
},
businessLegalName(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.fields.businessLegalName', options);
},
businessLegalNameTooltip(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.fields.businessLegalNameTooltip', options);
},
facebookBusinessManagerID(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.fields.facebookBusinessManagerID', options);
},
facebookBusinessManagerIDTooltip(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.fields.facebookBusinessManagerIDTooltip', options);
},
},
status: {
DRAFT(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.status.DRAFT', options);
},
REQUESTED(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.status.REQUESTED', options);
},
ONBOARDED(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.status.ONBOARDED', options);
},
NOT_REGISTERED(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.status.NOT_REGISTERED', options);
},
PENDING_FB_VERIFICATION(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.status.PENDING_FB_VERIFICATION', options);
},
REJECTED(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.status.REJECTED', options);
},
UNSPECIFIED(options?: TOptionsBase): string {

return i18n.t('whatsapp.aspClients.status.UNSPECIFIED', options);
},
},
},
messageTemplates: {
approvalProgress: {
title(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.approvalProgress.title', options);
},
subtitle(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.approvalProgress.subtitle', options);
},
},
list: {
pendingMessageTemplates(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.list.pendingMessageTemplates', options);
},
approvedMessageTemplates(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.list.approvedMessageTemplates', options);
},
noPendingMessageTemplates: {
title(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.list.noPendingMessageTemplates.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.list.noPendingMessageTemplates.description', options);
},
},
noApprovedMessageTemplates: {
title(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.list.noApprovedMessageTemplates.title', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.list.noApprovedMessageTemplates.description', options);
},
},
},
noCategory(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.noCategory', options);
},
noLanguages(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.noLanguages', options);
},
noVariables(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.noVariables', options);
},
register: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.heading', options);
},
sections: {
messageTemplateConfiguration(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.sections.messageTemplateConfiguration', options);
},
messageContent(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.sections.messageContent', options);
},
samples(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.sections.samples', options);
},
},
buttonLabels: {
addButton(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.buttonLabels.addButton', options);
},
},
helpTexts: {
language(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.helpTexts.language', options);
},
quickReplyButtons(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.helpTexts.quickReplyButtons', options);
},
callToActionButtons(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.helpTexts.callToActionButtons', options);
},
templateName(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.helpTexts.templateName', options);
},
samples(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.helpTexts.samples', options);
},
},
confirmationModal: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.confirmationModal.heading', options);
},
body(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.confirmationModal.body', options);
},
selectLanguagesBody(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.confirmationModal.selectLanguagesBody', options);
},
availableLanguages(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.confirmationModal.availableLanguages', options);
},
disabledLanguageLabel: {
incorrectValue(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.confirmationModal.disabledLanguageLabel.incorrectValue', options);
},
unchanged(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.confirmationModal.disabledLanguageLabel.unchanged', options);
},
},
warning(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.confirmationModal.warning', options);
},
warningLink(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.confirmationModal.warningLink', options);
},
},
saveDraftModal: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.saveDraftModal.heading', options);
},
newTemplateBody(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.saveDraftModal.newTemplateBody', options);
},
addTemplateBody(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.saveDraftModal.addTemplateBody', options);
},
editTemplateBody(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.saveDraftModal.editTemplateBody', options);
},
},
errorModal: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.heading', options);
},
saveErrorHeading(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.saveErrorHeading', options);
},
nameIsAlreadyInUse(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.nameIsAlreadyInUse', options);
},
duplicateLanguageIsNotAllowed(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.duplicateLanguageIsNotAllowed', options);
},
requiredHeading(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.requiredHeading', options);
},
requiredForNewDraft(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.requiredForNewDraft', options);
},
requiredForNewLanguage(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.requiredForNewLanguage', options);
},
countryCodeAndPhoneNumberRequired(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.countryCodeAndPhoneNumberRequired', options);
},
invalidPhoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.invalidPhoneNumber', options);
},
invalidWebsite(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.invalidWebsite', options);
},
transitionErrors(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.register.errorModal.transitionErrors', options);
},
},
},
details: {
deleteModal: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.details.deleteModal.heading', options);
},
body(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.details.deleteModal.body', options);
},
},
},
fields: {
templateName(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.templateName', options);
},
templateCategory(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.templateCategory', options);
},
languages(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.languages', options);
},
header(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.header', options);
},
headerText(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.headerText', options);
},
mediaType(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.mediaType', options);
},
messageBody(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.messageBody', options);
},
footer(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.footer', options);
},
buttons(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.buttons', options);
},
buttonText(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.buttonText', options);
},
typeOfAction(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.typeOfAction', options);
},
countryCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.countryCode', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.phoneNumber', options);
},
urlType(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.urlType', options);
},
websiteUrl(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.websiteUrl', options);
},
updated(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.updated', options);
},
button(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.button', options);
},
more(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.more', options);
},
headerMediaSample(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.headerMediaSample', options);
},
headerVariable(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.headerVariable', options);
},
bodyVariable(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.bodyVariable', options);
},
variable(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.variable', options);
},
buttonDynamicUrlVariable(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.buttonDynamicUrlVariable', options);
},
securityRecommendation(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.securityRecommendation', options);
},
codeExpirationMinutes(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.codeExpirationMinutes', options);
},
buttonType(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.buttonType', options);
},
copyCodeButtonText(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.copyCodeButtonText', options);
},
autofillText(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.autofillText', options);
},
packageName(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.packageName', options);
},
signatureHash(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.fields.signatureHash', options);
},
},
placeholders: {
templateName(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.placeholders.templateName', options);
},
templateCategory(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.placeholders.templateCategory', options);
},
languages(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.placeholders.languages', options);
},
header(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.placeholders.header', options);
},
buttons(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.placeholders.buttons', options);
},
countryCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.placeholders.countryCode', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.placeholders.phoneNumber', options);
},
},
tooltips: {
templateName(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.templateName', options);
},
templateCategory(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.templateCategory', options);
},
templateCategoryRadioButtons(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.templateCategoryRadioButtons', options);
},
languages(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.languages', options);
},
countryCode(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.countryCode', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.phoneNumber', options);
},
headerText(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.headerText', options);
},
messageBody(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.messageBody', options);
},
urlType(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.urlType', options);
},
headerMediaSampleTooltip(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.headerMediaSampleTooltip', options);
},
submitLanguages(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.submitLanguages', options);
},
autofillText(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.autofillText', options);
},
codeExpirationMinutes(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.codeExpirationMinutes', options);
},
packageName(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.packageName', options);
},
signatureHash(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.signatureHash', options);
},
copyCodeButtonText(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.tooltips.copyCodeButtonText', options);
},
},
templateCategoryLabels: {
NO_CATEGORY(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.NO_CATEGORY', options);
},
ACCOUNT_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.ACCOUNT_UPDATE', options);
},
PAYMENT_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.PAYMENT_UPDATE', options);
},
PERSONAL_FINANCE_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.PERSONAL_FINANCE_UPDATE', options);
},
SHIPPING_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.SHIPPING_UPDATE', options);
},
RESERVATION_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.RESERVATION_UPDATE', options);
},
ISSUE_RESOLUTION(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.ISSUE_RESOLUTION', options);
},
APPOINTMENT_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.APPOINTMENT_UPDATE', options);
},
TRANSPORTATION_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.TRANSPORTATION_UPDATE', options);
},
TICKET_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.TICKET_UPDATE', options);
},
ALERT_UPDATE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.ALERT_UPDATE', options);
},
AUTO_REPLY(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.AUTO_REPLY', options);
},
MARKETING(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.MARKETING', options);
},
TRANSACTIONAL(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.TRANSACTIONAL', options);
},
ONE_TIME_PASSWORDS(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.ONE_TIME_PASSWORDS', options);
},
UTILITY(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.UTILITY', options);
},
AUTHENTICATION(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCategoryLabels.AUTHENTICATION', options);
},
},
templateHeaderLabels: {
NONE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateHeaderLabels.NONE', options);
},
TEXT(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateHeaderLabels.TEXT', options);
},
MEDIA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateHeaderLabels.MEDIA', options);
},
},
templateMediaTypeLabels: {
NONE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateMediaTypeLabels.NONE', options);
},
IMAGE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateMediaTypeLabels.IMAGE', options);
},
DOCUMENT(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateMediaTypeLabels.DOCUMENT', options);
},
VIDEO(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateMediaTypeLabels.VIDEO', options);
},
},
templateButtonLabels: {
NONE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateButtonLabels.NONE', options);
},
QUICK_REPLY(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateButtonLabels.QUICK_REPLY', options);
},
CALL_TO_ACTION(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateButtonLabels.CALL_TO_ACTION', options);
},
},
authenticationTemplateButtonLabels: {
COPY_CODE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.authenticationTemplateButtonLabels.COPY_CODE', options);
},
ONE_TAP(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.authenticationTemplateButtonLabels.ONE_TAP', options);
},
},
templateLanguageLabels: {
NO_LANGUAGE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.NO_LANGUAGE', options);
},
AF(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.AF', options);
},
SQ(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.SQ', options);
},
AR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.AR', options);
},
AZ(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.AZ', options);
},
BN(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.BN', options);
},
BG(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.BG', options);
},
CA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.CA', options);
},
ZH_CN(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ZH_CN', options);
},
ZH_HK(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ZH_HK', options);
},
ZH_TW(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ZH_TW', options);
},
HR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.HR', options);
},
CS(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.CS', options);
},
DA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.DA', options);
},
NL(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.NL', options);
},
EN(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.EN', options);
},
EN_GB(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.EN_GB', options);
},
EN_US(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.EN_US', options);
},
ET(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ET', options);
},
FIL(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.FIL', options);
},
FI(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.FI', options);
},
FR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.FR', options);
},
DE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.DE', options);
},
EL(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.EL', options);
},
GU(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.GU', options);
},
HA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.HA', options);
},
HE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.HE', options);
},
HI(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.HI', options);
},
HU(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.HU', options);
},
ID(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ID', options);
},
GA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.GA', options);
},
IT(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.IT', options);
},
JA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.JA', options);
},
KN(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.KN', options);
},
KK(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.KK', options);
},
KO(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.KO', options);
},
LO(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.LO', options);
},
LV(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.LV', options);
},
LT(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.LT', options);
},
MK(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.MK', options);
},
MS(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.MS', options);
},
ML(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ML', options);
},
MR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.MR', options);
},
NB(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.NB', options);
},
FA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.FA', options);
},
PL(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.PL', options);
},
PT_BR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.PT_BR', options);
},
PT_PT(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.PT_PT', options);
},
PA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.PA', options);
},
RO(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.RO', options);
},
RU(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.RU', options);
},
SR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.SR', options);
},
SK(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.SK', options);
},
SL(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.SL', options);
},
ES(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ES', options);
},
ES_AR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ES_AR', options);
},
ES_ES(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ES_ES', options);
},
ES_MX(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ES_MX', options);
},
SW(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.SW', options);
},
SV(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.SV', options);
},
TA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.TA', options);
},
TE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.TE', options);
},
TH(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.TH', options);
},
TR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.TR', options);
},
UK(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.UK', options);
},
UR(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.UR', options);
},
UZ(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.UZ', options);
},
VI(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.VI', options);
},
ZU(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.ZU', options);
},
KA(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.KA', options);
},
RW_RW(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.RW_RW', options);
},
KY_KG(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateLanguageLabels.KY_KG', options);
},
},
templateStates: {
ACTIVE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateStates.ACTIVE', options);
},
INACTIVE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateStates.INACTIVE', options);
},
REJECTED(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateStates.REJECTED', options);
},
PAUSED(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateStates.PAUSED', options);
},
},
rejectScopedNotification: {
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.rejectScopedNotification.description', options);
},
link(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.rejectScopedNotification.link', options);
},
},
templateCallToActionTypeLabels: {
CALL_PHONE_NUMBER(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCallToActionTypeLabels.CALL_PHONE_NUMBER', options);
},
VISIT_WEBSITE(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateCallToActionTypeLabels.VISIT_WEBSITE', options);
},
},
templateUrlTypeLabels: {
STATIC(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateUrlTypeLabels.STATIC', options);
},
DYNAMIC(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.templateUrlTypeLabels.DYNAMIC', options);
},
},
emojiPickerLabels: {
pickYourEmoji(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.pickYourEmoji', options);
},
search(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.search', options);
},
notFound(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.notFound', options);
},
clear(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.clear', options);
},
skintext(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.skintext', options);
},
categorieslabel(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categorieslabel', options);
},
categories: {
search(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.search', options);
},
recent(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.recent', options);
},
people(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.people', options);
},
nature(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.nature', options);
},
foods(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.foods', options);
},
activity(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.activity', options);
},
places(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.places', options);
},
objects(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.objects', options);
},
symbols(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.symbols', options);
},
flags(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.categories.flags', options);
},
},
skintones: {
1(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.skintones.1', options);
},
2(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.skintones.2', options);
},
3(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.skintones.3', options);
},
4(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.skintones.4', options);
},
5(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.skintones.5', options);
},
6(options?: TOptionsBase): string {

return i18n.t('whatsapp.messageTemplates.emojiPickerLabels.skintones.6', options);
},
},
},
},
channels: {
request: {
placeholders: {
vertical(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.request.placeholders.vertical', options);
},
},
},
approvalProgress: {
title(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.approvalProgress.title', options);
},
subtitle(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.approvalProgress.subtitle', options);
},
},
changeRequest(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.changeRequest', options);
},
scopedNotification: {
verificationNeeded(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.scopedNotification.verificationNeeded', options);
},
inactiveChannel(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.scopedNotification.inactiveChannel', options);
},
link(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.scopedNotification.link', options);
},
verificationBySinch(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.scopedNotification.verificationBySinch', options);
},
},
toasts: {
verifyChannelSuccess(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.toasts.verifyChannelSuccess', options);
},
},
numberProviderLabel: {
sinch(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.numberProviderLabel.sinch', options);
},
customer(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.numberProviderLabel.customer', options);
},
},
deleteChannel(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.deleteChannel', options);
},
deleteChannelModal: {
header(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.deleteChannelModal.header', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.deleteChannelModal.description', options);
},
documentPage(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.deleteChannelModal.documentPage', options);
},
},
confirmationModal: {
header(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.confirmationModal.header', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.confirmationModal.description', options);
},
},
errorModal: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.errorModal.heading', options);
},
saveErrorHeading(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.errorModal.saveErrorHeading', options);
},
},
saveDraftModal: {
header(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.saveDraftModal.header', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.saveDraftModal.description', options);
},
},
draftValidationModal: {
header(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.draftValidationModal.header', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.draftValidationModal.description', options);
},
validPhoneNumber(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.draftValidationModal.validPhoneNumber', options);
},
validEmail(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.draftValidationModal.validEmail', options);
},
validWebsite(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.draftValidationModal.validWebsite', options);
},
},
appsLimitModal: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.appsLimitModal.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.appsLimitModal.description', options);
},
documentPage(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.appsLimitModal.documentPage', options);
},
},
channelsLimitModal: {
heading(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.channelsLimitModal.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.channelsLimitModal.description', options);
},
documentPage(options?: TOptionsBase): string {

return i18n.t('whatsapp.channels.channelsLimitModal.documentPage', options);
},
},
},
},
viberBusiness: {
countries: {
AF(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AF', options);
},
AL(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AL', options);
},
AN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AN', options);
},
DZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.DZ', options);
},
AS(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AS', options);
},
AD(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AD', options);
},
AO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AO', options);
},
AI(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AI', options);
},
AG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AG', options);
},
AR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AR', options);
},
AM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AM', options);
},
AW(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AW', options);
},
AU(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AU', options);
},
AT(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AT', options);
},
AZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AZ', options);
},
BS(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BS', options);
},
BH(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BH', options);
},
BD(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BD', options);
},
BB(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BB', options);
},
BY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BY', options);
},
BE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BE', options);
},
BZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BZ', options);
},
BJ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BJ', options);
},
BM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BM', options);
},
BT(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BT', options);
},
BO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BO', options);
},
BA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BA', options);
},
BW(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BW', options);
},
BR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BR', options);
},
BN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BN', options);
},
BG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BG', options);
},
BF(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BF', options);
},
BI(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.BI', options);
},
KH(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KH', options);
},
CM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CM', options);
},
CA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CA', options);
},
CV(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CV', options);
},
KY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KY', options);
},
CF(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CF', options);
},
TD(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TD', options);
},
CL(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CL', options);
},
CN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CN', options);
},
CO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CO', options);
},
KM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KM', options);
},
CG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CG', options);
},
CD(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CD', options);
},
CK(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CK', options);
},
CR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CR', options);
},
HR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.HR', options);
},
CU(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CU', options);
},
CY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CY', options);
},
CZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CZ', options);
},
DK(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.DK', options);
},
DJ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.DJ', options);
},
DM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.DM', options);
},
EC(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.EC', options);
},
EG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.EG', options);
},
SV(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SV', options);
},
GQ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GQ', options);
},
EE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.EE', options);
},
ET(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.ET', options);
},
FO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.FO', options);
},
FJ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.FJ', options);
},
FI(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.FI', options);
},
FR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.FR', options);
},
PF(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PF', options);
},
GA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GA', options);
},
GM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GM', options);
},
GE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GE', options);
},
DE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.DE', options);
},
GH(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GH', options);
},
GI(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GI', options);
},
GR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GR', options);
},
GL(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GL', options);
},
GD(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GD', options);
},
GT(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GT', options);
},
GN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GN', options);
},
GW(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GW', options);
},
GY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GY', options);
},
HN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.HN', options);
},
HK(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.HK', options);
},
HU(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.HU', options);
},
IS(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.IS', options);
},
IN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.IN', options);
},
ID(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.ID', options);
},
IR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.IR', options);
},
IQ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.IQ', options);
},
IE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.IE', options);
},
IL(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.IL', options);
},
IT(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.IT', options);
},
JM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.JM', options);
},
JP(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.JP', options);
},
JO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.JO', options);
},
KZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KZ', options);
},
KE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KE', options);
},
XK(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.XK', options);
},
KW(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KW', options);
},
KG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KG', options);
},
LA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LA', options);
},
LV(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LV', options);
},
LB(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LB', options);
},
LS(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LS', options);
},
LR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LR', options);
},
LY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LY', options);
},
LI(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LI', options);
},
LT(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LT', options);
},
LU(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LU', options);
},
MO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MO', options);
},
MK(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MK', options);
},
MG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MG', options);
},
MW(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MW', options);
},
MY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MY', options);
},
MV(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MV', options);
},
ML(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.ML', options);
},
MT(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MT', options);
},
MR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MR', options);
},
MU(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MU', options);
},
MX(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MX', options);
},
MD(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MD', options);
},
MC(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MC', options);
},
MN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MN', options);
},
ME(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.ME', options);
},
MS(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MS', options);
},
MA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MA', options);
},
MZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MZ', options);
},
MM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.MM', options);
},
NA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NA', options);
},
NP(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NP', options);
},
NL(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NL', options);
},
NC(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NC', options);
},
NZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NZ', options);
},
NI(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NI', options);
},
NE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NE', options);
},
NG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NG', options);
},
NO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.NO', options);
},
OM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.OM', options);
},
PK(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PK', options);
},
PA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PA', options);
},
PG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PG', options);
},
PY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PY', options);
},
PE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PE', options);
},
PH(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PH', options);
},
PL(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PL', options);
},
PT(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PT', options);
},
PR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.PR', options);
},
QA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.QA', options);
},
RO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.RO', options);
},
RU(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.RU', options);
},
RW(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.RW', options);
},
KN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KN', options);
},
LC(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LC', options);
},
VC(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.VC', options);
},
SA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SA', options);
},
SN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SN', options);
},
RS(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.RS', options);
},
SC(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SC', options);
},
SL(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SL', options);
},
SG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SG', options);
},
SK(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SK', options);
},
SI(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SI', options);
},
SB(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SB', options);
},
SO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SO', options);
},
ZA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.ZA', options);
},
KR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.KR', options);
},
ES(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.ES', options);
},
LK(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.LK', options);
},
SD(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SD', options);
},
SR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SR', options);
},
SZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SZ', options);
},
SE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SE', options);
},
CH(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.CH', options);
},
SY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.SY', options);
},
TW(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TW', options);
},
TJ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TJ', options);
},
TZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TZ', options);
},
TH(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TH', options);
},
TG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TG', options);
},
TO(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TO', options);
},
TT(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TT', options);
},
TN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TN', options);
},
TR(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TR', options);
},
TM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.TM', options);
},
UG(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.UG', options);
},
UA(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.UA', options);
},
AE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.AE', options);
},
GB(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.GB', options);
},
US(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.US', options);
},
UY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.UY', options);
},
UZ(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.UZ', options);
},
VU(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.VU', options);
},
VE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.VE', options);
},
VN(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.VN', options);
},
YE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.YE', options);
},
ZM(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.ZM', options);
},
ZW(options?: TOptionsBase): string {

return i18n.t('viberBusiness.countries.ZW', options);
},
},
unknownCountry(options?: TOptionsBase): string {

return i18n.t('viberBusiness.unknownCountry', options);
},
aspClients: {
register: {
heading(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.heading', options);
},
warrantyLetter(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.warrantyLetter', options);
},
descriptionOne(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.descriptionOne', options);
},
descriptionTwo(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.descriptionTwo', options);
},
documentationLink(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.documentationLink', options);
},
tooltips: {
name(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.tooltips.name', options);
},
businessLegalName(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.tooltips.businessLegalName', options);
},
businessCategories(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.tooltips.businessCategories', options);
},
businessRegisteredCountry(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.tooltips.businessRegisteredCountry', options);
},
warrantyLetter(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.tooltips.warrantyLetter', options);
},
},
placeholders: {
businessCategories(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.register.placeholders.businessCategories', options);
},
},
},
details: {
scopedNotification: {
description(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.details.scopedNotification.description', options);
},
link(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.details.scopedNotification.link', options);
},
},
},
fields: {
name(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.fields.name', options);
},
businessLegalName(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.fields.businessLegalName', options);
},
businessCategories(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.fields.businessCategories', options);
},
businessRegisteredCountry(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.fields.businessRegisteredCountry', options);
},
warrantyLetter(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.fields.warrantyLetter', options);
},
status(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.fields.status', options);
},
},
status: {
REQUESTED(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.status.REQUESTED', options);
},
ONBOARDED(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.status.ONBOARDED', options);
},
NOT_REGISTERED(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.status.NOT_REGISTERED', options);
},
REJECTED(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.status.REJECTED', options);
},
UNSPECIFIED(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.status.UNSPECIFIED', options);
},
},
businessCategories: {
BRAND(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.BRAND', options);
},
MEDIA_AGENCY(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.MEDIA_AGENCY', options);
},
FINANCIAL_INSTITUTION(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.FINANCIAL_INSTITUTION', options);
},
COMMUNITY_ORGANIZATION(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.COMMUNITY_ORGANIZATION', options);
},
GOVERNMENTAL_NON_GOVERNMENTAL_ORGANISATION(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.GOVERNMENTAL_NON_GOVERNMENTAL_ORGANISATION', options);
},
PUBLISHER(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.PUBLISHER', options);
},
COMMERCE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.COMMERCE', options);
},
ECOMMERCE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.ECOMMERCE', options);
},
REAL_ESTATE_AND_CONSTRUCTION_COMPANIES(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.REAL_ESTATE_AND_CONSTRUCTION_COMPANIES', options);
},
IT_AND_INTERNET_COMPANIES(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.IT_AND_INTERNET_COMPANIES', options);
},
EDUCATION(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.EDUCATION', options);
},
HEALTHCARE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.HEALTHCARE', options);
},
TRANSPORTATIONS(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.TRANSPORTATIONS', options);
},
RETAIL(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.RETAIL', options);
},
OTHER(options?: TOptionsBase): string {

return i18n.t('viberBusiness.aspClients.businessCategories.OTHER', options);
},
},
},
channels: {
request: {
tooltips: {
account(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.account', options);
},
channelName(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.channelName', options);
},
destinationCountries(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.destinationCountries', options);
},
brandLogo(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.brandLogo', options);
},
description(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.description', options);
},
address(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.address', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.phoneNumber', options);
},
extraDocuments(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.extraDocuments', options);
},
sampleMessage(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.tooltips.sampleMessage', options);
},
},
placeholders: {
account(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.placeholders.account', options);
},
destinationCountries(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.placeholders.destinationCountries', options);
},
businessLaunchDate(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.placeholders.businessLaunchDate', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.placeholders.phoneNumber', options);
},
},
helpTexts: {
destinationCountries(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.helpTexts.destinationCountries', options);
},
brandLogo(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.helpTexts.brandLogo', options);
},
extraDocuments(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.helpTexts.extraDocuments', options);
},
},
sampleMessages(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.sampleMessages', options);
},
extraDocuments(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.extraDocuments', options);
},
confirmationModal: {
heading(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.confirmationModal.heading', options);
},
description(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.confirmationModal.description', options);
},
approvalDescription(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.confirmationModal.approvalDescription', options);
},
documentation: {
description(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.confirmationModal.documentation.description', options);
},
link(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.confirmationModal.documentation.link', options);
},
},
},
saveDraftModal: {
heading(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.saveDraftModal.heading', options);
},
newTemplateBody(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.saveDraftModal.newTemplateBody', options);
},
},
errorModal: {
heading(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.errorModal.heading', options);
},
saveErrorHeading(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.errorModal.saveErrorHeading', options);
},
requiredHeading(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.errorModal.requiredHeading', options);
},
requiredForNewDraft(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.errorModal.requiredForNewDraft', options);
},
invalidPhoneNumber(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.errorModal.invalidPhoneNumber', options);
},
invalidWebsite(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.errorModal.invalidWebsite', options);
},
forbiddenFacebookInstagramDomain(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.request.errorModal.forbiddenFacebookInstagramDomain', options);
},
},
},
fields: {
account(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.account', options);
},
channelName(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.channelName', options);
},
destinationCountries(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.destinationCountries', options);
},
businessLaunchDate(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.businessLaunchDate', options);
},
brandLogo(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.brandLogo', options);
},
uploadLogoImage(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.uploadLogoImage', options);
},
scaled(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.scaled', options);
},
description(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.description', options);
},
address(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.address', options);
},
countryCode(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.countryCode', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.phoneNumber', options);
},
website(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.website', options);
},
sampleMessage(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.sampleMessage', options);
},
channelStatus(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.fields.channelStatus', options);
},
},
status: {
UNSPECIFIED(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.status.UNSPECIFIED', options);
},
ACTIVE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.status.ACTIVE', options);
},
INACTIVE(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.status.INACTIVE', options);
},
DECLINED(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.status.DECLINED', options);
},
},
activate(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.activate', options);
},
deactivate(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.deactivate', options);
},
confirmation(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.confirmation', options);
},
activateModal: {
description(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.activateModal.description', options);
},
},
deactivateModal: {
description(options?: TOptionsBase): string {

return i18n.t('viberBusiness.channels.deactivateModal.description', options);
},
},
},
},
kakaoTalk: {
channels: {
fields: {
profileId(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.fields.profileId', options);
},
channelName(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.fields.channelName', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.fields.phoneNumber', options);
},
topLevelCategory(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.fields.topLevelCategory', options);
},
midLevelCategory(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.fields.midLevelCategory', options);
},
subLevelCategory(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.fields.subLevelCategory', options);
},
},
request: {
tooltips: {
profileId(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.tooltips.profileId', options);
},
channelName(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.tooltips.channelName', options);
},
phoneNumber(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.tooltips.phoneNumber', options);
},
topLevelCategory(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.tooltips.topLevelCategory', options);
},
midLevelCategory(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.tooltips.midLevelCategory', options);
},
subLevelCategory(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.tooltips.subLevelCategory', options);
},
},
placeholders: {
phoneNumber(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.placeholders.phoneNumber', options);
},
},
errorModal: {
heading(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.errorModal.heading', options);
},
saveErrorHeading(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.errorModal.saveErrorHeading', options);
},
invalidPhoneNumber(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.errorModal.invalidPhoneNumber', options);
},
},
collectData(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.request.collectData', options);
},
},
confirmationModal: {
header(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.confirmationModal.header', options);
},
description(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.confirmationModal.description', options);
},
},
draftValidationModal: {
header(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.draftValidationModal.header', options);
},
description(options?: TOptionsBase): string {

return i18n.t('kakaoTalk.channels.draftValidationModal.description', options);
},
},
},
},
}
