import React from 'react';
import {
  FieldInputProps,
  useField,
  useFormikContext,
  FieldMetaProps,
} from 'formik';
import { Checkbox, ICheckboxProps } from '@salesforce/design-system-react';

import { RequiredFieldLabel } from './RequiredFieldLabel';

interface ICheckboxFieldProps<TObject> extends ICheckboxProps {
  name: keyof TObject & string;
  id?: string;
}

const fieldToCheckbox = (
  { onChange, ...field }: FieldInputProps<boolean>,
  isSubmitting: boolean,
  setFieldValue: (name: string, value: boolean) => void,
  { error, touched }: FieldMetaProps<boolean>,
  {
    disabled,
    labels: rawLabels,
    required,
    id,
    ...props
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ICheckboxFieldProps<any>
): ICheckboxProps => {
  const label =
    required && rawLabels?.label ? (
      <RequiredFieldLabel>{rawLabels.label}</RequiredFieldLabel>
    ) : (
      rawLabels?.label
    );
  const labels = {
    ...rawLabels,
    label: label as string,
  };

  return {
    disabled: disabled ?? isSubmitting,
    errorText: touched ? error : '',
    labels,
    id,
    onChange: (_, { checked }) => {
      setFieldValue(field.name, checked);
    },
    ...field,
    ...props,
  };
};

// any is required for component to work properly in cases
// where no generic parameter is provided
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CheckboxField<TObject = any>(
  props: ICheckboxFieldProps<TObject>
) {
  const [field, meta] = useField(props.name);
  const { isSubmitting, setFieldValue } = useFormikContext();

  return (
    <Checkbox
      {...fieldToCheckbox(field, isSubmitting, setFieldValue, meta, props)}
    />
  );
}
