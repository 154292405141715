import * as Yup from 'yup';

import { t } from '@src/messages';

const { e164, countryCode } = t.common.validation.phoneNumber;
const { httpHttps, https } = t.common.validation.url;

export const TEST_INVALID_PHONE_NUMBER = 'invalid_E164_phone_number';
export const TEST_INVALID_SUBSCRIBER_NUMBER = 'invalid_subscriber_number';
export const TEST_HTTP_HTTPS = 'http/https';
export const TEST_FACEBOOK_INSTAGRAM_DOMAIN =
  'forbidden_facebook_and_instagram_domain';
export const TEST_WHATSAPP_DOMAIN = 'forbidden_whatsapp_domain';
export const TEST_INVALID_URL_VARIABLE = 'invalid_url_variable';

export const phoneNumberE164Regex = /^\+?[1-9]\d{1,14}$/;
export const phoneNumberE164MaxLength = '15';
export const subscriberPhoneNumberMaxLength = 14;
export const getPhoneNumberE164Schema = () =>
  Yup.string().matches(phoneNumberE164Regex, {
    message: e164(),
    excludeEmptyString: true,
  });

/**
 * Regex for validating a phone number's country code part which
 * signifies the country that houses the number, the "+123" part.
 *
 * According to the E164 specification, a country code consists
 * of 3 digits, after the country code follows the Group
 * Identification Code (GIC). Since we let the user to input the
 * country code and GIC in the same field we add it to the country
 * code validation regex.
 */
export const phoneNumberE164CountryCodeRegex = /^\d{1,4}$/;
export const getPhoneNumberE164CountryCodeSchema = () =>
  Yup.string().matches(phoneNumberE164CountryCodeRegex, {
    message: countryCode(),
    excludeEmptyString: true,
  });

export const getHttpHttpsSchema = () =>
  Yup.string().test(TEST_HTTP_HTTPS, httpHttps(), (url: string): boolean => {
    if (!url) return true;
    const regex =
      // eslint-disable-next-line security/detect-unsafe-regex
      /^((http|https):\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*(\.[a-z]{2,5})?(:[0-9]{1,5})?(\/.*)?$/;
    return regex.test(url);
  });

export const getHttpsSchema = () =>
  Yup.string().test('https', https(), (url: string): boolean => {
    if (!url) return true;
    const regex =
      // eslint-disable-next-line security/detect-unsafe-regex
      /^((https):\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*(\.[a-z]{2,5})?(:[0-9]{1,5})?(\/.*)?$/;
    return regex.test(url);
  });
