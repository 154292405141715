import { gql } from '@apollo/client';

import { USERINFO_FRAGMENT, IUserInfoFragment } from '../fragments';

export interface IUserInfoQueryResponse {
  userInfo: IUserInfoFragment;
}

export const USERINFO_QUERY = gql`
  query UserInfo {
    userInfo {
      ...UserInfoFragment
    }
  }
  ${USERINFO_FRAGMENT}
`;
