import React, { ReactNode } from 'react';
import { Modal, ModalSize } from '@salesforce/design-system-react';
import { t } from 'messages';

import { Button } from '../Button';

interface IFormModalProps {
  heading: ReactNode;
  children: ReactNode;
  isOpen: boolean;
  size?: ModalSize;
  disableSubmit?: boolean;
  cancelLabel?: ReactNode;
  submitLabel?: ReactNode;
  onCancel: () => void;
  onSubmit?: () => void;
  submitting?: boolean;
}

export const FormModal = ({
  heading,
  children,
  isOpen,
  size,
  onCancel,
  onSubmit,
  cancelLabel,
  submitLabel,
  submitting = false,
  disableSubmit = false,
}: IFormModalProps) => (
  <Modal
    heading={heading}
    footer={[
      <Button onClick={onCancel} id="modal-cancel-button" key="cancel">
        {cancelLabel || t.common.actions.cancel()}
      </Button>,
      <Button
        variant="brand"
        type="submit"
        onClick={() => onSubmit?.()}
        loading={submitting}
        disabled={disableSubmit}
        key="submit"
        id="modal-submit-button"
      >
        {submitLabel || t.common.actions.save()}
      </Button>,
    ]}
    isOpen={isOpen}
    ariaHideApp={false}
    size={size}
    dismissOnClickOutside={false}
    onRequestClose={onCancel}
  >
    {children}
  </Modal>
);
