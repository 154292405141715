import 'core-js';
import React from 'react';
import { render } from 'react-dom';
import { Settings } from '@salesforce/design-system-react';
import { Workbox } from 'workbox-window';

import { App } from './App';

declare global {
  interface Window {
    LD_CLIENT_ID: string;
    SERVICE_WORKER_ENABLED: boolean;
    ENVIRONMENT: string;
  }
}

const appRoot = document.querySelector('#react-root')!;
Settings.setAppElement(appRoot);

render(<App />, appRoot);

// Service worker should be instantiated last in the bootstrap
// process. This prevents it from competing with the main thread
if (window.SERVICE_WORKER_ENABLED) {
  if ('serviceWorker' in navigator) {
    console.log('Service worker supported');
    const workbox = new Workbox('/service-worker.js');

    // When a new service worker is available (a new version has been deployed)
    // it will enter the waiting state.
    workbox.addEventListener('waiting', () => {
      workbox.addEventListener('controlling', () => {
        // Update the app since the new service worker is now loaded with
        // the new version.
        window.location.reload();
      });

      // Tell the service worker to stop waiting and instead replace the
      // existing one instantly. When the operation has finished the
      // 'controlling' event will be triggered.
      workbox.messageSkipWaiting();
    });
  } else {
    console.log('Service worker not supported');
  }
} else {
  console.log('Service worker disabled');
}
