import React from 'react';
import styled from 'styled-components';
import { Modal } from '@salesforce/design-system-react';
import { useLocation } from 'react-router';

import { Box, Text } from '@src/common/components';

const Background = styled.div({
  zIndex: -1,
  position: 'absolute',
  top: 0,
  width: '100%',
  left: 0,
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const InternalError = () => {
  const query = useQuery();

  return (
    <Box pt="small" pl="small" pr="small" pb="small">
      <Background>
        <div className="slds-brand-band slds-brand-band_large"></div>
      </Background>
      <Modal
        disableClose
        isOpen
        prompt="error"
        size="small"
        header="Unexpected Error"
      >
        <Box pt="large" pl="large" pr="large" pb="large">
          <Text align="center">{query.get('message')}</Text>
          <Text align="right" variant="body_small" color="weak" mt="small">
            code: {query.get('code')}
          </Text>
        </Box>
      </Modal>
    </Box>
  );
};
