import React, { ReactNode } from 'react';
import styled from 'styled-components';
import {
  ScopedNotification as LDSScopedNotification,
  IScopedNotificationProps as LDSIScopedNotificationProps,
} from '@salesforce/design-system-react';

import { SFIcon } from '../SFIcon';

type ScopedNotificationVariant = 'info' | 'error' | 'warning' | 'success';

interface IScopedNotificationProps extends LDSIScopedNotificationProps {
  children: ReactNode;
  variant?: ScopedNotificationVariant;
}

const iconMapper: Record<ScopedNotificationVariant, ReactNode> = {
  info: <SFIcon name="info" />,
  error: <SFIcon name="error" inverse />,
  warning: <SFIcon name="warning" />,
  success: <SFIcon name="success" inverse />,
};

const backgroundColorMapper: Record<ScopedNotificationVariant, string> = {
  info: '#ECEBEA',
  error: '#C33630',
  warning: '#FFB75D',
  success: '#04844B',
};

const Wrapper = styled(LDSScopedNotification)(
  ({ variant = 'info' }: IScopedNotificationProps) => ({
    backgroundColor: backgroundColorMapper[variant],

    '& *': {
      color:
        variant === 'warning' || variant === 'info'
          ? '#080707 !important'
          : '#FFF',
      '& a': {
        fontWeight: 700,
      },
    },
  })
);

export const ScopedNotification = ({
  theme = 'dark',
  variant = 'error',
  icon,
  ...props
}: IScopedNotificationProps) => {
  return (
    <Wrapper
      icon={icon || iconMapper[variant]}
      theme={theme}
      variant={variant}
      {...props}
    />
  );
};
