import React, { ReactNode, FunctionComponentElement } from 'react';
import {
  PageHeader as SFPageHeader,
  PageHeaderControl as SFPageHeaderControl,
  IPageHeaderProps as SFIPageHeaderProps,
  IIconProps,
} from '@salesforce/design-system-react';
import { Link } from 'react-router-dom';

import { EntityIcon, AccountLogo, MessageTemplateLogo } from '@src/assets';
import { t } from '@src/messages';
import { Channel } from '@src/common/types';

import { SFIcon } from '../SFIcon';
import { Box } from '../Box';

type PageHeaderSectionType = 'home' | 'channel' | 'account' | 'messageTemplate';

type Breadcrumb = {
  header: string;
  to: string;
};

interface IPageHeaderProps extends SFIPageHeaderProps {
  actions?: ReactNode;
  channelType?: Channel;
  sectionType?: PageHeaderSectionType;
  breadcrumbs?: Breadcrumb[];
  id?: string;
}

const pageHeaderIcons: Record<
  PageHeaderSectionType,
  FunctionComponentElement<IIconProps>
> = {
  channel: <SFIcon name="channel" />,
  account: <AccountLogo />,
  home: <EntityIcon />,
  messageTemplate: <MessageTemplateLogo />,
};

export const PageHeader = ({
  actions,
  channelType,
  sectionType = 'home',
  variant = 'object-home',
  breadcrumbs = [],
  id,
  ...props
}: IPageHeaderProps) => {
  const icon = pageHeaderIcons[sectionType];
  const breadcrumbTrails = breadcrumbs.map(({ to, header }) => (
    <Link to={to}>{header}</Link>
  ));
  const trail = [
    <Box pr="x-small">
      <span>
        {channelType
          ? t.common.channelNames[channelType]()
          : t.common.appTitle()}
      </span>
    </Box>,
    ...breadcrumbTrails,
  ];
  const onRenderActions = () => (
    <SFPageHeaderControl>{actions}</SFPageHeaderControl>
  );

  return (
    <div id={id}>
      <SFPageHeader
        icon={icon}
        trail={trail}
        variant={variant}
        onRenderActions={onRenderActions}
        {...props}
      />
    </div>
  );
};
