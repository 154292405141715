import React from 'react';
import styled from 'styled-components';

import { Box } from '@src/common/components';

import { Sidebar } from './Sidebar';
import { Subroutes } from './Subroutes';
import { ErrorBoundary } from './ErrorBoundary';
import { SIDEBAR_WIDTH } from './constants';

const Background = styled.div({
  zIndex: -1,
  position: 'absolute',
  top: 0,
  width: '100%',
  left: 0,
});

const SIDEBAR_WIDTH_PX = `${SIDEBAR_WIDTH}px`;

const SidebarWrapper = styled.div({
  height: '100%',
  width: SIDEBAR_WIDTH_PX,
  position: 'fixed',
  backgroundColor: 'white',
  overflow: 'hidden',
});

const MainWrapper = styled.div({
  marginLeft: SIDEBAR_WIDTH_PX,
});

export const Layout = () => {
  return (
    <>
      <SidebarWrapper>
        <Sidebar />
      </SidebarWrapper>
      <MainWrapper>
        <Box pt="small" pl="small" pr="small" pb="small">
          <Background>
            <div className="slds-brand-band slds-brand-band_large"></div>
          </Background>
          <ErrorBoundary type="view">
            <Subroutes />
          </ErrorBoundary>
        </Box>
      </MainWrapper>
    </>
  );
};
