import React from 'react';
import {
  useQuery,
  QueryResult,
  OperationVariables,
  DocumentNode,
  WatchQueryFetchPolicy,
} from '@apollo/client';
import styled from 'styled-components';

import { t } from '@src/messages';
import { SIDEBAR_WIDTH } from '@src/constants';

import { Spinner } from '../Spinner';
import { NotFound } from '../NotFound';

import { IQueryHandlerResult } from './IQueryHandlerResult';

interface ISpinnerWrapperProps {
  levitate?: boolean;
}

interface IQueryHandlerProps {
  query: DocumentNode;
  variables?: Record<string, unknown>;
  children: (
    // Correct type is provided by users of QueryHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: IQueryHandlerResult<any, OperationVariables>
  ) => JSX.Element | null;
  levitate?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
}

interface IApolloError {
  extensions?: {
    code: string;
  };
}

interface INetworkError extends Error {
  result?: {
    errors?: Array<IApolloError>;
  };
  statusCode: number;
}

const SpinnerWrapper = styled.div(
  ({ levitate = false }: ISpinnerWrapperProps) => ({
    '& .slds-spinner_container': {
      left: levitate ? SIDEBAR_WIDTH : 0,
    },
  })
);

const queryHandlerTexts = t.common.queryHandler;

export const QueryHandler = ({
  query,
  variables,
  children,
  levitate,
  fetchPolicy = 'network-only',
}: IQueryHandlerProps) => {
  const resultProps = useQuery<QueryResult<unknown, OperationVariables>>(
    query,
    {
      variables,
      fetchPolicy,
    }
  );

  const { loading, data, error } = resultProps;
  const networkError = (error && error.networkError) as INetworkError;
  const graphQlErrors = (error && error.graphQLErrors) || [];
  const uncaughtErrorOccurred = !!(networkError ?? graphQlErrors.length > 0);

  if (loading) {
    return (
      <SpinnerWrapper levitate={levitate}>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const isAuthError = networkError?.result?.errors?.some(
    (e) => e.extensions?.code === 'UNAUTHENTICATED'
  );
  if (isAuthError) {
    window.location.href = '/api/auth/login';
    return null;
  }

  const notFound = graphQlErrors?.some(
    (e) => e.extensions?.code === 'NOT_FOUND'
  );
  if (notFound) {
    return <NotFound />;
  }

  if (uncaughtErrorOccurred) {
    throw new Error(queryHandlerTexts.error());
  }

  if (data === undefined || data === null) {
    throw new Error(queryHandlerTexts.noData());
  }

  return children(resultProps);
};
