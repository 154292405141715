export const languageDirection = (languageCode: string) => {
  switch (languageCode) {
    case 'AR':
    case 'AZ':
    case 'HE':
    case 'FA':
    case 'UR':
    case 'DV':
    case 'KU':
    case 'PS':
    case 'SD':
      return 'rtl';
    default:
      return 'ltr';
  }
};
