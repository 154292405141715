export const getFileName = (url?: string): string => {
  if (!url) {
    return '';
  }

  const lastIndex = url.lastIndexOf('/');
  if (lastIndex < 0) {
    return '';
  }

  return url.substring(lastIndex + 1);
};
