import React from 'react';
import {
  Spinner as SFSpinner,
  ISpinnerProps,
} from '@salesforce/design-system-react';

export const Spinner = ({
  size = 'medium',
  variant = 'brand',
  ...props
}: ISpinnerProps) => <SFSpinner size={size} variant={variant} {...props} />;
