export const getCyItemIdentifier = (
  item:
    | { id?: string; aspClientId?: string; name?: string }
    | string
    | undefined
) => {
  let identifier = '';

  if (item !== undefined && typeof item !== 'string') {
    if (item?.id) identifier += '-' + item.id.split('-').pop();
    if (item?.aspClientId)
      identifier += '-' + item.aspClientId.split('-').pop();
    if (item?.name) identifier += '-' + item.name;
  }

  return identifier;
};
