import React, { ReactNode } from 'react';
import { Tooltip } from '@salesforce/design-system-react';

import { RequiredFieldLabel } from '../forms/RequiredFieldLabel';
import { Box } from '../Box';

interface ILabelProps {
  children: ReactNode;
  tooltip: string;
  id: string;
  required?: boolean;
}

export const Label = ({ children, tooltip, id, required }: ILabelProps) => {
  return (
    <span>
      {required && <RequiredFieldLabel>{children}</RequiredFieldLabel>}
      {!required && <Box display="inline-flex">{children}</Box>}
      <Box display="inline" mr={'x-small'} />
      <Tooltip id={id} align="top left" content={tooltip} variant="learnMore" />
    </span>
  );
};
