import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import omitDeep from 'omit-deep-lodash';

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'Access-token': localStorage.getItem('accessToken'),
    },
  };
});

const httpLink = createHttpLink({
  uri: '/api/graphql',
  credentials: 'same-origin',
});

const omitTypeNameLink = new ApolloLink((operation, forward) => {
  operation.variables = omitDeep(operation.variables || {}, ['__typename']);

  return forward(operation);
});

export const bffApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(omitTypeNameLink).concat(httpLink),
  defaultOptions: {
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});
