import { gql } from '@apollo/client';

import { IUploadCroppedImagesResponse } from '@shared/bff';

export interface IUploadCroppedImagesMutationResponse {
  uploadCroppedImages: IUploadCroppedImagesResponse;
}

export const UPLOAD_CROPPED_IMAGES_MUTATION = gql`
  mutation UploadCroppedImages($file: FileInput!, $sizes: [SizeInput!]!) {
    uploadCroppedImages(file: $file, sizes: $sizes) {
      images {
        url
        width
        height
      }
    }
  }
`;
