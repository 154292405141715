import React from 'react';
import { DataTableCell as SFDataTableCell } from '@salesforce/design-system-react';
import styled from 'styled-components';

import { getCyItemIdentifier } from './utils';

interface IFormattedCellProps {
  children?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  format?: (item: any, rawData: any) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatTitle?: (item: any) => string;
  // Formatting functions are intended to provide the correct
  // implementation for how the any is converted into a proper
  // value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  item?: any;
  columnId?: string;
}

const SFDataTableCellWrapper = styled(SFDataTableCell)({
  paddingLeft: '16px !important',
});

export const FormattedCell = ({
  format,
  formatTitle,
  item,
  children,
  columnId,
  ...props
}: IFormattedCellProps) => {
  const content = format && children ? format(children, item) : children;
  const title = formatTitle && children ? formatTitle(children) : children;

  return (
    <SFDataTableCellWrapper title={title} {...props}>
      {children && (
        <span
          {...(columnId
            ? { id: `${columnId}${getCyItemIdentifier(item)}` }
            : {})}
        >
          {content}
        </span>
      )}
    </SFDataTableCellWrapper>
  );
};

FormattedCell.displayName = SFDataTableCell.displayName;
