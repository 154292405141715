import React from 'react';

interface MultiLineErrorMessageProps {
  errors: string | string[] | undefined;
  touched: boolean;
  render(errorText: string): React.ReactNode;
}

export const MultiLineErrorMessage = ({
  errors,
  touched,
  render,
}: MultiLineErrorMessageProps) => {
  const errorList = errors ? (Array.isArray(errors) ? errors : [errors]) : [];

  if (touched && errorList.length > 0) {
    return (
      <div>
        {render(' ')}
        <div className="slds-has-error">
          {errorList.map((error, index) => (
            <div key={index} className="slds-form-element__help slds-has-error">
              {error}
            </div>
          ))}
        </div>
      </div>
    );
  }
  return <>{render('')}</>;
};
