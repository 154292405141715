import React, { ReactNode } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Tooltip } from '@salesforce/design-system-react';

import { Box } from '../Box';

export interface IDescriptionListItem {
  label: ReactNode;
  detail: ReactNode;
  id?: string;
  tooltip?: string;
}

type DescriptionListType = 'horizontal' | 'inline' | 'base';

type DetailsTextAlignmentType = 'left' | 'right';

interface IDescriptionListProps {
  items: IDescriptionListItem[];
  type?: DescriptionListType;
  spaceBetweenColumns?: boolean;
  detailsTextAlignment?: DetailsTextAlignmentType;
}

const DescriptionListWrapper = styled.div({
  width: '100%',
});

const DescriptionListItemWrapper = styled(Box)(({ justifyContent }) => ({
  width: '100%',
  justifyContent: justifyContent,
  '&:last-child ': {
    marginBottom: 0,
  },
}));

const DetailsWrapper = styled.div(
  ({ alignment }: { alignment: DetailsTextAlignmentType }) => ({
    textAlign: alignment,
    'word-break': 'break-all',
  })
);

export const DescriptionList = ({
  items,
  type = 'horizontal',
  spaceBetweenColumns,
  detailsTextAlignment = 'left',
  ...props
}: IDescriptionListProps) => {
  const drClasses = classNames('slds-text-body_small', 'slds-text-color_weak', {
    [`slds-dl_${type}__label`]: !spaceBetweenColumns && type !== 'base',
  });

  const ddClasses = classNames({
    [`slds-dl_${type}__detail`]: !spaceBetweenColumns && type !== 'base',
  });

  return (
    <Box pt="small" pb="small">
      <DescriptionListWrapper className="slds-region_narrow">
        <dl className={type !== 'base' ? `slds-dl_${type}` : ''} {...props}>
          {items.map(({ label, detail, id, tooltip }, index) => {
            return (
              <DescriptionListItemWrapper
                key={`${label}-${detail}-${index}`}
                mb="medium"
                display={type !== 'base' ? 'flex' : 'block'}
                justifyContent={
                  spaceBetweenColumns ? 'space-between' : 'flex-start'
                }
              >
                <dt className={drClasses}>
                  {label}
                  {tooltip && (
                    <>
                      <Tooltip
                        content={tooltip}
                        id={'${id}-tooltip'}
                        variant="learnMore"
                      />
                    </>
                  )}
                </dt>
                <dd className={ddClasses} id={id}>
                  <DetailsWrapper alignment={detailsTextAlignment}>
                    {detail}
                  </DetailsWrapper>
                </dd>
              </DescriptionListItemWrapper>
            );
          })}
        </dl>
      </DescriptionListWrapper>
    </Box>
  );
};
