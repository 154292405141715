import React, { useMemo, useContext } from 'react';

import { formatDateTime, mapCrStateToType } from '@src/common/utils';
import { UserContext } from '@src/common/components/UserProvider';
import { t } from '@src/messages';
import { CrState } from '@shared/bff';

import { DescriptionList, IDescriptionListItem } from '../DescriptionList';
import { LabelTag } from '../LabelTag';
import { Card } from '../Card';
import { Box } from '../Box';

export interface IChangeRequestInfoSectionProps {
  cr: {
    created: string;
    state: CrState;
  };
}

export const ChangeRequestInfoSection = ({
  cr,
}: IChangeRequestInfoSectionProps) => {
  const { user } = useContext(UserContext);
  const items = useMemo<IDescriptionListItem[]>(
    () => [
      {
        label: t.common.components.changeRequestInfoSection.crCreated(),
        detail: formatDateTime(cr.created, user.zoneInfo, user.locale),
      },
      {
        label: t.common.components.changeRequestInfoSection.crState(),
        detail: (
          <LabelTag type={mapCrStateToType(cr.state as CrState)}>
            {t.common.changeRequestStateLabels[cr.state]()}
          </LabelTag>
        ),
      },
    ],
    []
  );
  return (
    <Card
      heading={t.common.components.changeRequestInfoSection.changeRequest()}
    >
      <Box pl="medium" pr="medium" mt="xx-small">
        <DescriptionList items={items} />
      </Box>
    </Card>
  );
};
