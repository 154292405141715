import React, { useCallback, useContext } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { t } from '@src/messages';

import { Box } from '../Box';
import { Text } from '../Text';
import { UploadButton } from '../UploadButton';
import { ToastContext } from '../ToastProvider';

export interface IDragDropProps {
  options?: DropzoneOptions;
  onLoad: (filename: string, reader: FileReader) => void;
}

const DropZone = styled.div({
  padding: '32px 16px',
  border: '1px dashed #DDDBDA',
  borderRadius: '4px',
  marginTop: '15px',
  height: '240px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const defaultOptions = {
  multiple: false,
  accept: 'image/jpeg, image/png',
  noClick: true,
  noKeyboard: true,
};

export const DragDrop = ({ options, onLoad }: IDragDropProps) => {
  const { toast } = useContext(ToastContext);

  const mergedOptions = { ...defaultOptions, ...options };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => onLoad(file.name, reader);
        reader.readAsDataURL(file);
      });
    },
    [onLoad]
  );
  const onDropRejected = async () => {
    toast('error', {
      heading: t.common.toasts.uploadRejected(),
    });
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    ...mergedOptions,
  });

  return (
    <DropZone {...getRootProps()} id="drop-zone">
      <input {...getInputProps()} />
      <div>
        <Box display="flex" justifyContent="center" mb="medium">
          <UploadButton
            onClick={open}
            title={t.common.components.imageResolver.uploadButtonDescription()}
            id="choose-image-button"
          />
        </Box>
        <Text color="weak" align="center">
          {t.common.components.imageResolver.dropZoneLabel()}
        </Text>
      </div>
    </DropZone>
  );
};
