import React, { ReactNode } from 'react';
import {
  FieldInputProps,
  useField,
  useFormikContext,
  FieldMetaProps,
  FieldHelperProps,
} from 'formik';
import {
  Input,
  IInputProps,
  Tooltip,
  OnInputChangeCallback,
} from '@salesforce/design-system-react';
import styled from 'styled-components';

import { uuid } from '@src/common/utils';
import { emojiRegex } from '@src/modules/whatsapp/messageTemplates/utils';

import { GridItem, Grid } from '../Grid';

import { RequiredFieldLabel } from './RequiredFieldLabel';
import { CharactersLimit } from './CharactersLimit';
import { RegexReplacements } from './TextareaField';

const RightAdornment = styled.div({
  position: 'absolute',
  right: 0,
  top: 31,
  paddingRight: 8,
  color: '#706E6B',
});

interface IInputFieldProps<TObject> extends IInputProps {
  name: keyof TObject & string;
  regexConstraint?: RegExp;
  regexReplace?: RegexReplacements;
  excludeEmojis?: boolean;
  excludeLeadingSpace?: boolean;
  tooltip?: ReactNode;
  rightActions?: ReactNode;
  rightAdornment?: ReactNode;
  id?: string;
  hideCharactersLimit?: boolean;
}

const fieldToInput = (
  {
    value,
    onChange: fieldOnChange,
    onBlur: fieldOnBlur,
    ...field
  }: FieldInputProps<string | number>,
  isSubmitting: boolean,
  { error, touched }: FieldMetaProps<string | number>,
  { setValue, setTouched }: FieldHelperProps<string | number | undefined>,
  {
    disabled,
    tooltip,
    label: rawLabel,
    regexConstraint,
    regexReplace,
    excludeEmojis,
    excludeLeadingSpace,
    required,
    ...props
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IInputFieldProps<any>
): IInputProps => {
  const label = required ? (
    <RequiredFieldLabel>{rawLabel}</RequiredFieldLabel>
  ) : (
    rawLabel
  );
  const onChange: OnInputChangeCallback = (_, event) => {
    let newValue: string | number | undefined = event.value;
    if (props.type === 'number' && typeof newValue === 'string') {
      if (newValue === '') {
        newValue = undefined;
      } else {
        newValue = parseInt(newValue);
        if (props.minValue && newValue < props.minValue) {
          newValue = props.minValue;
        }
        if (props.maxValue && newValue > props.maxValue) {
          newValue = props.maxValue;
        }
      }
    }
    if (
      excludeLeadingSpace &&
      typeof newValue === 'string' &&
      newValue.charAt(0) === ' '
    ) {
      newValue = newValue.trim();
    }
    if (excludeEmojis && typeof newValue === 'string') {
      newValue = newValue.replaceAll(emojiRegex, '');
    }
    if (regexConstraint && typeof newValue === 'string') {
      newValue = newValue.replaceAll(regexConstraint, '');
    }

    if (regexReplace && typeof newValue === 'string') {
      regexReplace.forEach(
        (replacement) =>
          (newValue = (newValue as string).replaceAll(
            replacement.searchValue,
            replacement.replacer
          ))
      );
    }

    setValue(newValue);
  };
  const onBlur = () => {
    let newValue = value;
    if (typeof newValue === 'string') {
      newValue = newValue.trim();
    }
    setValue(newValue);

    if (!value && !touched) {
      return;
    }
    setTouched(true);
  };
  return {
    disabled: disabled ?? isSubmitting,
    errorText: touched && !disabled ? error : '',
    // SalesForce input can't handle null values so we coerce them to
    // an empty string
    value: value ?? '',
    // Label can only be as a string, but we need to handle required fields
    label: label as string,
    fieldLevelHelpTooltip: tooltip ? (
      <Tooltip
        id={`${uuid()}-level-help-tooltip`}
        align="top left"
        content={tooltip}
        variant="learnMore"
      />
    ) : undefined,
    onChange,
    onBlur,
    ...field,
    ...props,
  };
};

// any is required for component to work properly in cases
// where no generic parameter is provided
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function InputField<TObject = any>({
  maxLength,
  rightActions,
  rightAdornment,
  id,
  hideCharactersLimit,
  ...props
}: IInputFieldProps<TObject>) {
  const [field, meta, helpers] = useField(props.name);
  const { isSubmitting } = useFormikContext();

  return (
    <Input
      id={id}
      maxLength={maxLength}
      {...fieldToInput(field, isSubmitting, meta, helpers, props)}
    >
      {rightAdornment && <RightAdornment>{rightAdornment}</RightAdornment>}
      <Grid>
        <GridItem cols={rightActions ? 4 : 12}>
          {!hideCharactersLimit && (
            <CharactersLimit value={field.value} maxLength={maxLength} />
          )}
        </GridItem>
        {rightActions && <GridItem cols={8}>{rightActions}</GridItem>}
      </Grid>
    </Input>
  );
}
