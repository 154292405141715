import React from 'react';
import { Link } from 'react-router-dom';
import { DataTableCell as SFDataTableCell } from '@salesforce/design-system-react';
import styled from 'styled-components';

import { getCyItemIdentifier } from './utils';

interface ILinkCellProps {
  children?: string;
  item?: string;
  getPath?: (item?: string) => string;
  columnId?: string;
  truncate?: boolean;
}

const SFDataTableCellWrapper = styled(SFDataTableCell)({
  paddingLeft: '16px !important',
});

export const LinkCell = ({
  children,
  item,
  getPath,
  columnId,
  truncate,
}: ILinkCellProps) => (
  <SFDataTableCellWrapper fixedLayout={truncate}>
    <Link
      to={item && getPath ? getPath(item) : '#'}
      {...(columnId ? { id: `${columnId}${getCyItemIdentifier(item)}` } : {})}
    >
      {children}
    </Link>
  </SFDataTableCellWrapper>
);

LinkCell.displayName = SFDataTableCell.displayName;
