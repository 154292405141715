import React from 'react';
import styled from 'styled-components';
import { Spinner } from '@salesforce/design-system-react';

import { t } from '@src/messages';
import { FileTypeMapper } from '@src/common/utils';
import { IExtraDoc, IExtraDocInput } from '@shared/bff';

import { Box } from '../Box';
import { SFIcon } from '../SFIcon';
import { Button } from '../Button';

interface IFilesListProps {
  files: IExtraDoc[] | IExtraDocInput[];
  uploading: boolean;
  onDeleteFile: (index: number) => void;
}

const FileWrapper = styled(Box)({
  '& .delete-button': {
    visibility: 'hidden',
  },
  '&:hover': {
    backgroundColor: '#F3F2F2',
    '& .delete-button': {
      visibility: 'visible',
    },
  },
});

const FileName = styled.div({
  width: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const SpinnerWrapper = styled.div({
  position: 'relative',
  height: '40px',
  width: '40px',
});

export const FilesList = ({
  files,
  uploading,
  onDeleteFile,
}: IFilesListProps) => {
  return (
    <Box mt="small">
      {files.map(({ name, type }, index) => (
        <FileWrapper
          pl="small"
          pb="xx-small"
          pr="small"
          pt="xx-small"
          display="flex"
          justifyContent="space-between"
        >
          <Box display="inline-flex" alignItems="center">
            <SFIcon name={FileTypeMapper[type]} />
            <Box display="inline" ml="x-small" />
            <FileName>{name}</FileName>
          </Box>
          <Button
            className="delete-button"
            onClick={() => onDeleteFile(index)}
            iconCategory="utility"
            iconName="delete"
            iconVariant="border-filled"
            variant="icon"
          />
        </FileWrapper>
      ))}
      {uploading && (
        <Box
          display="inline-flex"
          alignItems="center"
          mt="small"
          pl="small"
          pb="xx-small"
          pr="small"
          pt="xx-small"
        >
          <SpinnerWrapper>
            <Spinner size="small" variant="brand" id="file-upload-spinner" />
          </SpinnerWrapper>
          {t.whatsapp.botForm.upload.loadingDescription()}
        </Box>
      )}
    </Box>
  );
};
