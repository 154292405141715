import * as React from 'react';

import { Button } from '../Button';

export interface IUploadIconProps {
  onClick?: () => void;
  title: string;
  id: string;
  disabled?: boolean;
}

export const UploadButton = ({
  onClick,
  title,
  id,
  disabled,
}: IUploadIconProps) => {
  return (
    <Button
      assistiveText={{
        icon: title,
      }}
      iconCategory="utility"
      iconName="upload"
      iconPosition="left"
      onClick={onClick}
      id={id}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};
