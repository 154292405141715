import React from 'react';
import { Icon } from '@salesforce/design-system-react';
import styled from 'styled-components';

import { SFIconName, ISFIconProps, IIconProps, Type } from './types';

const Color: Record<Type, string> = {
  base: 'initial',
  warning: '#FFB75D',
};

const IconWrapper = styled(Icon)(({ type = 'base' }: IIconProps) => ({
  backgroundColor: Color[type],
}));

const ChannelIcon = (props: IIconProps) => {
  return <IconWrapper category="custom" name="custom110" {...props} />;
};

const ConversationIcon = (props: IIconProps) => {
  return (
    <IconWrapper category="standard" name="messaging_conversation" {...props} />
  );
};

const ImageIcon = (props: IIconProps) => {
  return <IconWrapper category="utility" name="image" {...props} />;
};

const VideoIcon = (props: IIconProps) => {
  return <IconWrapper category="utility" name="video" {...props} />;
};

const FileIcon = (props: IIconProps) => {
  return <IconWrapper category="utility" name="file" {...props} />;
};

const CloseIcon = (props: IIconProps) => {
  return <IconWrapper category="utility" name="close" {...props} />;
};

const VerificationIcon = (props: IIconProps) => {
  return <IconWrapper category="standard" name="approval" {...props} />;
};

const InfoIcon = (props: IIconProps) => {
  return <IconWrapper category="utility" name="info" size="small" {...props} />;
};

const ErrorIcon = (props: IIconProps) => {
  return (
    <IconWrapper category="utility" name="error" size="small" {...props} />
  );
};

const WarningIcon = (props: IIconProps) => {
  return (
    <IconWrapper
      category="utility"
      size="small"
      name="warning"
      style={{
        fill: '#000',
      }}
      {...props}
    />
  );
};

const SuccessIcon = (props: IIconProps) => {
  return (
    <IconWrapper category="utility" size="small" name="success" {...props} />
  );
};

const CheckIcon = (props: IIconProps) => {
  return (
    <IconWrapper
      category="utility"
      name="check"
      size="small"
      style={{
        fill: '#1589EE',
      }}
      {...props}
    />
  );
};

const PdfIcon = (props: IIconProps) => {
  return <IconWrapper category="doctype" size="small" name="pdf" {...props} />;
};

const WordIcon = (props: IIconProps) => {
  return <IconWrapper category="doctype" size="small" name="word" {...props} />;
};

const VideoFileIcon = (props: IIconProps) => {
  return (
    <IconWrapper category="doctype" size="small" name="video" {...props} />
  );
};

const icons: Record<
  SFIconName,
  (props: IIconProps) => React.FunctionComponentElement<ISFIconProps>
> = {
  channel: (props) => <ChannelIcon {...props} />,
  conversation: (props) => <ConversationIcon {...props} />,
  image: (props) => <ImageIcon {...props} />,
  video: (props) => <VideoIcon {...props} />,
  file: (props) => <FileIcon {...props} />,
  close: (props) => <CloseIcon {...props} />,
  verification: (props) => <VerificationIcon {...props} />,
  info: (props) => <InfoIcon {...props} />,
  error: (props) => <ErrorIcon {...props} />,
  warning: (props) => <WarningIcon {...props} />,
  success: (props) => <SuccessIcon {...props} />,
  check: (props) => <CheckIcon {...props} />,
  pdf: (props) => <PdfIcon {...props} />,
  word: (props) => <WordIcon {...props} />,
  videoFile: (props) => <VideoFileIcon {...props} />,
};

export const SFIcon = ({ name, ...props }: ISFIconProps) => {
  return icons[name](props);
};
