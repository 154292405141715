import { SFIconName } from '../components/SFIcon/types';

export const FileTypeMapper: Record<string, SFIconName> = {
  ['application/pdf']: 'pdf',
  ['application/msword']: 'word',
  ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']:
    'word',
  ['video/mp4']: 'videoFile',
  ['video/quicktime']: 'videoFile',
};
