import React, { ReactNode } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import { Box, IBoxProps } from '../Box';

import { GridItemCols } from './types';

interface IStickyGridItemProps extends IBoxProps {
  cols?: GridItemCols;
  children: ReactNode;
}

const Wrapper = styled(Box)({
  position: 'sticky',
  top: 0,
});

export const StickyGridItem = ({
  cols = 12,
  children,
  ...props
}: IStickyGridItemProps) => (
  <Wrapper
    className={classNames('slds-col', `slds-size_${cols}-of-12`)}
    {...props}
  >
    {children}
  </Wrapper>
);
