import React, { createContext } from 'react';

import { QueryHandler, IQueryHandlerResult } from '../QueryHandler';
import { USERINFO_QUERY, IUserInfoQueryResponse } from '../../graphql/queries';

import { IUserProviderProps } from './types';

// @ts-expect-error default value is not needed
export const UserContext = createContext<IUserContext>();

export const UserProvider = ({ children }: IUserProviderProps) => {
  return (
    <QueryHandler query={USERINFO_QUERY} levitate>
      {({
        data: { userInfo },
      }: IQueryHandlerResult<IUserInfoQueryResponse>) => {
        return (
          <UserContext.Provider value={{ user: userInfo }}>
            {children}
          </UserContext.Provider>
        );
      }}
    </QueryHandler>
  );
};
