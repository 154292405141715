import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';
import { useMutation } from '@apollo/client';
import { Crop } from 'react-image-crop';

import { t } from '@src/messages';
import {
  UploadFileMutationResponse,
  UPLOAD_FILE_MUTATION,
} from '@src/common/graphql';
import { IMutationUploadFileArgs } from '@shared/bff';

import { Box } from '../Box';
import { ImageResolver } from '../ImageResolver';
import { Label } from '../Label';
import { Text } from '../Text';
import { Grid, GridItem } from '../Grid';
import { MultiLineErrorMessage } from '../forms';
import { ImagePreview } from '../ImagePreview';
import { ToastContext } from '../ToastProvider';
import { LoadingIndicator } from '../LoadingIndicator';

interface IFileNameTextProps {
  isPlaceHolder: boolean;
}

const BoxWrapper = styled(Box)({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const FileNameText = styled(Text)(({ isPlaceHolder }: IFileNameTextProps) => ({
  color: isPlaceHolder ? '#706E6B' : undefined,
}));

interface IImageUploaderProps {
  label: string;
  fieldName: string;
  mimeFieldName?: string;
  tooltip: {
    label: string;
    id: string;
  };
  modalLabels: {
    title: string;
    description: string;
  };
  maxImageSize: number;
  cropOptions?: Partial<Crop>;
  hideFileName?: boolean;
}

export const ImageUploader = ({
  label,
  fieldName,
  mimeFieldName,
  tooltip,
  modalLabels,
  maxImageSize,
  cropOptions,
  hideFileName,
}: IImageUploaderProps) => {
  const { setFieldValue } = useFormikContext();
  const { toast } = useContext(ToastContext);
  const [field, meta] = useField(fieldName);
  const { error, touched } = meta;
  const { value } = field;

  const [uploading, setUploading] = useState<boolean>(false);
  const [filename, setFileName] = useState(value);

  const [uploadFileMutation] = useMutation<
    UploadFileMutationResponse,
    IMutationUploadFileArgs
  >(UPLOAD_FILE_MUTATION);

  const handleFileDropped = (droppedFilename: string) => {
    setFileName(droppedFilename);
  };

  const handleFileDialogCancel = () => {
    setFileName('');
  };

  const errorList = error ? (Array.isArray(error) ? error : [error]) : [];

  return (
    <>
      <Label id={tooltip.id} tooltip={tooltip.label} required>
        <Text color="weak">{label}</Text>
      </Label>
      <Box mt="small" />
      <Grid>
        <GridItem cols={10}>
          <BoxWrapper pl="x-small" display="flex" alignItems="center">
            <MultiLineErrorMessage
              errors={error}
              touched={touched}
              render={() => {
                return (
                  <ImagePreview
                    src={field.value}
                    width={`${(cropOptions?.aspect
                      ? 100 * cropOptions.aspect
                      : 100
                    )?.toString()}px`}
                    height="100px"
                    err={touched && errorList.length > 0}
                  />
                );
              }}
            />
            <Box display="inline" ml="medium" />
            <Box display="inline-block">
              {!hideFileName && (
                <>
                  <Text className="slds-form-element__label">
                    {t.whatsapp.fieldLabels.imageName()}
                  </Text>
                  <FileNameText isPlaceHolder={!filename}>
                    {filename || t.whatsapp.fieldLabels.noName()}
                  </FileNameText>
                  <Box mt="small" />
                </>
              )}
              <ImageResolver
                title={modalLabels.title}
                description={modalLabels.description}
                dragDropOptions={{
                  maxSize: maxImageSize,
                }}
                cropOptions={cropOptions}
                options={{
                  outputWidth: [192, 640],
                  outputHeight: [192, 640],
                }}
                onFileUpload={async ({ name, content, mimeType }) => {
                  setUploading(true);
                  try {
                    const { data } = await uploadFileMutation({
                      variables: {
                        file: { name, content, mimeType },
                      },
                    });

                    setFieldValue(fieldName, data?.uploadFile.fileUrl);
                    if (mimeFieldName) {
                      setFieldValue(mimeFieldName, mimeType);
                    }
                  } catch (err) {
                    toast('error', {
                      heading: t.common.toasts.somethingWentWrong(),
                    });
                  }
                  setUploading(false);
                }}
                onFileDropped={handleFileDropped}
                onFileDialogCancel={handleFileDialogCancel}
              />
            </Box>
          </BoxWrapper>
        </GridItem>
      </Grid>
      <LoadingIndicator
        open={uploading}
        title={t.whatsapp.botForm.upload.loadingDescription()}
      />
    </>
  );
};
