import React, { useMemo, useState, useContext } from 'react';

import { t } from '@src/messages';
import {
  AspClientState,
  IViberAspClient,
  IWhatsAppAspClient,
} from '@shared/bff';

import { Channel } from '../../types';
import {
  accountsDetailsPath,
  sortTableItems,
  formatDateTime,
} from '../../utils';
import { LabelTag, TagType } from '../LabelTag';
import {
  CellType,
  DataTable,
  IColumn,
  IDataTableSorting,
  SortDirection,
} from '../DataTable';
import { UserContext } from '../UserProvider';
import { EmptySection } from '../EmptySection';

const labelType: Record<AspClientState, TagType> = {
  REQUESTED: 'default',
  NOT_REGISTERED: 'default',
  ONBOARDED: 'success',
  REJECTED: 'error',
  UNSPECIFIED: 'default',
  PENDING_FB_VERIFICATION: 'default',
};

interface IOnboardedAspClientProps {
  channelType: Channel;
  items: IViberAspClient[] | IWhatsAppAspClient[];
  id: string;
}

const defaultSorting = {
  property: 'updated',
  sortDirection: SortDirection.DESCENDING,
};

export const OnboardedAccounts = ({
  channelType,
  items,
  id,
}: IOnboardedAspClientProps) => {
  const { user } = useContext(UserContext);
  const [sorting, setSorting] = useState<IDataTableSorting>(defaultSorting);

  const [sortedItems, setSortedItems] = useState(
    sortTableItems<IViberAspClient | IWhatsAppAspClient>(sorting, items)
  );

  const columns = useMemo<IColumn[]>(
    () => [
      {
        label: t.common.listings.columns.accountName(),
        property: 'name',
        width: '60%',
        truncate: true,
        type: CellType.Link,
        getPath: (item) => accountsDetailsPath(channelType, item.id),
        sortable: true,
        isSorted: sorting.property === 'name',
        sortDirection:
          sorting.property === 'name' ? sorting.sortDirection : undefined,
        columnId: `${id}-account-name`,
      },
      {
        label: t.common.listings.columns.status(),
        property: 'state',
        width: '20%',
        type: CellType.Formatted,
        formatTitle: (state: AspClientState) =>
          t.whatsapp.aspClients.status[state](),
        format: (state: AspClientState) => (
          <LabelTag
            type={labelType[state]}
            showRaw={[
              AspClientState.UNSPECIFIED,
              AspClientState.NOT_REGISTERED,
            ].includes(state)}
          >
            {t.whatsapp.aspClients.status[state]()}
          </LabelTag>
        ),
        columnId: `${id}-account-status`,
      },
      {
        label: t.common.listings.columns.updated(),
        property: 'updated',
        width: '20%',
        type: CellType.Formatted,
        format: (item) =>
          formatDateTime(item, user.zoneInfo, user.locale, 'LL'),
        sortable: true,
        isSorted: sorting.property === 'updated',
        sortDirection:
          sorting.property === 'updated' ? sorting.sortDirection : undefined,
      },
    ],
    [sorting]
  );

  return (
    <DataTable
      items={sortedItems}
      columns={columns}
      noDataComponent={
        <EmptySection
          type="no-data"
          title={t.common.noOnboardedAccounts.title()}
          subtitle={t.common.noOnboardedAccounts.description()}
        />
      }
      handleSort={(sort: IDataTableSorting) => {
        setSorting(sort);
        setSortedItems(
          sortTableItems<IViberAspClient | IWhatsAppAspClient>(
            sort,
            sortedItems
          )
        );
      }}
      id={id}
    />
  );
};
