import React, { ReactNode } from 'react';
import styled from 'styled-components';

const SectionHeader = styled.h3({
  fontWeight: 'bold',
});

const SectionContainer = styled.div({
  padding: '15px',
});

export interface ISectionPros {
  header?: string;
  children: ReactNode;
}
export const Section = ({ header, children }: ISectionPros) => {
  return (
    <SectionContainer>
      {header && <SectionHeader>{header}</SectionHeader>}
      {children}
    </SectionContainer>
  );
};
