import React from 'react';

import {
  IProgressIndicatorProps,
  ProgressIndicatorWrapper,
} from './ProgressIndicator';

export const EmptyProgressIndicator = ({
  color = 'darken',
  steps,
  ...props
}: IProgressIndicatorProps) => (
  <ProgressIndicatorWrapper
    steps={steps}
    selectedStep={undefined}
    completedSteps={[]}
    color={color}
    {...props}
  />
);
