import React from 'react';
import { ProfileSettingsWhatsApp, StandardPhoneFrame } from '@sinch/previewer';
import styled from 'styled-components';
import { WhatsAppPreviewerData } from '@sinch/previewer/dist/modules/apps/WhatsApp/ProfileSettings/components/types';

import { Box } from '../Box';

const PREVIEWER_WIDTH = '370px';

const PreviewerWrapper = styled(Box)({
  width: PREVIEWER_WIDTH,
  minWidth: PREVIEWER_WIDTH,
});

const CenterWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: PREVIEWER_WIDTH,
});

interface ProfileSettingsPreviewerProps {
  whatsappData: WhatsAppPreviewerData;
}

export const ProfileSettingsPreviewer = ({
  whatsappData,
}: ProfileSettingsPreviewerProps) => {
  return (
    <CenterWrapper>
      <PreviewerWrapper mt="x-large" ml="large" mr="large">
        <StandardPhoneFrame channelSwitcher={false}>
          <ProfileSettingsWhatsApp whatsappData={whatsappData} />
        </StandardPhoneFrame>
      </PreviewerWrapper>
    </CenterWrapper>
  );
};
