interface IImageForCropOptions {
  minWidth: number;
  minHeight: number;
}

interface IFillImage {
  minWidth: number;
  minHeight: number;
  img: HTMLImageElement;
}

const fillImage = ({ img, minWidth, minHeight }: IFillImage): string => {
  const { naturalWidth, naturalHeight } = img;
  const widthTooLow = naturalWidth < minWidth;
  const heightTooLow = naturalHeight < minHeight;
  const xPosition = widthTooLow ? (minWidth - naturalWidth) / 2 : 0;
  const yPosition = heightTooLow ? (minHeight - naturalHeight) / 2 : 0;
  const canvas = document.createElement('canvas');
  canvas.width = widthTooLow ? minWidth : naturalWidth;
  canvas.height = heightTooLow ? minHeight : naturalHeight;
  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, xPosition, yPosition, naturalWidth, naturalHeight);
  }
  return canvas.toDataURL('image/jpeg');
};

export const prepareImageForCrop = async (
  imageBase64: string,
  { minWidth, minHeight }: IImageForCropOptions
): Promise<string> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const imageTooSmall =
        img.naturalWidth < minWidth || img.naturalHeight < minHeight;
      const imageForCrop = imageTooSmall
        ? fillImage({ img, minWidth, minHeight })
        : imageBase64;
      resolve(imageForCrop);
    };
    img.src = imageBase64;
  });
