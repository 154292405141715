import React from 'react';
import {
  ProgressIndicator as SFProgressIndicator,
  IProgressIndicatorProps as SFIProgressIndicatorProps,
  IStep,
} from '@salesforce/design-system-react';
import styled from 'styled-components';

type ProgressIndicatorType = 'default' | 'error';
type ProgressIndicatorColor = 'light' | 'darken';

export interface IProgressIndicatorProps extends SFIProgressIndicatorProps {
  type?: ProgressIndicatorType;
  color?: ProgressIndicatorColor;
  errorSteps?: IStep[];
  steps: IStep[];
  currentStepId?: string | number;
}

export const ProgressIndicatorWrapper = styled(SFProgressIndicator)(
  ({ color = 'darken' }: Pick<IProgressIndicatorProps, 'color'>) => {
    if (color === 'darken') {
      return {
        maxWidth: '98%',
        '& .slds-progress__item .slds-progress__marker': {
          borderColor: '#F3F2F2',
        },
        '& .slds-is-active .slds-progress__marker': {
          boxShadow: '#F3F2F2 0 0 0 4px',
          borderColor: '#1589ee',
        },
      };
    }

    return {
      maxWidth: '98%',
    };
  }
);

const findSelectedStep = (
  steps: IStep[],
  stepId: IProgressIndicatorProps['currentStepId']
): IStep | undefined => {
  const [lastStep] = steps.slice(-1);
  return lastStep.id !== stepId
    ? steps.find((step) => step.id === stepId)
    : undefined;
};

export const ProgressIndicator = ({
  type = 'default',
  color = 'darken',
  steps,
  currentStepId,
  ...props
}: IProgressIndicatorProps) => {
  const selectedStep = findSelectedStep(steps, currentStepId);
  const completedSteps = selectedStep
    ? steps.slice(0, steps.indexOf(selectedStep))
    : steps;
  const disabledSteps = selectedStep
    ? steps.slice(steps.indexOf(selectedStep) + 1)
    : [];

  return (
    <ProgressIndicatorWrapper
      steps={steps}
      selectedStep={selectedStep}
      completedSteps={completedSteps}
      disabledSteps={disabledSteps}
      errorSteps={type === 'error' ? [selectedStep] : []}
      color={color}
      {...props}
    />
  );
};
