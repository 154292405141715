import React, { useMemo, useState, useContext } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import { t } from '@src/messages';
import { UserContext } from '@src/common/components/UserProvider';
import { formatDateTime } from '@src/common/utils';
import { NotificationTemplateType } from '@shared/bff';

import { Card } from '../Card';
import { Box } from '../Box';
import { Text } from '../Text';
import { TextareaField } from '../forms/TextareaField';
import { EmptySection } from '../EmptySection';
import { FormModal } from '../FormModal';
import { DataTable, IColumn, CellType } from '../DataTable';
import { Button } from '../Button';
import { RouteLeavingGuard } from '../RouteLeavingGuard';

type SectionSize = 'small' | 'normal';

enum ColumnSizes {
  'x-small' = '15%',
  'small' = '25%',
  'medium' = '35%',
  'large' = '40%',
  'x-large' = '50%',
}

interface IActivityHistorySectionProps {
  onSubmit: (values: IActivityComment) => void;
  data?: IActivityHistory[];
  size?: SectionSize;
  titlePrefix?: string;
}

export interface IActivityHistory {
  id: string;
  timestamp: string;
  event: string;
  comment: string;
}

export interface IActivityComment {
  comment: string;
}

const initialValues: IActivityComment = {
  comment: '',
};

const validationSchema = Yup.object({
  comment: Yup.string().required(),
});

const shouldBlockInternalNavigation = () => false;

export const ActivityHistorySection = ({
  onSubmit,
  data = [],
  size = 'normal',
  titlePrefix,
}: IActivityHistorySectionProps) => {
  const { user } = useContext(UserContext);
  const readOnlyAccess = user.permissions.view && !user.permissions.update;

  const columns = useMemo<IColumn[]>(
    () => [
      {
        label: t.common.listings.columns.timestamp(),
        property: 'timestamp',
        width: size === 'small' ? ColumnSizes['small'] : ColumnSizes['x-small'],
        type: CellType.Formatted,
        format: (item) => formatDateTime(item, user.zoneInfo, user.locale),
      },
      {
        label: t.common.listings.columns.event(),
        property: 'event',
        width: ColumnSizes['medium'],
        type: CellType.Formatted,
        format: (event: NotificationTemplateType) =>
          t.common.notificationTypeLabels[event](),
      },
      {
        label: t.common.listings.columns.comment(),
        property: 'comment',
        width: size === 'small' ? ColumnSizes['large'] : ColumnSizes['x-large'],
        type: CellType.Default,
      },
    ],
    []
  );
  const [showModal, setShowModal] = useState(false);

  const handleAddButtonClick = () => {
    setShowModal(true);
  };

  const handleSubmit = (
    values: IActivityComment,
    actions: FormikHelpers<IActivityComment>
  ) => {
    onSubmit(values);
    setShowModal(false);
    actions.resetForm();
  };

  const getHeading = (): string => {
    const header = t.common.listings.headers.activityHistory();

    if (titlePrefix) {
      return `${titlePrefix} ${header}`;
    }

    return header;
  };

  return (
    <>
      <Card
        id="activity-history"
        heading={getHeading()}
        headerActions={
          <Button
            onClick={handleAddButtonClick}
            id="add-comment-button"
            disabled={readOnlyAccess}
          >
            {t.whatsapp.fieldLabels.addCommentButton()}
          </Button>
        }
      >
        <Box mt="xx-small">
          <DataTable
            items={data}
            columns={columns}
            noDataComponent={
              <EmptySection
                type="no-data"
                title={t.common.noActivities.title()}
                subtitle={t.common.noActivities.description()}
              />
            }
          />
        </Box>
      </Card>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, dirty, resetForm }) => {
          // submit button will not trigger form.submit if it is in Modal, so we need to manually call submitForm
          const handleSubmitClick = () => {
            submitForm();
          };

          const handleCancel = () => {
            resetForm();
            setShowModal(false);
          };

          const shouldBlockExternalNavigation = () => dirty;
          return (
            <>
              <Form>
                <FormModal
                  heading={t.whatsapp.botForm.addComment.title()}
                  onCancel={handleCancel}
                  onSubmit={handleSubmitClick}
                  isOpen={showModal}
                  submitting={isSubmitting}
                  disableSubmit={!dirty || isSubmitting}
                >
                  <Box pl="small" pr="small" pt="small" pb="small">
                    <Text>{t.whatsapp.botForm.addComment.description()}</Text>
                    <Box mt="small" mb="small">
                      <TextareaField<IActivityComment>
                        id="comment-text-input"
                        name={'comment'}
                        label={t.whatsapp.fieldLabels.comment()}
                        disabled={isSubmitting}
                        required
                      />
                    </Box>
                  </Box>
                </FormModal>
              </Form>
              <RouteLeavingGuard
                shouldBlockInternalNavigation={shouldBlockInternalNavigation}
                shouldBlockExternalNavigation={shouldBlockExternalNavigation}
              />
            </>
          );
        }}
      </Formik>
    </>
  );
};
