import React, { createContext, useState, useEffect } from 'react';
import { IToastLabels, ToastVariant } from '@salesforce/design-system-react';

import { IToastProviderProps, IToastContext, Duration } from './types';
import { Toast } from './Toast';
import { getDuration } from './utils';

// @ts-expect-error default value is not needed
export const ToastContext = createContext<IToastContext>();

let timeoutId = 0;

export const ToastProvider = ({ children }: IToastProviderProps) => {
  const [showToast, setShowToast] = useState(false);
  const [variant, setVariant] = useState<ToastVariant>('info');
  const [labels, setLabels] = useState<IToastLabels>({
    heading: '',
  });
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const hideToast = () => {
    setShowToast(false);
  };

  const handleToast = (
    toastVariant: ToastVariant,
    toastLabels: IToastLabels,
    duration?: Duration
  ) => {
    duration = duration ?? 'medium';
    setVariant(toastVariant);
    setLabels(toastLabels);
    setShowToast(true);

    const timeoutDuration = getDuration(duration);
    if (timeoutDuration) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(hideToast, timeoutDuration);
    }
  };
  const handleRequestClose = () => {
    setShowToast(false);
    clearTimeout(timeoutId);
  };
  return (
    <ToastContext.Provider
      value={{
        toast: handleToast,
      }}
    >
      {showToast && (
        <Toast
          variant={variant}
          labels={labels}
          onRequestClose={handleRequestClose}
        />
      )}
      {children}
    </ToastContext.Provider>
  );
};
