import React, { useEffect, useState } from 'react';
import { Location as HistoryLocation } from 'history';
import { Prompt } from 'react-router-dom';

import { WarningDialog } from './WarningDialog';

interface Props {
  whenBlockInternalNavigation?: boolean;
  navigate?: (path: string) => void;
  shouldBlockInternalNavigation?: (location: HistoryLocation) => boolean;
  shouldBlockExternalNavigation?: (location: Location) => boolean;
}

export const RouteLeavingGuard = ({
  whenBlockInternalNavigation,
  navigate,
  shouldBlockInternalNavigation = () => true,
  shouldBlockExternalNavigation = () => true,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [lastPathName, setLastPathName] = useState<string | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };
  const handleBlockedNavigation = (nextLocation: HistoryLocation): boolean => {
    if (!confirmedNavigation && shouldBlockInternalNavigation(nextLocation)) {
      setShowModal(true);
      setLastPathName(nextLocation.pathname);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setShowModal(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (navigate && confirmedNavigation && lastPathName) {
      navigate(lastPathName);
    }
    return () => {
      window.onbeforeunload = null;
    };
  }, [confirmedNavigation, lastPathName]);

  window.onbeforeunload = () => {
    return shouldBlockExternalNavigation(window.location)
      ? 'unsaved changes'
      : null;
  };
  return (
    <>
      <Prompt
        when={whenBlockInternalNavigation}
        message={handleBlockedNavigation}
      />
      <WarningDialog
        open={showModal}
        onCancel={closeModal}
        onConfirm={handleConfirmNavigationClick}
      />
    </>
  );
};
