import React from 'react';
import styled from 'styled-components';

import { Image, IImageProps } from '../Image';

interface IImagePreviewprops extends IImageProps {
  err?: boolean;
}

const ImageWrapper = styled(Image)(
  ({ err }: IImagePreviewprops) =>
    err && {
      border: '2px solid #C23934',
    }
);

export const ImagePreview = (props: IImagePreviewprops) => (
  <ImageWrapper {...props} />
);
