import React from 'react';
import {
  IComboboxProps,
  IComboboxOption,
} from '@salesforce/design-system-react';
import { t } from 'messages';

import { SingleSelectField } from '../forms/SingleSelectField';

type AspClientSelectType = 'channels' | 'templates';

interface IAspClientSelectProps extends IComboboxProps {
  type: AspClientSelectType;
  options: IComboboxOption[];
}

export const AspClientSelect = ({
  type,
  name,
  required = true,
  ...props
}: IAspClientSelectProps) => {
  return (
    <SingleSelectField
      name="aspClientId"
      labels={{
        label: t.common.components.accountSelect.label(),
        placeholderReadOnly: t.common.components.accountSelect.placeholder(),
      }}
      tooltip={t.common.components.accountSelect.tooltips[type]()}
      required={required}
      variant="readonly"
      id="account-input"
      {...props}
    />
  );
};
