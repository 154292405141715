import { gql } from '@apollo/client';

import { IUserInfo } from '@shared/bff';

export type IUserInfoFragment = Pick<
  IUserInfo,
  | 'enterpriseId'
  | 'enterpriseName'
  | 'memberId'
  | 'locale'
  | 'userId'
  | 'userName'
  | 'userEmail'
  | 'zoneInfo'
  | 'stack'
  | 'permissions'
>;

export const USERINFO_FRAGMENT = gql`
  fragment UserInfoFragment on UserInfo {
    enterpriseId
    enterpriseName
    memberId
    locale
    userId
    userName
    userEmail
    zoneInfo
    stack
    permissions {
      view
      update
    }
  }
`;
