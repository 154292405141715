import React, { useMemo, useState, useContext } from 'react';

import { t } from '@src/messages';
import {
  BotState,
  CrState,
  IViberCr,
  IWhatsAppCr,
  ViberSenderState,
  WhatsAppDailyLimit,
  WhatsAppQuality,
} from '@shared/bff';
import { useFeatureFlags } from '@src/common/utils/hooks';

import { Channel, IListItemFragment } from '../../types';
import {
  channelBotDetailsPath,
  sortTableItems,
  formatDateTime,
  mapCrStateToType,
  mapBotStateToType,
  mapSenderStateToType,
  mapWhatsAppQualityToType,
} from '../../utils';
import { UserContext } from '../UserProvider';
import {
  CellType,
  DataTable,
  IColumn,
  IDataTableSorting,
  SortDirection,
} from '../DataTable';
import { LabelTag, TagType } from '../LabelTag';
import { EmptySection } from '../EmptySection';

interface IOnboardedChannelsProps {
  channelType: Channel;
  onboardedChannels: IListItemFragment[];
  id: string;
}

type BotCr = IWhatsAppCr | IViberCr;

const defaultSorting = {
  property: 'updated',
  sortDirection: SortDirection.DESCENDING,
};

const getCrProperty = (channelType: Channel): string => {
  switch (channelType) {
    case Channel.Whatsapp:
      return 'botCr';
    case Channel.ViberBusiness:
      return 'cr';
    default:
      return '';
  }
};

const getLabelTagType = (
  channelType: Channel,
  state: ViberSenderState | BotState
): TagType => {
  return channelType === Channel.ViberBusiness
    ? mapSenderStateToType(state as ViberSenderState)
    : mapBotStateToType(state as BotState);
};

const getChannelStatusTitle = (
  channelType: Channel,
  state: ViberSenderState | BotState
): string => {
  return channelType === Channel.ViberBusiness
    ? t.viberBusiness.channels.status[state as ViberSenderState]()
    : t.whatsapp.stateLabels[state as BotState]();
};

const getCrTitle = (botCr: BotCr) =>
  botCr ? t.common.changeRequestStateLabels[botCr.state as CrState]() : '';

const formatCrStatus = (botCr: BotCr) =>
  botCr ? (
    <LabelTag type={mapCrStateToType(botCr.state)}>
      {t.common.changeRequestStateLabels[botCr.state as CrState]()}
    </LabelTag>
  ) : null;

export const OnboardedChannels = ({
  channelType,
  onboardedChannels,
  id,
}: IOnboardedChannelsProps) => {
  const { user } = useContext(UserContext);
  const [sorting, setSorting] = useState<IDataTableSorting>(defaultSorting);
  const [sortedItems, setSortedItems] = useState<IListItemFragment[]>(
    sortTableItems<IListItemFragment>(sorting, onboardedChannels)
  );
  const { whatsAppQualityInformation } = useFeatureFlags();

  const columns = useMemo<IColumn[]>(
    () =>
      [
        {
          label: t.common.listings.columns.channelName(),
          property: 'name',
          width: '50%',
          type: CellType.Link,
          getPath: (item: IListItemFragment) =>
            channelBotDetailsPath(channelType, item.id),
          sortable: true,
          isSorted: sorting.property === 'name',
          sortDirection:
            sorting.property === 'name' ? sorting.sortDirection : undefined,
          columnId: `${id}-channel-name`,
        },
        whatsAppQualityInformation && channelType === Channel.Whatsapp
          ? {
              label: t.common.listings.columns.quality(),
              property: 'quality',
              width: '10%',
              type: CellType.Formatted,
              columnId: `${id}-channel-quality`,
              format: (quality: WhatsAppQuality) =>
                quality !== WhatsAppQuality.UNKNOWN ? (
                  <LabelTag type={mapWhatsAppQualityToType(quality)}>
                    {t.whatsapp.qualityLabels[quality]()}
                  </LabelTag>
                ) : (
                  t.whatsapp.qualityLabels[quality]()
                ),
            }
          : null,
        whatsAppQualityInformation && channelType === Channel.Whatsapp
          ? {
              label: t.common.listings.columns.dailyLimit(),
              property: 'dailyLimit',
              width: '10%',
              type: CellType.Formatted,
              columnId: `${id}-channel-daily-limit`,
              format: (tier: WhatsAppDailyLimit) => (
                <>{t.whatsapp.dailyLimitLabels[tier]()}</>
              ),
            }
          : null,
        {
          label: t.common.listings.columns.status(),
          property: 'state',
          width: '10%',
          type: CellType.Formatted,
          columnId: `${id}-channel-status`,
          formatTitle: (state: ViberSenderState | BotState) =>
            getChannelStatusTitle(channelType, state),
          format: (state: ViberSenderState | BotState) => (
            <LabelTag type={getLabelTagType(channelType, state)}>
              {getChannelStatusTitle(channelType, state)}
            </LabelTag>
          ),
        },
        {
          label: t.common.listings.columns.changeRequestStatus(),
          property: getCrProperty(channelType),
          width: '20%',
          type: CellType.Formatted,
          columnId: `${id}-channel-status`,
          formatTitle: getCrTitle,
          format: formatCrStatus,
        },
        {
          label: t.common.listings.columns.updated(),
          property: 'updated',
          width: '20%',
          type: CellType.Formatted,
          format: (item: string) =>
            formatDateTime(item, user.zoneInfo, user.locale, 'LL'),
          sortable: true,
          isSorted: sorting.property === 'updated',
          sortDirection:
            sorting.property === 'updated' ? sorting.sortDirection : undefined,
        },
      ].filter((entry) => entry) as IColumn[],
    [sorting]
  );

  return (
    <DataTable
      items={sortedItems}
      columns={columns}
      noDataComponent={
        <EmptySection
          type="no-data"
          title={t.common.noVerifiedChannels.title()}
          subtitle={t.common.noVerifiedChannels.description()}
        />
      }
      handleSort={(sort: IDataTableSorting) => {
        setSorting(sort);
        setSortedItems(sortTableItems<IListItemFragment>(sort, sortedItems));
      }}
      id={id}
    />
  );
};
