import React from 'react';
import { Datasets } from '@sinch/utils';
import {
  IComboboxOption,
  IComboboxProps,
} from '@salesforce/design-system-react';

import { uuid } from '@src/common/utils';

import { SingleSelectField } from './SingleSelectField';

interface ICountryCodeSelectorProps<TObject> extends IComboboxProps {
  name: keyof TObject & string;
  tooltip?: string;
  disabledCountryCodes?: string | false;
}

const getCountries = (disabledCountryCodes: string | false) => {
  let countries = Object.entries(Datasets.DIAL_CODES);
  if (disabledCountryCodes) {
    const countryCodes = disabledCountryCodes.split(',');
    countries = countries.filter(
      ([countryCode]) => !countryCodes.includes(countryCode.toUpperCase())
    );
  }
  return countries.map(
    ([countryCode, code]): IComboboxOption => ({
      id: uuid(),
      label: `${countryCode.toUpperCase()} +${code.dialCode} ${code.name}`,
      value: code.dialCode,
      selectedLabel: `+${code.dialCode}`,
    })
  );
};
// any is required for component to work properly in cases
// where no generic parameter is provided
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CountryCodeSelector<TObject = any>({
  disabledCountryCodes = false,
  ...props
}: ICountryCodeSelectorProps<TObject>) {
  return (
    <SingleSelectField
      options={getCountries(disabledCountryCodes)}
      variant="inline-listbox"
      {...props}
    />
  );
}
