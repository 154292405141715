import React from 'react';
import styled from 'styled-components';
import { Modal, Spinner } from '@salesforce/design-system-react';

import { Section } from '../Section';

export interface ILoadingIndicatorProps {
  open: boolean;
  title: string;
}

const SpinnerWrapper = styled.div({
  position: 'relative',
  height: '5rem',
});

export const LoadingIndicator = ({ open, title }: ILoadingIndicatorProps) => {
  return (
    <Modal heading={title} disableClose isOpen={open}>
      <Section header={''}>
        <SpinnerWrapper>
          <Spinner size={'large'} assistiveText={{ label: title }} />
        </SpinnerWrapper>
      </Section>
    </Modal>
  );
};
