import { gql } from '@apollo/client';

import { IChannelLimitsResponse as GqlChannelLimitsResponse } from '@shared/bff';

import { ChannelLimitsFragment } from '../../fragments';

export interface IChannelLimitsResponse {
  channelLimits: GqlChannelLimitsResponse;
}

export const CHANNEL_LIMITS_QUERY = gql`
  query ChannelLimits {
    channelLimits {
      viberChannels {
        ...ChannelLimitsFragment
      }
      whatsAppChannels {
        ...ChannelLimitsFragment
      }
    }
  }
  ${ChannelLimitsFragment}
`;
