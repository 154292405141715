import React from 'react';
import { Modal, Button } from '@salesforce/design-system-react';
import { t } from 'messages';

import { Box } from '../Box';

interface IWarningDialogProps {
  open?: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const WarningDialog = ({
  open = false,
  onCancel,
  onConfirm,
}: IWarningDialogProps) => {
  return (
    <Modal
      heading={t.common.navigationOutConfirmDialog.title()}
      footer={[
        <Button onClick={onCancel} key="cancel" id="dialog-cancel-button">
          {t.common.actions.cancel()}
        </Button>,
        <Button
          variant="brand"
          onClick={onConfirm}
          key="confirm"
          id="dialog-confirm-button"
        >
          {t.common.navigationOutConfirmDialog.leave()}
        </Button>,
      ]}
      isOpen={open}
      onRequestClose={onCancel}
    >
      <Box pl="medium" pr="medium" pt="medium" pb="x-large">
        {t.common.navigationOutConfirmDialog.description()}
      </Box>
    </Modal>
  );
};
