import { BotState, CrState, ViberSenderState } from '@shared/bff';

import { TagType } from '../components/LabelTag';

const botStateMapping: Record<BotState, TagType> = {
  [BotState.ACTIVE]: 'success',
  [BotState.INACTIVE]: 'error',
  [BotState.TEST]: 'default',
  [BotState.NEW]: 'default',
  [BotState.DELETED]: 'error',
  [BotState.SUSPENDED]: 'error',
  [BotState.PROVISIONING]: 'default',
  [BotState.PROVISIONING_FAILED]: 'error',
};

export const mapBotStateToType = (status: BotState): TagType => {
  return botStateMapping[status];
};

const senderStateMapping: Record<ViberSenderState, TagType> = {
  [ViberSenderState.UNSPECIFIED]: 'default',
  [ViberSenderState.ACTIVE]: 'success',
  [ViberSenderState.INACTIVE]: 'error',
};

export const mapSenderStateToType = (status: ViberSenderState): TagType => {
  return senderStateMapping[status];
};

const crStateMapping: Record<CrState, TagType> = {
  [CrState.DRAFT]: 'emphasize',
  [CrState.BOT_CR_UNSPECIFIED_STATE]: 'default',
  [CrState.REVIEW_QUEUE]: 'default',
  [CrState.REVIEW]: 'default',
  [CrState.APPROVED]: 'default',
  [CrState.IN_PROGRESS]: 'default',
  [CrState.DONE]: 'default',
  [CrState.ROLLBACK]: 'default',
  [CrState.REJECTED]: 'error',
  [CrState.SIGNATURE_PENDING]: 'default',
};

export const mapCrStateToType = (status: CrState): TagType => {
  return crStateMapping[status];
};
