import { Channel } from '@src/common/types';

const MapFromChannelTypeToUrl: Record<Channel, string> = {
  RCS: 'rcs',
  Whatsapp: 'whatsApp',
  ViberBusiness: 'viber',
  KakaoTalk: 'kakaoTalk',
};

export const homePath = '/home';
export const accountsSubpath = '/accounts';
export const channelSubpath = '/channels';
export const templateSubpath = '/templates';

export const accountsPath = (type: Channel) =>
  `/${MapFromChannelTypeToUrl[type]}${accountsSubpath}`;

export const accountRegisterPath = (type: Channel) =>
  `/${MapFromChannelTypeToUrl[type]}${accountsSubpath}/register`;

export const accountsDetailsPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${accountsSubpath}/account/${id}`;

export const accountsEditPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${accountsSubpath}/account/${id}/edit`;

export const channelPath = (type: Channel) =>
  `/${MapFromChannelTypeToUrl[type]}`;

export const channelListPath = (type: Channel) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}`;

export const channelBotDetailsPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/bot/${id}`;

export const channelBotEditPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/bot/${id}/edit`;

export const channelBotCompareCrBotPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/changeRequest/bot/${id}`;

export const channelCrBotCompareBotPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/changeRequest/crbot/${id}`;

export const channelRequestPath = (type: Channel) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/request`;

export const channelEditDraftPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/draft/edit/${id}`;

export const channelChangeRequestDetailsPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/changeRequest/crbot/${id}`;

export const channelChangeRequestBotDetailsPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/changeRequest/bot/${id}`;

export const channelChangeRequestEditDraftPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/changeRequest/${id}/edit-draft`;

export const channelChangeRequestEditRejectedPath = (
  type: Channel,
  id: string
) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/changeRequest/${id}/edit-rejected`;

export const channelBotEditDetailsPath = (type: Channel, id: string) =>
  `/${MapFromChannelTypeToUrl[type]}${channelSubpath}/bot/${id}/edit-bot`;

export const templateListPath = (type: Channel) =>
  `/${MapFromChannelTypeToUrl[type]}${templateSubpath}`;

export const templateRegisterPath = (type: Channel) =>
  `/${MapFromChannelTypeToUrl[type]}${templateSubpath}/register`;

export const templateDetailsPath = (
  type: Channel,
  aspClientId: string,
  name: string
) =>
  `/${MapFromChannelTypeToUrl[type]}${templateSubpath}/template/${aspClientId}/${name}`;

export const templateAddLanguagesPath = (
  type: Channel,
  aspClientId: string,
  name: string
) => `${templateDetailsPath(type, aspClientId, name)}/add-language`;

export const templateEditPath = (
  type: Channel,
  aspClientId: string,
  name: string
) => `${templateDetailsPath(type, aspClientId, name)}/edit`;
