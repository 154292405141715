import React from 'react';
import {
  AppFrameWhatsApp,
  PreviewerWhatsAppBusinessAPI,
  WhatsAppBusinessAPITemplate,
  StandardPhoneFrame,
} from '@sinch/previewer';
import styled from 'styled-components';

import { languageDirection } from '@src/common/utils';

import { Box } from '../Box';

const PREVIEWER_WIDTH = '370px';

const PreviewerWrapper = styled(Box)({
  width: PREVIEWER_WIDTH,
  minWidth: PREVIEWER_WIDTH,
});

const CenterWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: PREVIEWER_WIDTH,
});

interface ITemplatePreviewerProps {
  template: WhatsAppBusinessAPITemplate;
}

export const TemplatePreviewer = ({ template }: ITemplatePreviewerProps) => {
  return (
    <CenterWrapper>
      <PreviewerWrapper mt="x-large" ml="large" mr="large">
        <StandardPhoneFrame channelSwitcher={false}>
          <AppFrameWhatsApp extendedFrame={false}>
            <PreviewerWhatsAppBusinessAPI
              messages={[template]}
              RTLLayout={languageDirection(template.language) === 'rtl'}
            />
          </AppFrameWhatsApp>
        </StandardPhoneFrame>
      </PreviewerWrapper>
    </CenterWrapper>
  );
};
