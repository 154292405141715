import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { IComboboxOption } from '@salesforce/design-system-react';

import {
  CheckboxField,
  MultiSelectField,
  InputField,
  TextareaField,
  SingleSelectField,
  Card,
  Section,
} from '@src/common/components';

const comboOptions: IComboboxOption[] = [
  {
    value: '#1',
    label: 'Number one',
    subTitle: 'I am below number one text',
  },
  {
    value: '#2',
    label: 'Number two',
    subTitle: 'I am below number two text',
  },
  {
    value: '#3',
    label: 'Number three',
    subTitle: 'I am below number two text',
  },
  {
    value: '#4',
    label: 'Number four',
    subTitle: 'I am below number two text',
  },
  {
    value: '#5',
    label: 'Number five',
    subTitle: 'I am below number two text',
  },
  {
    value: '#6',
    label: 'Number six',
    subTitle: 'I am below number two text',
  },
];

const formSchema = yup.object({
  check: yup.boolean().oneOf([true]).required(),
  cmb: yup.string().oneOf(['#1']).required(),
  multiCmb: yup
    .array()
    .of(yup.string().oneOf(['#1', '#2', '#3']))
    .required(),
  inputTxt: yup.string().required().min(2).max(10),
  inputNumber: yup.number().min(10).max(12).required(),
  area: yup.string().min(20).max(100).required(),
});

export const TestFormik = () => {
  const initialValues = {
    check: false,
    cmb: '#2',
    multiCmb: ['#1'],
    inputTxt: 'txt',
    inputNumber: '5',
    area: '',
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={formSchema}
        onSubmit={(values, actions) => {
          console.log('submitting', values);
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 2000);
        }}
      >
        {() => {
          return (
            <Form>
              <Card hasNoHeader fullHeight>
                <Section>
                  <CheckboxField
                    name="check"
                    labels={{ label: 'Check me!', heading: 'Check header' }}
                    required
                  />
                  <SingleSelectField
                    name="cmb"
                    labels={{ label: 'Combo time!' }}
                    options={comboOptions}
                    variant="readonly"
                  />
                  <MultiSelectField
                    name="multiCmb"
                    labels={{
                      label: 'MultiCombo time!',
                    }}
                    options={comboOptions}
                    multiple
                    variant="readonly"
                  />
                  <InputField
                    name="inputTxt"
                    maxLength="10"
                    label="String input"
                  />
                  <InputField
                    name="inputNumber"
                    minValue={10}
                    maxValue={12}
                    step={1}
                    type="number"
                    label="Number input"
                  />
                  <TextareaField
                    name="area"
                    label="Header"
                    placeholder="Add a bunch of text"
                    maxLength="100"
                  />
                  <input type="submit" />
                </Section>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
