import React, { ReactNode } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

type Weight = 'regular' | 'bold';

type Spacing =
  | 'xxx-small'
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large';

interface ITextProps {
  mt?: Spacing;
  ml?: Spacing;
  mr?: Spacing;
  mb?: Spacing;
  children: ReactNode;
  color?:
    | 'default'
    | 'succcess'
    | 'weak'
    | 'error'
    | 'destructive'
    | 'inverse'
    | 'inverse-weak';
  variant?:
    | 'body'
    | 'body_small'
    | 'body_regular'
    | 'heading_small'
    | 'heading_medium'
    | 'heading_large'
    | 'title'
    | 'title_caps'
    | 'longform';
  align?: 'left' | 'right' | 'center';
  weight?: Weight;
  className?: string;
  id?: string;
}

const weightMatcher: Record<Weight, number> = {
  regular: 400,
  bold: 700,
};

// @ts-expect-error no description provided before new lint rules
const Wrapper = styled.div(({ weight = 'regular' }: ITextProps) => ({
  fontWeight: weightMatcher[weight],
  whiteSpace: 'initial',
}));

export const Text = ({
  mt,
  ml,
  mr,
  mb,
  children,
  color = 'default',
  variant = 'body',
  align = 'left',
  ...props
}: ITextProps) => {
  return (
    <Wrapper
      className={classNames(
        mt && `slds-m-top_${mt}`,
        ml && `slds-m-left_${ml}`,
        mr && `slds-m-right_${mr}`,
        mb && `slds-m-bottom_${mb}`,
        `slds-text-${variant}`,
        `slds-text-color_${color}`,
        `slds-text-align_${align}`
      )}
      {...props}
    >
      {children}
    </Wrapper>
  );
};
