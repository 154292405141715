import React, { createContext, useEffect, useState } from 'react';

import { WindowSizeContextData, WindowSizeProviderProps } from './types';

// @ts-expect-error default value is not needed
export const WindowSizeContext = createContext<WindowSizeContextData>();

export const WindowSizeProvider = ({ children }: WindowSizeProviderProps) => {
  const [width, setWindowWidth] = useState(0);
  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);
  const updateDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  return (
    <WindowSizeContext.Provider value={{ isLargeWindow: width > 1400 }}>
      {children}
    </WindowSizeContext.Provider>
  );
};
