// tslint:disable
// graphql typescript definitions
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export enum BotState {
  NEW = 'NEW',
  INACTIVE = 'INACTIVE',
  TEST = 'TEST',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
  PROVISIONING = 'PROVISIONING',
  PROVISIONING_FAILED = 'PROVISIONING_FAILED',
}

export enum FilterByBotState {
  NEW = 'NEW',
  INACTIVE = 'INACTIVE',
  TEST = 'TEST',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
}

export type IBotFilter = {
  botIds?: Maybe<Array<Scalars['String']>>;
  state: FilterByBotState;
};

export type IBotSort = {
  sortField?: Maybe<BotSortField>;
  sortOrder?: Maybe<SortOrder>;
};

export enum CrState {
  BOT_CR_UNSPECIFIED_STATE = 'BOT_CR_UNSPECIFIED_STATE',
  DRAFT = 'DRAFT',
  REVIEW_QUEUE = 'REVIEW_QUEUE',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ROLLBACK = 'ROLLBACK',
  REJECTED = 'REJECTED',
  SIGNATURE_PENDING = 'SIGNATURE_PENDING',
}

export type IBotCrFilter = {
  states?: Maybe<Array<CrState>>;
  onlyNewBots?: Maybe<Scalars['Boolean']>;
};

export type IBotCrSort = {
  sortField?: Maybe<BotCrSortField>;
  sortOrder?: Maybe<SortOrder>;
};

export enum BotCrSortField {
  BY_CREATE_DATE = 'BY_CREATE_DATE',
  BY_MODIFIED_DATE = 'BY_MODIFIED_DATE',
  BY_NAME = 'BY_NAME',
}

export enum SortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}

export enum BotSortField {
  BY_CREATE_DATE = 'BY_CREATE_DATE',
  BY_MODIFIED_DATE = 'BY_MODIFIED_DATE',
  BY_NAME = 'BY_NAME',
}

export type IQuery = {
  __typename?: 'Query';
  activities: Array<IActivity>;
  userInfo?: Maybe<IUserInfo>;
  channelLimits?: Maybe<IChannelLimitsResponse>;
  emailNotificationsSettings?: Maybe<IEmailNotificationsSettingsResponse>;
  kakaoTalkSenderData: IKakaoTalkSenderData;
  viberAspClients: IViberAspClientsResponse;
  viberAspClient: IViberAspClient;
  viberAspClientsList: Array<IViberAspClient>;
  viberCr?: Maybe<IViberCr>;
  viberCrs: Array<IViberCr>;
  viberHome: IViberHomeResponse;
  viberSender?: Maybe<IViberSender>;
  viberSenders: Array<IViberSender>;
  whatsAppAspClients: IWhatsAppAspClientsResponse;
  whatsAppAspClient: IWhatsAppAspClient;
  whatsAppAspClientsList: Array<IWhatsAppAspClient>;
  whatsAppBot?: Maybe<IWhatsAppBot>;
  whatsAppBots: Array<IWhatsAppBot>;
  whatsAppCr?: Maybe<IWhatsAppCr>;
  whatsAppCrs: Array<IWhatsAppCr>;
  whatsAppHome: IWhatsAppHomeResponse;
  whatsAppMessageTemplates: IWhatsAppMtQueryResponse;
  whatsAppMessageTemplate: IWhatsAppMtOrMtCr;
};

export type IQueryActivitiesArgs = {
  targetType: ActivityTargetType;
  targetId: Scalars['String'];
};

export type IQueryKakaoTalkSenderDataArgs = {
  profileId: Scalars['String'];
};

export type IQueryViberAspClientArgs = {
  aspClientId: Scalars['String'];
};

export type IQueryViberAspClientsListArgs = {
  state?: Maybe<Array<ViberAspClientState>>;
};

export type IQueryViberCrArgs = {
  senderCrId: Scalars['String'];
};

export type IQueryViberCrsArgs = {
  filter?: Maybe<IBotCrFilter>;
  sort?: Maybe<IBotCrSort>;
};

export type IQueryViberSenderArgs = {
  senderId: Scalars['String'];
};

export type IQueryViberSendersArgs = {
  filter?: Maybe<IViberSenderFilter>;
  sort?: Maybe<IBotSort>;
  search?: Maybe<Scalars['String']>;
};

export type IQueryWhatsAppAspClientArgs = {
  aspClientId: Scalars['String'];
};

export type IQueryWhatsAppAspClientsListArgs = {
  withActiveChannels?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Array<AspClientState>>;
};

export type IQueryWhatsAppBotArgs = {
  botId: Scalars['String'];
};

export type IQueryWhatsAppBotsArgs = {
  filter?: Maybe<IBotFilter>;
  sort?: Maybe<IBotSort>;
  search?: Maybe<Scalars['String']>;
};

export type IQueryWhatsAppCrArgs = {
  botCrId: Scalars['String'];
};

export type IQueryWhatsAppCrsArgs = {
  filter?: Maybe<IBotCrFilter>;
  sort?: Maybe<IBotCrSort>;
};

export type IQueryWhatsAppMessageTemplateArgs = {
  name: Scalars['String'];
  aspClientId: Scalars['String'];
};

export type IMutation = {
  __typename?: 'Mutation';
  createComment: IActivity;
  optIn: IEmailNotificationsSettingsUpdateResponse;
  optOut: IEmailNotificationsSettingsUpdateResponse;
  uploadCroppedImages: IUploadCroppedImagesResponse;
  uploadFile: IUploadFileResponse;
  viberRegisterAspClient?: Maybe<IViberAspClient>;
  viberUpdateAspClient?: Maybe<IViberAspClient>;
  viberCreateCr: IViberCr;
  viberUpdateCr: IViberCr;
  viberTransitionSenderCrFromDraftToReview: IViberCr;
  viberTransitionSenderCrFromRejectedToDraft: IViberCr;
  viberSenderAction: Scalars['String'];
  whatsAppRegisterAspClient?: Maybe<IWhatsAppAspClient>;
  whatsAppUpdateAspClient?: Maybe<IWhatsAppAspClient>;
  whatsAppBotAction: IWhatsAppBotResponse;
  whatsAppRegisterBot?: Maybe<IWhatsAppBotResponse>;
  whatsAppVerifyBot?: Maybe<IWhatsAppBotResponse>;
  whatsAppCreateCr: IWhatsAppCr;
  whatsAppUpdateCr: IWhatsAppCr;
  whatsAppTransitionBotCrFromDraftToReview: IWhatsAppCr;
  whatsAppTransitionBotCrFromRejectedToDraft: IWhatsAppCr;
  whatsAppCreateMessageTemplateCr: IWhatsAppMtOrMtCr;
  whatsAppDeleteMessageTemplate: IWhatsAppMtDeleteResponse;
  whatsAppTransitionMessageTemplateCrFromRejectedToDraft: IWhatsAppMtOrMtCr;
  whatsAppTransitionMessageTemplateCrFromDraftToReview: IWhatsAppMtOrMtCr;
  whatsAppUpdateMessageTemplateDraftCr: IWhatsAppMtOrMtCr;
};

export type IMutationCreateCommentArgs = {
  comment: ICreateCommentInput;
};

export type IMutationOptInArgs = {
  email: Scalars['String'];
};

export type IMutationUploadCroppedImagesArgs = {
  file: IFileInput;
  sizes: Array<ISizeInput>;
};

export type IMutationUploadFileArgs = {
  file: IFileInput;
};

export type IMutationViberRegisterAspClientArgs = {
  aspClient: IViberAspClientInput;
};

export type IMutationViberUpdateAspClientArgs = {
  aspClient: IUpdateViberAspClientInput;
};

export type IMutationViberCreateCrArgs = {
  viberCr: IViberCrInput;
};

export type IMutationViberUpdateCrArgs = {
  viberCr: IViberCrUpdateInput;
};

export type IMutationViberTransitionSenderCrFromDraftToReviewArgs = {
  crId: Scalars['String'];
};

export type IMutationViberTransitionSenderCrFromRejectedToDraftArgs = {
  crId: Scalars['String'];
};

export type IMutationViberSenderActionArgs = {
  senderId: Scalars['String'];
  action: ViberSenderAction;
};

export type IMutationWhatsAppRegisterAspClientArgs = {
  aspClient: IWhatsAppAspClientInput;
};

export type IMutationWhatsAppUpdateAspClientArgs = {
  aspClient: IUpdateWhatsAppAspClientInput;
};

export type IMutationWhatsAppBotActionArgs = {
  action: WhatsAppBotAction;
  botId: Scalars['String'];
};

export type IMutationWhatsAppRegisterBotArgs = {
  botId: Scalars['String'];
  registrationMethod: WhatsAppRegistrationMethod;
  twoFactorCode?: Maybe<Scalars['String']>;
};

export type IMutationWhatsAppVerifyBotArgs = {
  botId: Scalars['String'];
  code: Scalars['String'];
};

export type IMutationWhatsAppCreateCrArgs = {
  whatsAppCr: IWhatsAppCrInput;
};

export type IMutationWhatsAppUpdateCrArgs = {
  whatsAppCr: IWhatsAppCrUpdateInput;
};

export type IMutationWhatsAppTransitionBotCrFromDraftToReviewArgs = {
  crId: Scalars['String'];
};

export type IMutationWhatsAppTransitionBotCrFromRejectedToDraftArgs = {
  crId: Scalars['String'];
};

export type IMutationWhatsAppCreateMessageTemplateCrArgs = {
  crInput: IWhatsAppMtCrInput;
};

export type IMutationWhatsAppDeleteMessageTemplateArgs = {
  name: Scalars['String'];
  aspClientId: Scalars['String'];
};

export type IMutationWhatsAppTransitionMessageTemplateCrFromRejectedToDraftArgs =
  {
    transitionInput: ITransitionCrInput;
  };

export type IMutationWhatsAppTransitionMessageTemplateCrFromDraftToReviewArgs =
  {
    transitionInput: ITransitionCrInput;
  };

export type IMutationWhatsAppUpdateMessageTemplateDraftCrArgs = {
  crInput: IWhatsAppMtCrInput;
};

export type ICreateCommentInput = {
  targetId: Scalars['String'];
  targetType: ActivityTargetType;
  comment: Scalars['String'];
};

export type IActivity = {
  __typename?: 'Activity';
  activityId: Scalars['String'];
  targetId: Scalars['String'];
  targetType: ActivityTargetType;
  notificationTemplate: NotificationTemplateType;
  comment: Scalars['String'];
  created: Scalars['String'];
  updated: Scalars['String'];
};

export enum ActivityTargetType {
  UNKNOWN = 'UNKNOWN',
  WA_BOT = 'WA_BOT',
  WA_BOT_CHANGE_REQUEST = 'WA_BOT_CHANGE_REQUEST',
  WA_MT = 'WA_MT',
  WA_MT_CHANGE_REQUEST = 'WA_MT_CHANGE_REQUEST',
  ASP_CLIENT = 'ASP_CLIENT',
  VIBER_SENDER = 'VIBER_SENDER',
  VIBER_SENDER_CHANGE_REQUEST = 'VIBER_SENDER_CHANGE_REQUEST',
  VIBER_CHANNEL = 'VIBER_CHANNEL',
  VIBER_CHANNEL_CHANGE_REQUEST = 'VIBER_CHANNEL_CHANGE_REQUEST',
  WA_EMBEDDED_SENDER_CHANGE_REQUEST = 'WA_EMBEDDED_SENDER_CHANGE_REQUEST',
}

export enum NotificationTemplateType {
  UNSPECIFIED = 'UNSPECIFIED',
  CUSTOMER_CREATED = 'CUSTOMER_CREATED',
  BOT_CREATED = 'BOT_CREATED',
  BOT_EDITED = 'BOT_EDITED',
  BOT_STARTED = 'BOT_STARTED',
  BOT_STOPPED = 'BOT_STOPPED',
  CHANGE_REQUEST_CREATED = 'CHANGE_REQUEST_CREATED',
  CHANGE_REQUEST_EDITED = 'CHANGE_REQUEST_EDITED',
  CHANGE_REQUEST_IN_REVIEW_QUEUE = 'CHANGE_REQUEST_IN_REVIEW_QUEUE',
  CHANGE_REQUEST_BEING_REVIEWED = 'CHANGE_REQUEST_BEING_REVIEWED',
  CHANGE_REQUEST_APPROVED = 'CHANGE_REQUEST_APPROVED',
  CHANGE_REQUEST_IN_PROGRESS = 'CHANGE_REQUEST_IN_PROGRESS',
  CHANGE_REQUEST_DONE = 'CHANGE_REQUEST_DONE',
  CHANGE_REQUEST_REJECTED = 'CHANGE_REQUEST_REJECTED',
  CHANGE_REQUEST_CANCELLED = 'CHANGE_REQUEST_CANCELLED',
  CHANGE_REQUEST_BEING_ROLLED_BACK = 'CHANGE_REQUEST_BEING_ROLLED_BACK',
  CUSTOMER_RCS_ONBOARDING_REQUESTED = 'CUSTOMER_RCS_ONBOARDING_REQUESTED',
  CUSTOMER_WA_ONBOARDING_REQUESTED = 'CUSTOMER_WA_ONBOARDING_REQUESTED',
  CUSTOMER_RCS_ONBOARDED = 'CUSTOMER_RCS_ONBOARDED',
  CUSTOMER_WA_ONBOARDED = 'CUSTOMER_WA_ONBOARDED',
  CUSTOMER_UPDATED = 'CUSTOMER_UPDATED',
  CUSTOMER_ACTIVATED = 'CUSTOMER_ACTIVATED',
  CUSTOMER_DISABLED = 'CUSTOMER_DISABLED',
  BOT_COMMENT_ADDED = 'BOT_COMMENT_ADDED',
  CR_COMMENT_ADDED = 'CR_COMMENT_ADDED',
  BOT_TEST = 'BOT_TEST',
  BOT_VERIFIED = 'BOT_VERIFIED',
  BOT_REGISTERED = 'BOT_REGISTERED',
  ASP_CLIENT_WA_ONBOARDING_REQUESTED = 'ASP_CLIENT_WA_ONBOARDING_REQUESTED',
  ASP_CLIENT_WA_ONBOARDED = 'ASP_CLIENT_WA_ONBOARDED',
  CHANGE_REQUEST_SET_TO_DRAFT = 'CHANGE_REQUEST_SET_TO_DRAFT',
  CUSTOMER_COMMENT_ADDED = 'CUSTOMER_COMMENT_ADDED',
  ASP_CLIENT_COMMENT_ADDED = 'ASP_CLIENT_COMMENT_ADDED',
  ASP_CLIENT_WA_PENDING_FB_VERIFICATION = 'ASP_CLIENT_WA_PENDING_FB_VERIFICATION',
  ASP_CLIENT_WA_REJECTED = 'ASP_CLIENT_WA_REJECTED',
  CUSTOMER_WA_PENDING_FB_VERIFICATION = 'CUSTOMER_WA_PENDING_FB_VERIFICATION',
  CUSTOMER_WA_REJECTED = 'CUSTOMER_WA_REJECTED',
  CHANGE_REQUEST_TERMINATED = 'CHANGE_REQUEST_TERMINATED',
  CHANGE_REQUEST_SIGNATURE_PENDING = 'CHANGE_REQUEST_SIGNATURE_PENDING',
  CUSTOMER_VIBER_ONBOARDING_REQUESTED = 'CUSTOMER_VIBER_ONBOARDING_REQUESTED',
  CUSTOMER_VIBER_ONBOARDED = 'CUSTOMER_VIBER_ONBOARDED',
  ASP_CLIENT_VIBER_ONBOARDING_REQUESTED = 'ASP_CLIENT_VIBER_ONBOARDING_REQUESTED',
  ASP_CLIENT_VIBER_ONBOARDED = 'ASP_CLIENT_VIBER_ONBOARDED',
  CHANGE_REQUEST_WA_ONBOARDING_REQUESTED = 'CHANGE_REQUEST_WA_ONBOARDING_REQUESTED',
  CHANGE_REQUEST_WA_PENDING_FB_VERIFICATION = 'CHANGE_REQUEST_WA_PENDING_FB_VERIFICATION',
  CHANGE_REQUEST_WABAID_PROVIDED = 'CHANGE_REQUEST_WABAID_PROVIDED',
  CHANGE_REQUEST_MULTIPLE_WABA_PER_CLIENT = 'CHANGE_REQUEST_MULTIPLE_WABA_PER_CLIENT',
  ACCOUNT_KT_ONBOARDED = 'ACCOUNT_KT_ONBOARDED',
  ACCOUNT_KT_ONBOARDING_REQUESTED = 'ACCOUNT_KT_ONBOARDING_REQUESTED',
  ASP_CLIENT_KT_ONBOARDED = 'ASP_CLIENT_KT_ONBOARDED',
  ASP_CLIENT_KT_ONBOARDING_REQUESTED = 'ASP_CLIENT_KT_ONBOARDING_REQUESTED',
  ASP_CLIENT_RCS_ONBOARDED = 'ASP_CLIENT_RCS_ONBOARDED',
  ASP_CLIENT_RCS_ONBOARDING_REQUESTED = 'ASP_CLIENT_RCS_ONBOARDING_REQUESTED',
}

export type IUserInfo = {
  __typename?: 'UserInfo';
  enterpriseId?: Maybe<Scalars['String']>;
  enterpriseName?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  zoneInfo?: Maybe<Scalars['String']>;
  stack?: Maybe<Scalars['String']>;
  permissions?: Maybe<IPermission>;
};

export type IPermission = {
  __typename?: 'Permission';
  view?: Maybe<Scalars['Boolean']>;
  update?: Maybe<Scalars['Boolean']>;
};

export type IChannelLimitsResponse = {
  __typename?: 'ChannelLimitsResponse';
  viberChannels: IChannelLimits;
  whatsAppChannels: IChannelLimits;
};

export type IChannelLimits = {
  __typename?: 'ChannelLimits';
  purchased: Scalars['Int'];
  consumed: Scalars['Int'];
  registered: Scalars['Int'];
};

export type IEmailNotificationsSettingsResponse = {
  __typename?: 'EmailNotificationsSettingsResponse';
  email?: Maybe<Scalars['String']>;
};

export type IEmailNotificationsSettingsUpdateResponse = {
  __typename?: 'EmailNotificationsSettingsUpdateResponse';
  success: Scalars['Boolean'];
};

export type IFileInput = {
  name: Scalars['String'];
  content: Scalars['String'];
  mimeType: Scalars['String'];
};

export type ISizeInput = {
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type IUploadCroppedImagesResponse = {
  __typename?: 'UploadCroppedImagesResponse';
  images: Array<ICroppedImage>;
};

export type ICroppedImage = {
  __typename?: 'CroppedImage';
  url: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type IUploadFileResponse = {
  __typename?: 'UploadFileResponse';
  fileUrl?: Maybe<Scalars['String']>;
};

export type IKakaoTalkSenderData = {
  __typename?: 'KakaoTalkSenderData';
  channelName: Scalars['String'];
  phoneNumber: Scalars['String'];
  topLevelCategory: Scalars['String'];
  midLevelCategory: Scalars['String'];
};

export type IKakaoTalkInput = {
  profileId: Scalars['String'];
  channelName: Scalars['String'];
  phoneNumber: Scalars['String'];
  topLevelCategory: Scalars['String'];
  midLevelCategory: Scalars['String'];
  subLevelCategory: Scalars['String'];
};

export type IViberAspClientsResponse = {
  __typename?: 'ViberAspClientsResponse';
  pending: Array<IViberAspClient>;
  onboarded: Array<IViberAspClient>;
};

export type IViberAspClient = {
  __typename?: 'ViberAspClient';
  id: Scalars['String'];
  name: Scalars['String'];
  businessLegalName: Scalars['String'];
  businessCategories: Scalars['String'];
  businessRegisteredCountry: Scalars['String'];
  warrantyLetterUrl: Scalars['String'];
  state: ViberAspClientState;
  created: Scalars['String'];
  updated: Scalars['String'];
};

export type IViberAspClientInput = {
  name: Scalars['String'];
  businessLegalName: Scalars['String'];
  businessCategories: Scalars['String'];
  businessRegisteredCountry: Scalars['String'];
  warrantyLetterUrl: Scalars['String'];
};

export type IUpdateViberAspClientInput = {
  name: Scalars['String'];
  businessLegalName: Scalars['String'];
  businessCategories: Scalars['String'];
  businessRegisteredCountry: Scalars['String'];
  warrantyLetterUrl: Scalars['String'];
  aspClientId: Scalars['String'];
};

export enum ViberAspClientState {
  UNSPECIFIED = 'UNSPECIFIED',
  NOT_REGISTERED = 'NOT_REGISTERED',
  REQUESTED = 'REQUESTED',
  ONBOARDED = 'ONBOARDED',
  REJECTED = 'REJECTED',
}

export type IViberCr = {
  __typename?: 'ViberCr';
  id: Scalars['String'];
  senderId: Scalars['String'];
  sender?: Maybe<IViberSender>;
  changes: Array<Scalars['String']>;
  state: CrState;
  created: Scalars['String'];
  updated: Scalars['String'];
};

export type IViberCrUpdateInput = {
  id: Scalars['String'];
  senderId?: Maybe<Scalars['String']>;
  sender: IViberSenderInput;
};

export type IViberCrInput = {
  senderId?: Maybe<Scalars['String']>;
  sender: IViberSenderInput;
};

export type IViberHomeResponse = {
  __typename?: 'ViberHomeResponse';
  aspClientStep: AspClientStep;
  channelStep: ChannelStep;
};

export enum AspClientStep {
  NOT_CREATED = 'NOT_CREATED',
  REQUESTED = 'REQUESTED',
  ONBOARDED = 'ONBOARDED',
}

export enum ChannelStep {
  NOT_CREATED = 'NOT_CREATED',
  REQUESTED = 'REQUESTED',
  PROVISIONED = 'PROVISIONED',
  VERIFIED = 'VERIFIED',
}

export type IViberSender = {
  __typename?: 'ViberSender';
  id: Scalars['String'];
  aspClientId: Scalars['String'];
  name: Scalars['String'];
  destinationCountries: Array<Scalars['String']>;
  businessLaunchDate: Scalars['String'];
  logoUrls: Array<ILogoUrl>;
  description: Scalars['String'];
  address: Scalars['String'];
  phoneNumber: Scalars['String'];
  website: Scalars['String'];
  state: ViberSenderState;
  created: Scalars['String'];
  updated: Scalars['String'];
  extraDocs?: Maybe<Array<IExtraDoc>>;
  sampleMessage: Scalars['String'];
  cr?: Maybe<IViberCr>;
};

export type IViberSenderInput = {
  id?: Maybe<Scalars['String']>;
  aspClientId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  destinationCountries?: Maybe<Array<Scalars['String']>>;
  businessLaunchDate?: Maybe<Scalars['String']>;
  logoUrls: Array<ILogoUrlInput>;
  description?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  extraDocs?: Maybe<Array<IExtraDocInput>>;
  sampleMessage?: Maybe<Scalars['String']>;
};

export enum ViberSenderState {
  UNSPECIFIED = 'UNSPECIFIED',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ViberSenderAction {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
}

export type IViberSenderFilter = {
  ids?: Maybe<Array<Scalars['String']>>;
  states?: Maybe<Array<ViberSenderState>>;
};

export type ILogoUrlInput = {
  url: Scalars['String'];
  size: ISizeInput;
  name: Scalars['String'];
};

export type ILogoUrl = {
  __typename?: 'LogoUrl';
  url: Scalars['String'];
  size: ISize;
  name: Scalars['String'];
};

export type IExtraDocInput = {
  url: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  permanent: Scalars['Boolean'];
  contentLength?: Maybe<Scalars['Int']>;
};

export type IExtraDoc = {
  __typename?: 'ExtraDoc';
  url: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  permanent: Scalars['Boolean'];
  contentLength: Scalars['Int'];
};

export type ISize = {
  __typename?: 'Size';
  width: Scalars['Int'];
  height: Scalars['Int'];
};

export type IWhatsAppAspClientsResponse = {
  __typename?: 'WhatsAppAspClientsResponse';
  pending: Array<IWhatsAppAspClient>;
  onboarded: Array<IWhatsAppAspClient>;
};

export type IWhatsAppAspClient = {
  __typename?: 'WhatsAppAspClient';
  id: Scalars['String'];
  name: Scalars['String'];
  facebookBusinessManagerId: Scalars['String'];
  companyLegalName: Scalars['String'];
  state: AspClientState;
  created: Scalars['String'];
  updated: Scalars['String'];
};

export type IWhatsAppAspClientInput = {
  name: Scalars['String'];
  facebookBusinessManagerId: Scalars['String'];
  companyLegalName: Scalars['String'];
};

export type IUpdateWhatsAppAspClientInput = {
  name: Scalars['String'];
  facebookBusinessManagerId: Scalars['String'];
  companyLegalName: Scalars['String'];
  aspClientId: Scalars['String'];
};

export enum AspClientState {
  UNSPECIFIED = 'UNSPECIFIED',
  NOT_REGISTERED = 'NOT_REGISTERED',
  REQUESTED = 'REQUESTED',
  ONBOARDED = 'ONBOARDED',
  PENDING_FB_VERIFICATION = 'PENDING_FB_VERIFICATION',
  REJECTED = 'REJECTED',
}

export type IWhatsAppBot = {
  __typename?: 'WhatsAppBot';
  id: Scalars['String'];
  aspClientId: Scalars['String'];
  aspClientName: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  state: BotState;
  websiteOne: Scalars['String'];
  websiteTwo: Scalars['String'];
  email: Scalars['String'];
  phone: Scalars['String'];
  photoUrl: Scalars['String'];
  countryCode: Scalars['String'];
  vertical: WhatsAppVertical;
  address: Scalars['String'];
  about: Scalars['String'];
  numberProviderType: NumberProviderType;
  created: Scalars['String'];
  updated: Scalars['String'];
  botCr?: Maybe<IWhatsAppCr>;
  quality: WhatsAppQuality;
  dailyLimit: WhatsAppDailyLimit;
};

export type IWhatsAppBotResponse = {
  __typename?: 'WhatsAppBotResponse';
  botId: Scalars['String'];
  registrationResponse?: Maybe<WhatsAppRegistrationResponse>;
};

export type IWhatsAppBotInput = {
  id?: Maybe<Scalars['String']>;
  aspClientId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  websiteOne?: Maybe<Scalars['String']>;
  websiteTwo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  state?: Maybe<BotState>;
  countryCode?: Maybe<Scalars['String']>;
  vertical: WhatsAppVertical;
  address?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  numberProviderType: NumberProviderType;
};

export enum WhatsAppBotAction {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  TEST = 'TEST',
}

export enum NumberProviderType {
  CUSTOMER = 'CUSTOMER',
  SINCH = 'SINCH',
}

export enum WhatsAppRegistrationResponse {
  VERIFICATION_NEEDED = 'VERIFICATION_NEEDED',
  ALREADY_VERIFIED = 'ALREADY_VERIFIED',
}

export enum WhatsAppRegistrationMethod {
  SMS = 'SMS',
  VOICE = 'VOICE',
}

export enum WhatsAppVertical {
  OTHER = 'OTHER',
  AUTOMOTIVE = 'AUTOMOTIVE',
  BEAUTY_SPA_SALON = 'BEAUTY_SPA_SALON',
  CLOTHING_AND_APPAREL = 'CLOTHING_AND_APPAREL',
  EDUCATION = 'EDUCATION',
  ENTERTAINMENT = 'ENTERTAINMENT',
  EVENT_PLANNING_AND_SERVICE = 'EVENT_PLANNING_AND_SERVICE',
  FINANCE_AND_BANKING = 'FINANCE_AND_BANKING',
  FOOD_AND_GROCERY = 'FOOD_AND_GROCERY',
  PUBLIC_SERVICE = 'PUBLIC_SERVICE',
  HOTEL_AND_LODGING = 'HOTEL_AND_LODGING',
  MEDICAL_AND_HEALTH = 'MEDICAL_AND_HEALTH',
  NON_PROFIT = 'NON_PROFIT',
  PROFESSIONAL_SERVICES = 'PROFESSIONAL_SERVICES',
  SHOPPING_AND_RETAIL = 'SHOPPING_AND_RETAIL',
  TRAVEL_AND_TRANSPORTATION = 'TRAVEL_AND_TRANSPORTATION',
  RESTAURANT = 'RESTAURANT',
}

export enum WhatsAppDailyLimit {
  UNKNOWN = 'UNKNOWN',
  TIER_NOT_SET = 'TIER_NOT_SET',
  TIER_50 = 'TIER_50',
  TIER_1K = 'TIER_1K',
  TIER_10K = 'TIER_10K',
  TIER_100K = 'TIER_100K',
  TIER_UNLIMITED = 'TIER_UNLIMITED',
}

export enum WhatsAppQuality {
  UNKNOWN = 'UNKNOWN',
  RED = 'RED',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
}

export type IWhatsAppCr = {
  __typename?: 'WhatsAppCr';
  id: Scalars['String'];
  botId: Scalars['String'];
  bot?: Maybe<IWhatsAppBot>;
  changes: Array<Scalars['String']>;
  state: CrState;
  created: Scalars['String'];
  updated: Scalars['String'];
};

export type IWhatsAppCrUpdateInput = {
  id: Scalars['String'];
  botId?: Maybe<Scalars['String']>;
  bot: IWhatsAppBotInput;
};

export type IWhatsAppCrInput = {
  botId?: Maybe<Scalars['String']>;
  bot: IWhatsAppBotInput;
};

export type IWhatsAppHomeResponse = {
  __typename?: 'WhatsAppHomeResponse';
  aspClientStep: AspClientStep;
  channelStep: ChannelStep;
  templateStep: TemplateStep;
};

export enum TemplateStep {
  NOT_CREATED = 'NOT_CREATED',
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
}

export type IWhatsAppMtDeleteResponse = {
  __typename?: 'WhatsAppMtDeleteResponse';
  success: Scalars['Boolean'];
};

export type ITransitionCrInput = {
  crIds: Array<Scalars['String']>;
  aspClientId: Scalars['String'];
};

export type IWhatsAppMtQueryResponse = {
  __typename?: 'WhatsAppMtQueryResponse';
  pending: Array<IWhatsAppMtCr>;
  approved: Array<IWhatsAppMt>;
};

export type IWhatsAppMtOrMtCr = IWhatsAppMt | IWhatsAppMtCr;

export type IWhatsAppMtCr = {
  __typename?: 'WhatsAppMtCr';
  aspClientId: Scalars['String'];
  name: Scalars['String'];
  category: WhatsAppMtCategory;
  contents: Array<IWhatsAppMtCrContent>;
};

export type IWhatsAppMt = {
  __typename?: 'WhatsAppMt';
  aspClientId: Scalars['String'];
  name: Scalars['String'];
  category: WhatsAppMtCategory;
  contents: Array<IWhatsAppMtTemplateContent>;
};

export type IWhatsAppMtTemplateContent =
  | IWhatsAppMtContent
  | IWhatsAppMtCrContent;

export type IWhatsAppMtContent = {
  __typename?: 'WhatsAppMtContent';
  id: Scalars['String'];
  type: WhatsAppMtTemplateContentType;
  templateStatus: WhatsAppMtStatus;
  language: WhatsAppMtLanguage;
  headerType: WhatsAppMtHeaderType;
  headerText?: Maybe<Scalars['String']>;
  mediaType: WhatsAppMtMediaType;
  bodyText: Scalars['String'];
  footer?: Maybe<Scalars['String']>;
  buttonType: WhatsAppMtButtonType;
  quickReplyButtons?: Maybe<Array<Scalars['String']>>;
  callToActionButtons?: Maybe<Array<IWhatsAppMtActions>>;
  mediaVariable?: Maybe<IWhatsAppMtMediaVariable>;
  headerVariable?: Maybe<Scalars['String']>;
  bodyVariables?: Maybe<Array<Scalars['String']>>;
  buttonUrlVariable?: Maybe<Scalars['String']>;
  securityRecommendation?: Maybe<Scalars['Boolean']>;
  codeExpirationMinutes?: Maybe<Scalars['Int']>;
  otpType?: Maybe<WhatsAppMtOtpType>;
  buttonText?: Maybe<Scalars['String']>;
  autofillText?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  signatureHash?: Maybe<Scalars['String']>;
};

export type IWhatsAppMtCrContent = {
  __typename?: 'WhatsAppMtCrContent';
  id: Scalars['String'];
  type: WhatsAppMtTemplateContentType;
  crStatus: CrState;
  language: WhatsAppMtLanguage;
  headerType: WhatsAppMtHeaderType;
  headerText?: Maybe<Scalars['String']>;
  mediaType: WhatsAppMtMediaType;
  bodyText: Scalars['String'];
  footer?: Maybe<Scalars['String']>;
  buttonType: WhatsAppMtButtonType;
  quickReplyButtons?: Maybe<Array<Scalars['String']>>;
  callToActionButtons?: Maybe<Array<IWhatsAppMtActions>>;
  mediaVariable?: Maybe<IWhatsAppMtMediaVariable>;
  headerVariable?: Maybe<Scalars['String']>;
  bodyVariables?: Maybe<Array<Scalars['String']>>;
  buttonUrlVariable?: Maybe<Scalars['String']>;
  securityRecommendation?: Maybe<Scalars['Boolean']>;
  codeExpirationMinutes?: Maybe<Scalars['Int']>;
  otpType?: Maybe<WhatsAppMtOtpType>;
  buttonText?: Maybe<Scalars['String']>;
  autofillText?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  signatureHash?: Maybe<Scalars['String']>;
};

export type IWhatsAppMtActions =
  | IWhatsAppMtPhoneAction
  | IWhatsAppMtWebsiteAction;

export type IWhatsAppMtPhoneAction = {
  __typename?: 'WhatsAppMtPhoneAction';
  phoneNumber: Scalars['String'];
  buttonText: Scalars['String'];
};

export type IWhatsAppMtWebsiteAction = {
  __typename?: 'WhatsAppMtWebsiteAction';
  urlType: WhatsAppMtUrlType;
  websiteUrl: Scalars['String'];
  buttonText: Scalars['String'];
};

export type IWhatsAppMtMediaVariable = {
  __typename?: 'WhatsAppMtMediaVariable';
  url: Scalars['String'];
  type: Scalars['String'];
};

export type IWhatsAppMtCrInput = {
  aspClientId: Scalars['String'];
  name: Scalars['String'];
  category: WhatsAppMtCategory;
  contents?: Maybe<Array<IWhatsAppMtCrContentInput>>;
  authenticationContents?: Maybe<
    Array<IWhatsAppAuthenticationMtCrContentInput>
  >;
};

export type IWhatsAppAuthenticationMtCrContentInput = {
  language: WhatsAppMtLanguage;
  securityRecommendation?: Maybe<Scalars['Boolean']>;
  codeExpirationMinutes?: Maybe<Scalars['Int']>;
  otpType: WhatsAppMtOtpType;
  buttonText: Scalars['String'];
  autofillText?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  signatureHash?: Maybe<Scalars['String']>;
};

export type IWhatsAppMtCrContentInput = {
  language: WhatsAppMtLanguage;
  headerType: WhatsAppMtHeaderType;
  headerText?: Maybe<Scalars['String']>;
  mediaType: WhatsAppMtMediaType;
  bodyText: Scalars['String'];
  footer?: Maybe<Scalars['String']>;
  buttonType: WhatsAppMtButtonType;
  quickReplyButtons?: Maybe<Array<Scalars['String']>>;
  callToActionButtons?: Maybe<Array<IWhatsAppMtActionInput>>;
  mediaVariable?: Maybe<IWhatsAppMtMediaVariableInput>;
  headerVariable?: Maybe<Scalars['String']>;
  bodyVariables?: Maybe<Array<Scalars['String']>>;
  buttonUrlVariable?: Maybe<Scalars['String']>;
};

export type IWhatsAppMtActionInput = {
  phoneAction?: Maybe<IWhatsAppMtPhoneActionInput>;
  websiteAction?: Maybe<IWhatsAppMtWebsiteActionInput>;
};

export type IWhatsAppMtPhoneActionInput = {
  phoneNumber: Scalars['String'];
  buttonText: Scalars['String'];
};

export type IWhatsAppMtWebsiteActionInput = {
  urlType: WhatsAppMtUrlType;
  websiteUrl: Scalars['String'];
  buttonText: Scalars['String'];
};

export type IWhatsAppMtMediaVariableInput = {
  url: Scalars['String'];
  type: Scalars['String'];
};

export enum WhatsAppMtOtpType {
  COPY_CODE = 'COPY_CODE',
  ONE_TAP = 'ONE_TAP',
}

export enum WhatsAppMtTemplateContentType {
  TEMPLATE = 'TEMPLATE',
  CHANGE_REQUEST = 'CHANGE_REQUEST',
}

export enum WhatsAppMtCategory {
  NO_CATEGORY = 'NO_CATEGORY',
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  PAYMENT_UPDATE = 'PAYMENT_UPDATE',
  PERSONAL_FINANCE_UPDATE = 'PERSONAL_FINANCE_UPDATE',
  SHIPPING_UPDATE = 'SHIPPING_UPDATE',
  RESERVATION_UPDATE = 'RESERVATION_UPDATE',
  ISSUE_RESOLUTION = 'ISSUE_RESOLUTION',
  APPOINTMENT_UPDATE = 'APPOINTMENT_UPDATE',
  TRANSPORTATION_UPDATE = 'TRANSPORTATION_UPDATE',
  TICKET_UPDATE = 'TICKET_UPDATE',
  ALERT_UPDATE = 'ALERT_UPDATE',
  AUTO_REPLY = 'AUTO_REPLY',
  TRANSACTIONAL = 'TRANSACTIONAL',
  MARKETING = 'MARKETING',
  ONE_TIME_PASSWORDS = 'ONE_TIME_PASSWORDS',
  UTILITY = 'UTILITY',
  AUTHENTICATION = 'AUTHENTICATION',
}

export enum WhatsAppMtLanguage {
  NO_LANGUAGE = 'NO_LANGUAGE',
  AF = 'AF',
  SQ = 'SQ',
  AR = 'AR',
  AZ = 'AZ',
  BN = 'BN',
  BG = 'BG',
  CA = 'CA',
  ZH_CN = 'ZH_CN',
  ZH_HK = 'ZH_HK',
  ZH_TW = 'ZH_TW',
  HR = 'HR',
  CS = 'CS',
  DA = 'DA',
  NL = 'NL',
  EN = 'EN',
  EN_GB = 'EN_GB',
  EN_US = 'EN_US',
  ET = 'ET',
  FIL = 'FIL',
  FI = 'FI',
  FR = 'FR',
  DE = 'DE',
  EL = 'EL',
  GU = 'GU',
  HA = 'HA',
  HE = 'HE',
  HI = 'HI',
  HU = 'HU',
  ID = 'ID',
  GA = 'GA',
  IT = 'IT',
  JA = 'JA',
  KN = 'KN',
  KK = 'KK',
  KO = 'KO',
  LO = 'LO',
  LV = 'LV',
  LT = 'LT',
  MK = 'MK',
  MS = 'MS',
  ML = 'ML',
  MR = 'MR',
  NB = 'NB',
  FA = 'FA',
  PL = 'PL',
  PT_BR = 'PT_BR',
  PT_PT = 'PT_PT',
  PA = 'PA',
  RO = 'RO',
  RU = 'RU',
  SR = 'SR',
  SK = 'SK',
  SL = 'SL',
  ES = 'ES',
  ES_AR = 'ES_AR',
  ES_ES = 'ES_ES',
  ES_MX = 'ES_MX',
  SW = 'SW',
  SV = 'SV',
  TA = 'TA',
  TE = 'TE',
  TH = 'TH',
  TR = 'TR',
  UK = 'UK',
  UR = 'UR',
  UZ = 'UZ',
  VI = 'VI',
  ZU = 'ZU',
  KA = 'KA',
  RW_RW = 'RW_RW',
  KY_KG = 'KY_KG',
}

export enum WhatsAppMtMediaType {
  NONE = 'NONE',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

export enum WhatsAppMtButtonType {
  NONE = 'NONE',
  QUICK_REPLY = 'QUICK_REPLY',
  CALL_TO_ACTION = 'CALL_TO_ACTION',
}

export enum WhatsAppMtUrlType {
  STATIC = 'STATIC',
  DYNAMIC = 'DYNAMIC',
}

export enum WhatsAppMtHeaderType {
  NONE = 'NONE',
  TEXT = 'TEXT',
  MEDIA = 'MEDIA',
}

export enum WhatsAppMtStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REJECTED = 'REJECTED',
  PAUSED = 'PAUSED',
}
