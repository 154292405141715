import React, { ReactNode } from 'react';

interface IDocumentationLinkProps {
  children: ReactNode;
}

export const DocumentationLink = ({
  children,
  ...props
}: IDocumentationLinkProps) => {
  return (
    <a
      href="https://clxlundrcs.s3.eu-central-1.amazonaws.com/SCM/SCM-docs.pdf"
      target="_blank"
      {...props}
    >
      {children}
    </a>
  );
};
