import { WhatsAppQuality } from '@shared/bff';

import { TagType } from '../components/LabelTag';

const whatsAppQualityMapping: Record<WhatsAppQuality, TagType> = {
  [WhatsAppQuality.UNKNOWN]: 'transparent',
  [WhatsAppQuality.RED]: 'error',
  [WhatsAppQuality.YELLOW]: 'warning',
  [WhatsAppQuality.GREEN]: 'success',
};

export const mapWhatsAppQualityToType = (quality: WhatsAppQuality): TagType => {
  return whatsAppQualityMapping[quality];
};
