import React, { ReactNode } from 'react';

import { Text } from '../Text';
import { Box } from '../Box';

interface IRequiredFieldLabelProps {
  children: ReactNode;
}

export const RequiredFieldLabel = ({ children }: IRequiredFieldLabelProps) => (
  <Box display="inline-flex">
    <Box ml="xxx-small" mr="xxx-small">
      <Text variant="body_small" color="error">
        *
      </Text>
    </Box>
    {children}
  </Box>
);
