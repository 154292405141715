import React, { useContext } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import styled from 'styled-components';

import { t } from '@src/messages';
import { IFileInput } from '@src/common/types';

import { Box } from '../Box';
import { Text } from '../Text';
import { UploadButton } from '../UploadButton';
import { ToastContext } from '../ToastProvider';

export interface IDragDropProps {
  options?: DropzoneOptions;
  onLoad: (files: IFileInput[]) => void;
  uploadButtonTitle: string;
}

const DropZone = styled.div({
  padding: '3px',
  border: '1px dashed #DDDBDA',
  borderRadius: '4px',
});

const defaultOptions = {
  multiple: true,
  accept:
    'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  noClick: true,
  noKeyboard: true,
};

export const DragDrop = ({
  options,
  onLoad,
  uploadButtonTitle,
}: IDragDropProps) => {
  const { toast } = useContext(ToastContext);

  const mergedOptions = { ...defaultOptions, ...options };

  const onDrop = async (acceptedFiles: File[]) => {
    const files: Promise<IFileInput>[] = acceptedFiles.map((file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve) => {
        reader.onload = () => {
          resolve({
            name: file.name,
            mimeType: file.type,
            content: reader.result?.toString() ?? '',
            blob: file,
          });
        };
      });
    });
    onLoad(await Promise.all(files));
  };
  const onDropRejected = async () => {
    toast('error', {
      heading: t.common.toasts.uploadRejected(),
    });
  };
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    onDropRejected,
    ...mergedOptions,
  });

  return (
    <DropZone {...getRootProps()} id="drop-zone-files">
      <input {...getInputProps()} />
      <Box display="flex" alignItems="center">
        <UploadButton
          onClick={open}
          title={uploadButtonTitle}
          id="upload-files-button"
        />
        <Text color="weak" ml="x-small">
          {mergedOptions.multiple
            ? t.common.components.fileUploader.multiple.dropZoneLabel()
            : t.common.components.fileUploader.single.dropZoneLabel()}
        </Text>
      </Box>
    </DropZone>
  );
};
