import React from 'react';
import { IInputProps, InputIcon } from '@salesforce/design-system-react';
import styled from 'styled-components';

import { phoneNumberE164MaxLength } from '@src/common/utils';

import { InputField } from './InputField';

interface IPhoneNumberFieldProps<TObject> extends IInputProps {
  name: keyof TObject & string;
  tooltip?: string;
}

const InputFieldWrapper = styled.div({
  '& .slds-input__icon': {
    height: '0.7rem',
    width: '0.7rem',
    fill: '#080707',
    marginTop: '-0.4rem',
  },
});

// any is required for component to work properly in cases
// where no generic parameter is provided
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PhoneNumberField<TObject = any>(
  props: IPhoneNumberFieldProps<TObject>
) {
  return (
    <InputFieldWrapper>
      <InputField
        maxLength={phoneNumberE164MaxLength}
        iconLeft={<InputIcon name="add" category="utility" />}
        regexConstraint={/[\D]/g}
        {...props}
      />
    </InputFieldWrapper>
  );
}
