import React, { useState } from 'react';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import {
  Combobox,
  IComboboxProps,
  IComboboxOption,
  OnComboboxRequestRemoveSelectedOptionCallback,
  OnComboboxSelectCallback,
  ComboboxVariant,
  Tooltip,
  OnComboboxChangeCallback,
} from '@salesforce/design-system-react';

import { uuid } from '@src/common/utils';

import { MultiLineErrorMessage } from './MultiLineErrorMessage';
import { RequiredFieldLabel } from './RequiredFieldLabel';

export interface IMultiSelectFieldProps<TObject> extends IComboboxProps {
  name: keyof TObject & string;
  options: IComboboxOption[];
  variant: ComboboxVariant;
  tooltip?: string;
  maxNumberOfSelected?: number;
}

const addIdToOptions = (options: IComboboxOption[]) =>
  options.map((o) => (o.id ? o : { ...o, id: o.value }));

const getSelectedOptions = (
  options: IComboboxOption[],
  selectedValues: string[]
) =>
  selectedValues.map(
    (value) => options.find((o) => o.value === value) || { value, label: value }
  );

const getNonSelectedOptions = (
  options: IComboboxOption[],
  selectedValues: string[]
) => options.filter((o) => !selectedValues.includes(o.value));

const fieldToCombobox = (
  { name, value, onChange, ...field }: FieldInputProps<string[]>,
  touched: boolean,
  setFieldValue: (name: string, value: string[]) => void,
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void,
  {
    options,
    tooltip,
    events,
    labels: rawLabels,
    required,
    maxNumberOfSelected,
    ...props
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IMultiSelectFieldProps<any>
): IComboboxProps => {
  const optionsWithId = addIdToOptions(options);
  const [searchValue, setSearchValue] = useState<string>('');
  const nonSelectedOptions: IComboboxOption[] = getNonSelectedOptions(
    optionsWithId,
    value
  );

  const [availableOptions, setAvailableOptions] =
    useState<IComboboxOption[]>(nonSelectedOptions);

  const selection: IComboboxOption[] = Array.isArray(value)
    ? getSelectedOptions(optionsWithId, value)
    : [];

  const label =
    required && rawLabels?.label ? (
      <RequiredFieldLabel>{rawLabels.label}</RequiredFieldLabel>
    ) : (
      rawLabels?.label
    );
  const labels = {
    ...rawLabels,
    label: label as string,
  };

  const onSelect: OnComboboxSelectCallback = (
    _,
    { selection: newSelection }
  ) => {
    if (!maxNumberOfSelected || newSelection.length <= maxNumberOfSelected) {
      const selectedOptions = newSelection.map((s) => s.value);
      const filteredOptions = getNonSelectedOptions(
        optionsWithId,
        selectedOptions
      );
      setFieldValue(name, selectedOptions);
      setFieldTouched(name, true, false);
      setSearchValue('');
      setAvailableOptions(filteredOptions);
    }
  };

  const onRequestRemoveSelectedOption: OnComboboxRequestRemoveSelectedOptionCallback =
    (_, { selection: newSelection }) => {
      const selectedOptions = newSelection.map((s) => s.value);
      const remainingOptions = getNonSelectedOptions(
        optionsWithId,
        selectedOptions
      );
      setFieldValue(name, selectedOptions);
      setFieldTouched(name, true, false);
      setAvailableOptions(remainingOptions);
    };

  const onSearch: OnComboboxChangeCallback = (_, { value: searchText }) => {
    const filteredOptions = nonSelectedOptions.filter((o) =>
      o.label.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchValue(searchText);
    setAvailableOptions(filteredOptions);
  };

  const onBlur = () => {
    if (!searchValue && selection.length === 0 && !touched) {
      return;
    }
    setFieldTouched(name, true, false);
  };

  return {
    events: {
      onBlur,
      onSelect,
      onRequestRemoveSelectedOption,
      onChange: onSearch,
      ...events,
    },
    selection,
    options: availableOptions,
    value: searchValue,
    labels,
    fieldLevelHelpTooltip: tooltip ? (
      <Tooltip
        id={`${uuid()}-level-help-tooltip`}
        align="top left"
        content={tooltip}
        variant="learnMore"
      />
    ) : undefined,
    ...field,
    ...props,
  };
};

// any is required for component to work properly in cases
// where no generic parameter is provided
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MultiSelectField<TObject = any>(
  props: IMultiSelectFieldProps<TObject>
) {
  const [field, meta] = useField({
    name: props.name,
  });
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const { error, touched } = meta;

  return (
    <MultiLineErrorMessage
      errors={error}
      touched={touched}
      render={(errorText) => (
        <Combobox
          {...fieldToCombobox(
            field,
            touched,
            setFieldValue,
            setFieldTouched,
            props
          )}
          multiple
          errorText={errorText}
          menuItemVisibleLength={5}
        />
      )}
    />
  );
}
