import React from 'react';
import styled from 'styled-components';

import { Text } from '../Text';

export type TagType =
  | 'success'
  | 'default'
  | 'warning'
  | 'error'
  | 'emphasize'
  | 'transparent'
  | 'inverted';

interface IStyleProps {
  type?: TagType;
  showRaw?: boolean;
}

interface ILabelTagProps extends IStyleProps {
  children: React.ReactNode;
  id?: string;
  className?: string;
}

interface ILabel {
  text: string;
  background: string;
  border?: string;
}

const typeColor: Record<TagType, ILabel> = {
  success: {
    text: '#FFFFFF',
    background: '#04844B',
  },
  default: {
    text: '#000000',
    background: '#ECEBEA',
  },
  warning: {
    text: '#000000',
    background: '#FFB75D',
  },
  error: {
    text: '#FFFFFF',
    background: '#C23934',
  },
  emphasize: {
    text: '#FFFFFF',
    background: '#706E6B',
  },
  transparent: {
    text: '#000000',
    background: 'transparent',
    border: '1px solid #DBDBDB',
  },
  inverted: {
    text: '#080707',
    background: '#FFFFFF',
    border: '1px solid #DDDBDA',
  },
};

const Wrapper = styled.span(({ type = 'default' }: IStyleProps) => {
  return {
    borderRadius: '20px',
    padding: '3px 8px 3px 8px',
    fontWeight: 700,
    fontSize: '12px',
    backgroundColor: typeColor[type].background,
    color: typeColor[type].text,
    border: typeColor[type].border,
  };
});

export const LabelTag = ({
  type,
  showRaw = false,
  className,
  children,
  id,
}: ILabelTagProps) => {
  return showRaw ? (
    <Text data-id={id ?? 'element-status'} weight="bold" variant="body_small">
      {children}
    </Text>
  ) : (
    <Wrapper data-id={id ?? 'element-status'} type={type} className={className}>
      {children}
    </Wrapper>
  );
};
