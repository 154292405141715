import { gql } from '@apollo/client';

import { IUploadFileResponse } from '@shared/bff';

export interface UploadFileMutationResponse {
  uploadFile: IUploadFileResponse;
}

export const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFile($file: FileInput!) {
    uploadFile(file: $file) {
      fileUrl
    }
  }
`;
