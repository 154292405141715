import React, { useEffect, useState, ReactNode, useContext } from 'react';
import i18n from 'i18next';
import Fetch from 'i18next-fetch-backend';

import { UserContext } from '@src/common/components';

import { initializeYupErrorMessages } from './initializeYupErrorMessages';

interface I18nextProviderProps {
  children: ReactNode;
}

const supportedLocales: string[] = [
  'de-DE',
  'en-US',
  'es-41',
  'es-ES',
  'fr-CA',
  'fr-FR',
  'it-IT',
  'ja-JP',
  'pt-BR',
];

const localeMapper: Record<string, string> = {
  ['en']: 'en-US',
  ['de']: 'de-DE',
  ['es']: 'es-ES',
  ['fr']: 'fr-FR',
  ['it']: 'it-IT',
  ['pt']: 'pt-BR',
};

export const I18nextProvider = ({ children }: I18nextProviderProps) => {
  const { user } = useContext(UserContext);
  const [isLoaded, setIsLoaded] = useState(false);

  const language = user.locale.split('-')[0];
  const isSupported = supportedLocales.includes(user.locale);
  const locale = isSupported ? user.locale : localeMapper[language] || 'en-US';

  useEffect(() => {
    i18n
      .use(Fetch)
      .init({
        backend: { loadPath: '/resources/languages/{{lng}}.json' },
        lng: locale,
        fallbackLng: 'en-US',

        interpolation: {
          escapeValue: false,
        },
        load: 'currentOnly',
      })
      .then(() => {
        initializeYupErrorMessages();
        setIsLoaded(true);
      });
  }, []);
  document.documentElement.lang = i18n.language;

  return <>{isLoaded && children}</>;
};
