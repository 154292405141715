import { PredefinedDuration, Duration } from './types';

const durationMatcher: Record<PredefinedDuration, number | undefined> = {
  short: 3000,
  medium: 5000,
  long: 8000,
  forever: undefined,
};

export const getDuration = (duration?: Duration): number | undefined => {
  duration = duration ?? 'forever';

  if (typeof duration === 'number') {
    return duration;
  }

  return durationMatcher[duration];
};
