import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

type Spacing =
  | 'xxx-small'
  | 'xx-small'
  | 'x-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'x-large'
  | 'xx-large';

type Justify =
  | 'start'
  | 'center'
  | 'end'
  | 'left'
  | 'right'
  | 'flex-start'
  | 'flex-end'
  | 'space-between';

type Alignment = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';

type Display = 'block' | 'inline-block' | 'flex' | 'inline-flex' | 'inline';

type Direction = 'row' | 'column';

type Wrap = 'wrap' | 'nowrap';

export interface IBoxProps {
  mt?: Spacing;
  ml?: Spacing;
  mr?: Spacing;
  mb?: Spacing;
  pt?: Spacing;
  pl?: Spacing;
  pr?: Spacing;
  pb?: Spacing;
  children?: React.ReactNode;
  display?: Display;
  flexDirection?: Direction;
  justifyContent?: Justify;
  flexWrap?: Wrap;
  alignItems?: Alignment;
  height?: string;
  className?: string;
  id?: string;
}

const BoxWrapper = styled.div(
  ({
    display,
    justifyContent,
    alignItems,
    flexWrap,
    height,
    flexDirection,
  }: IBoxProps) => ({
    display,
    justifyContent,
    alignItems,
    flexWrap,
    height,
    flexDirection,
  })
);

export const Box = ({
  mt,
  ml,
  mr,
  mb,
  pt,
  pl,
  pr,
  pb,
  className,
  children,
  id,
  ...props
}: IBoxProps) => {
  return (
    <BoxWrapper
      id={id}
      className={classNames(
        mt && `slds-m-top_${mt}`,
        ml && `slds-m-left_${ml}`,
        mr && `slds-m-right_${mr}`,
        mb && `slds-m-bottom_${mb}`,
        pt && `slds-p-top_${pt}`,
        pl && `slds-p-left_${pl}`,
        pr && `slds-p-right_${pr}`,
        pb && `slds-p-bottom_${pb}`,
        className
      )}
      {...props}
    >
      {children}
    </BoxWrapper>
  );
};
