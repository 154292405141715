import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import {
  Combobox,
  IComboboxProps,
  IComboboxOption,
  OnComboboxSelectCallback,
  Tooltip,
  ComboboxVariant,
  OnComboboxChangeCallback,
  OnComboboxRequestRemoveSelectedOptionCallback,
} from '@salesforce/design-system-react';

import { uuid } from '@src/common/utils';

import { RequiredFieldLabel } from './RequiredFieldLabel';

export interface ISingleSelectFieldProps<TObject> extends IComboboxProps {
  name: keyof TObject & string;
  options: IComboboxOption[];
  tooltip?: string;
  variant: ComboboxVariant;
  id?: string;
  onSelect?: (selectedValue: unknown) => void;
}

const getSelectedOption = (
  options: IComboboxOption[],
  selectedValue?: string
) => options.find((o) => o.value === selectedValue);

const addIdToOptions = (options: IComboboxOption[]) =>
  options.map((o) => (o.id ? o : { ...o, id: o.value }));

// any is required for component to work properly in cases
// where no generic parameter is provided
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function SingleSelectField<TObject = any>({
  name,
  options,
  labels: rawLabels,
  required,
  tooltip,
  variant,
  id,
  onSelect,
  ...props
}: ISingleSelectFieldProps<TObject>) {
  const [field, meta, helpers] = useField(name);
  const { setTouched, setValue } = helpers;
  const { error, touched } = meta;
  const { value } = field;

  const optionsWithId = addIdToOptions(options);

  const [availableOptions, setAvailableOptions] =
    useState<IComboboxOption[]>(optionsWithId);
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    setAvailableOptions(addIdToOptions(options));
  }, [options]);

  const selectedOption =
    typeof value === 'string'
      ? getSelectedOption(optionsWithId, value)
      : undefined;
  const selection: IComboboxOption[] =
    selectedOption !== undefined
      ? [
          {
            ...selectedOption,
            label: selectedOption.selectedLabel ?? selectedOption.label,
          },
        ]
      : [];

  // Label can only be as a string, but we need to handle required fields
  const label =
    required && rawLabels?.label ? (
      <RequiredFieldLabel>{rawLabels.label}</RequiredFieldLabel>
    ) : (
      rawLabels?.label
    );

  const onBlur = () => {
    if (!searchValue && selection.length === 0 && !touched) {
      return;
    }
    setTouched(true);
  };
  const onSearch: OnComboboxChangeCallback = (_, { value: searchText }) => {
    const filteredOptions = optionsWithId.filter((o) =>
      o.label.toLowerCase().includes(searchText.toLowerCase())
    );
    setSearchValue(searchText);
    setAvailableOptions(filteredOptions);
  };
  const onSingleSelect: OnComboboxSelectCallback = (
    _,
    { selection: newSelection }
  ) => {
    const newSelectionOption = newSelection[0] ? newSelection[0].value : '';
    setTouched(true);
    setSearchValue('');
    setValue(newSelectionOption);
    setAvailableOptions(optionsWithId);

    if (onSelect) {
      onSelect(newSelectionOption);
    }
  };
  const onRequestRemoveSelectedOption: OnComboboxRequestRemoveSelectedOptionCallback =
    () => {
      setValue('');
      setTouched(true);
    };

  return (
    <Combobox
      labels={{
        ...rawLabels,
        label: label as string,
      }}
      fieldLevelHelpTooltip={
        tooltip && (
          <Tooltip
            id={`${uuid()}-level-help-tooltip`}
            align="top left"
            content={tooltip}
            variant="learnMore"
            position="overflowBoundaryElement"
          />
        )
      }
      options={availableOptions}
      selection={selection}
      value={searchValue}
      events={{
        onBlur,
        onSelect: onSingleSelect,
        onRequestRemoveSelectedOption,
        onChange: onSearch,
      }}
      variant={variant}
      errorText={touched ? error : ''}
      menuItemVisibleLength={5}
      id={id}
      {...props}
    />
  );
}
