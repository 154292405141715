import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { Layout } from './Layout';

export const Routes = () => (
  <Switch>
    <Route path="/" component={Layout} />
  </Switch>
);
