import { gql } from '@apollo/client';

import { IActivity } from '@shared/bff';

import { ACTIVITY_FRAGMENT } from '../fragments';

export type ICreateCommentResponse = IActivity;

export const CREATE_COMMENT_MUTATION = gql`
  mutation CreateComment($comment: CreateCommentInput!) {
    createComment(comment: $comment) {
      ...ActivityFragment
    }
  }
  ${ACTIVITY_FRAGMENT}
`;
