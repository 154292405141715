import React from 'react';
import styled from 'styled-components';

import { PhotoIcon } from '@src/assets';

interface ISize {
  width?: string;
  height?: string;
}

const PlaceHolderImg = styled.div(({ width, height }: ISize) => ({
  width,
  height,
  backgroundColor: '#C7CBD1',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& > svg > g > g': {
    fill: '#FFF',
  },
}));

export interface IImageProps extends ISize {
  src?: string;
}

const ImgWrapper = styled.img(({ width, height }: ISize) => ({
  width,
  height,
}));

export const Image = ({ src, ...props }: IImageProps) => {
  return src ? (
    <ImgWrapper src={src} {...props} />
  ) : (
    <PlaceHolderImg {...props}>
      <PhotoIcon />
    </PlaceHolderImg>
  );
};
