import React, { ReactNode } from 'react';
import { Modal } from '@salesforce/design-system-react';
import { t } from 'messages';

import { Button } from '../Button';

interface IErrorModalProps {
  heading: ReactNode;
  children: ReactNode;
  isOpen: boolean;
  label?: ReactNode;
  onClose: () => void;
  footer?: ReactNode[] | false;
}

export const ErrorModal = ({
  heading,
  children,
  isOpen,
  onClose,
  label,
  footer,
}: IErrorModalProps) => (
  <Modal
    heading={heading}
    footer={
      footer || [
        <Button
          variant="brand"
          id="error-modal-cancel-button"
          onClick={onClose}
          key="close"
        >
          {label || t.common.actions.close()}
        </Button>,
      ]
    }
    isOpen={isOpen}
    ariaHideApp={false}
    dismissOnClickOutside={false}
    onRequestClose={onClose}
    disableClose={footer && true}
  >
    {children}
  </Modal>
);
