import moment from 'moment-timezone';

const DEFAULT_DATETIME_PATTERN = 'LLL';
const DEFAULT_DATE_PATTERN = 'L';
const DEFAULT_LOCALE = 'en_GB';

type DateTimeFormat = 'LLL' | 'LL' | 'L';

export const formatDateTime = (
  isoDate: string,
  timezone: string,
  locale?: string,
  format?: DateTimeFormat
) => {
  return moment(isoDate)
    .tz(timezone)
    .locale(locale ?? DEFAULT_LOCALE)
    .format(format ?? DEFAULT_DATETIME_PATTERN);
};

export const formatUserInputDate = (date: Date, format?: DateTimeFormat) =>
  moment(date)
    .startOf('day')
    .format(format ?? DEFAULT_DATE_PATTERN);

export const isIsoWeekday = (locale?: string): boolean => {
  const firstDayOfWeek = moment
    .localeData(locale ?? DEFAULT_LOCALE)
    .firstDayOfWeek();
  return firstDayOfWeek !== 0;
};

export const isISOFormat = (date: string) =>
  moment(date, moment.ISO_8601).isValid();

export const convertToISOString = (date: string) =>
  moment(date, DEFAULT_DATETIME_PATTERN).toISOString();
