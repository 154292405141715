import React from 'react';
import { DropzoneOptions } from 'react-dropzone';

import { IFileInput } from '@src/common/types';

import { Grid, GridItem } from '../Grid';

import { DragDrop } from './DragDrop';

interface IFileUploaderProps {
  dragDropOptions?: DropzoneOptions;
  allowedExtensions?: string[];
  onFilesUpload: (files: IFileInput[]) => void;
  uploadButtonTitle: string;
  gridSize?: 5 | 7;
}

export const FileUploader = ({
  dragDropOptions,
  onFilesUpload,
  uploadButtonTitle,
  gridSize,
}: IFileUploaderProps) => {
  return (
    <>
      <Grid>
        <GridItem cols={gridSize || 5}>
          <DragDrop
            options={dragDropOptions}
            onLoad={onFilesUpload}
            uploadButtonTitle={uploadButtonTitle}
          />
        </GridItem>
      </Grid>
    </>
  );
};
