import React from 'react';
import { IStep } from '@salesforce/design-system-react';

import {
  ProgressIndicator,
  EmptyProgressIndicator,
} from '../ProgressIndicator';
import { Card } from '../Card';
import { Box } from '../Box';
import { Text } from '../Text';

interface IProgressCardProps {
  heading: string;
  description: string;
  steps: IStep[];
  currentStepId?: string | number;
}

export const ProgressCard = ({
  heading,
  description,
  steps,
  currentStepId,
}: IProgressCardProps) => {
  return (
    <Card
      heading={
        <Text
          id="progress-section"
          variant="heading_small"
          weight="bold"
          mt="xx-small"
        >
          {heading}
        </Text>
      }
      description={description}
      type="darken"
    >
      <Box mt="large" />
      {currentStepId ? (
        <ProgressIndicator steps={steps} currentStepId={currentStepId} />
      ) : (
        <EmptyProgressIndicator steps={steps} />
      )}
    </Card>
  );
};
