import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { Box, IBoxProps } from '../Box';

import { GridItemCols } from './types';

interface IGridItemProps extends IBoxProps {
  cols?: GridItemCols;
  children?: ReactNode;
}

export const GridItem = ({ cols = 12, children, ...props }: IGridItemProps) => (
  <Box className={classNames('slds-col', `slds-size_${cols}-of-12`)} {...props}>
    {children}
  </Box>
);
