import React from 'react';
import styled from 'styled-components';
import { Modal } from '@salesforce/design-system-react';

import { Box, Text } from '@src/common/components';
import { t } from '@src//messages';

const Background = styled.div({
  zIndex: -1,
  position: 'absolute',
  top: 0,
  width: '100%',
  left: 0,
});

export const PermissionDenied = () => {
  return (
    <Box pt="small" pl="small" pr="small" pb="small">
      <Background>
        <div className="slds-brand-band slds-brand-band_large"></div>
      </Background>
      <Modal
        disableClose
        isOpen
        prompt="error"
        size="small"
        title="Permission Denied"
      >
        <Box pt="large" pl="large" pr="large" pb="large">
          <Text align="center">{t.common.permissionDeniedNotification()}</Text>
        </Box>
      </Modal>
    </Box>
  );
};
