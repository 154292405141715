import React from 'react';
import {
  RadioGroup as LDSRadioGroup,
  IRadioGroupProps as ILDSRadioGroupProps,
  Tooltip,
} from '@salesforce/design-system-react';
import styled from 'styled-components';

import { Box } from '../Box';
import { Text } from '../Text';
import { RequiredFieldLabel } from '../forms';

interface IRadioGroupProps extends ILDSRadioGroupProps {
  tooltip?: string;
}

const BoxWrapper = styled(Box)({
  fontWeight: 'initial',
});

export const RadioGroup = ({
  tooltip,
  labels,
  children,
  required,
  label: rawLabel,
  ...restProps
}: IRadioGroupProps) => {
  const labelText = required ? (
    <RequiredFieldLabel>{rawLabel}</RequiredFieldLabel>
  ) : (
    rawLabel
  );

  const tooltipText = tooltip ? (
    <Tooltip
      id={`tooltip-${tooltip}`}
      align="top left"
      content={tooltip}
      variant="learnMore"
    />
  ) : (
    rawLabel
  );

  const mergedLabels =
    required || tooltip
      ? {
          label: (
            <BoxWrapper display="inline-flex">
              <Box>{labelText}</Box>
              <Text color="weak">{labels?.label}</Text>
              <Box pl="x-small" display="flex">
                {tooltipText}
              </Box>
            </BoxWrapper>
          ),
        }
      : labels;
  return (
    <LDSRadioGroup labels={mergedLabels} {...restProps}>
      {children}
    </LDSRadioGroup>
  );
};
