import React from 'react';

import { t } from '@src/messages';

interface ICharatersLimitProps {
  value: string;
  maxLength?: string;
}

export const CharactersLimit = ({
  value = '',
  maxLength,
}: ICharatersLimitProps) => {
  if (!maxLength) {
    return null;
  }

  return (
    <div className="slds-form-element__label">
      {t.common.components.charactersLimit()}: {value ? value.length : '0'}/
      {maxLength}
    </div>
  );
};
