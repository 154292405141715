import {
  PhoneNumberFormat,
  PhoneNumberUtil,
  ShortNumberInfo,
} from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();
const shortInfo = ShortNumberInfo.getInstance();

interface IFormatPhoneNumber {
  countryCode?: string;
  phoneNumber?: string;
}

export const formatPhoneNumber = ({
  countryCode,
  phoneNumber,
}: IFormatPhoneNumber) => {
  if (!phoneNumber) {
    return `+${countryCode}`;
  }

  if (!countryCode) {
    // Handle old message templates that have not been saved in E164 standard
    return phoneNumber.charAt(0) === '+' ? phoneNumber : `+${phoneNumber}`;
  }

  try {
    const phone = phoneUtil.parse(`+${countryCode}${phoneNumber}`, '');
    return phoneUtil.format(phone, PhoneNumberFormat.E164);
  } catch {
    return `+${countryCode} ${phoneNumber}`;
  }
};

export const getCountryCodeAndNationalNumber = (phoneNumber: string) => {
  try {
    const number = phoneUtil.parse(`+${phoneNumber}`, '');
    return {
      countryCode: number.getCountryCode()?.toString() || '',
      nationalNumber: number.getNationalNumber()?.toString() || '',
    };
  } catch {
    return {
      countryCode: '',
      nationalNumber: '',
    };
  }
};

export const isPhoneNumberE164 = (phoneNumber: string): boolean => {
  try {
    const phoneNumberProto = phoneUtil.parse(`+${phoneNumber}`);
    return (
      phoneUtil.isPossibleNumber(phoneNumberProto) ||
      shortInfo.isValidShortNumber(phoneNumberProto)
    );
  } catch {
    return false;
  }
};
