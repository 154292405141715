import React, { useState } from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { Crop } from 'react-image-crop';
import { Modal, Button } from '@salesforce/design-system-react';
import styled from 'styled-components';

import { IFileInput } from '@src/common/types';
import { t } from '@src/messages';

import { ImageCrop } from '../ImageCrop';
import { Box } from '../Box';
import { UploadButton } from '../UploadButton';

import { DragDrop } from './DragDrop';
import { prepareImageForCrop } from './prepareImageForCrop';

interface IImageResolverOptions {
  outputWidth: number | number[];
  outputHeight: number | number[];
}

export interface IImageResolverProps {
  dragDropOptions?: DropzoneOptions;
  cropOptions?: Partial<Crop>;
  options: IImageResolverOptions;
  title: string;
  description: string;
  onFileUpload: (file: IFileInput) => void;
  onFileDropped?: (filename: string) => void;
  onFileDialogCancel?: () => void;
}

const ImageContainer = styled.div({
  backgroundColor: '#c0c0c0',
  padding: '15px',
  marginTop: '10px',
  display: 'flex',
  justifyContent: 'center',
});

type ImageBytes = string | ArrayBuffer | null;

const getMeasurement = (measurement: number | number[]): number => {
  if (typeof measurement === 'number') {
    return measurement;
  }

  return measurement[0];
};

const getImageToCrop = async (
  options: IImageResolverOptions,
  result: ImageBytes
): Promise<ImageBytes> => {
  const { outputWidth, outputHeight } = options;

  // check if result is image-base64
  if (typeof result === 'string') {
    return prepareImageForCrop(result, {
      minWidth: getMeasurement(outputWidth),
      minHeight: getMeasurement(outputHeight),
    });
  }

  return result;
};

export const ImageResolver = ({
  dragDropOptions,
  cropOptions,
  options,
  title = t.common.components.imageResolver.defaultModalTitle(),
  description = t.common.components.imageResolver.resolverDescription.bot(),
  onFileUpload,
  onFileDropped,
  onFileDialogCancel,
}: IImageResolverProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [image, setImage] = useState<ImageBytes>(null);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(null);

  const getContentFromFile = async (blob: Blob): Promise<ImageBytes> => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          resolve(!reader.result ? null : reader.result);
        },
        false
      );
      reader.readAsDataURL(blob);
    });
  };

  const postImage = async (blob: Blob, name: string) => {
    const { type: mimeType } = blob;
    const content = await getContentFromFile(blob);
    const fileInput: IFileInput = {
      name,
      mimeType,
      // Content is always a string at this point, but the reader
      // API also supports buffers. So we cast the result to what
      // we expect it to be.
      content: content as string,
      blob,
    };

    setImage(null);
    setImageBlob(null);
    setImageName(null);
    setOpen(false);

    onFileUpload(fileInput);
  };

  const uploadImage = async (blob: Blob, name: string) => {
    setImageURL(URL.createObjectURL(blob));
    await postImage(blob, name);
    URL.revokeObjectURL(imageURL || '');
  };

  const handleCancel = () => {
    setImage(null);
    setImageBlob(null);
    setImageName(null);
    setOpen(false);

    onFileDialogCancel && onFileDialogCancel();
  };

  const handleSave = () => {
    if (imageBlob && imageName) {
      uploadImage(imageBlob, imageName);
    }
  };

  const dragDropOnLoad = async (filename: string, { result }: FileReader) => {
    setImage(await getImageToCrop(options, result));
    onFileDropped && onFileDropped(filename);
  };

  return (
    <div id="image-resolver">
      <UploadButton
        title={
          imageURL
            ? t.common.components.imageResolver.replaceButtonDescription()
            : t.common.components.imageResolver.uploadButtonDescription()
        }
        onClick={() => setOpen(true)}
        id="upload-image-button"
      />
      <Modal
        heading={title}
        footer={[
          <Button onClick={handleCancel} key="cancel" id="cancel-button">
            {t.common.actions.cancel()}
          </Button>,
          <Button
            variant="brand"
            onClick={handleSave}
            disabled={imageBlob === null || imageBlob === undefined}
            key="save"
            id="save-button"
          >
            {t.common.actions.save()}
          </Button>,
        ]}
        isOpen={open}
        onRequestClose={handleCancel}
      >
        <Box pl="medium" pr="medium" pt="medium" pb="x-large">
          <p>{description}</p>
          {!image ? (
            <DragDrop options={dragDropOptions} onLoad={dragDropOnLoad} />
          ) : (
            <ImageContainer>
              <ImageCrop
                src={typeof image === 'string' ? image : image.toString()}
                options={{
                  outputWidth: options?.outputWidth,
                  outputHeight: options?.outputHeight,
                }}
                cropOptions={cropOptions}
                onCrop={(img, name) => {
                  setImageBlob(img);
                  setImageName(name);
                }}
              />
            </ImageContainer>
          )}
        </Box>
      </Modal>
    </div>
  );
};
