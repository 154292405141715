import React from 'react';
import styled from 'styled-components';

import {
  NoDataLargeSvg,
  NoDataSmallSvg,
  NoSetupLargeSvg,
  NoSetupSmallSvg,
  PageNotAvailableLargeSvg,
  PageNotAvailableSmallSvg,
  NoTemplatesLargeSvg,
  NoTemplatesSmallSvg,
  HeroSvg,
  ContactSvg,
} from '@src/assets';

import { Box } from '../Box';
import { Text } from '../Text';

type IllustrationType =
  | 'no-data'
  | 'no-setup'
  | 'page-not-available'
  | 'no-templates'
  | 'hero'
  | 'contact';

type SvgType =
  | 'no-data-large'
  | 'no-data-small'
  | 'no-setup-large'
  | 'no-setup-small'
  | 'page-not-available-large'
  | 'page-not-available-small'
  | 'no-templates-large'
  | 'no-templates-small'
  | 'hero-small'
  | 'contact-small';

export interface IIllustrationProps {
  type?: IllustrationType;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  size?: 'small' | 'large';
}

const ContentWrapper = styled.div({
  maxWidth: '400px',
});

const svgs: Record<SvgType, React.ReactNode> = {
  'no-data-large': <NoDataLargeSvg className="slds-illustration__svg" />,
  'no-data-small': <NoDataSmallSvg className="slds-illustration__svg" />,
  'no-setup-large': <NoSetupLargeSvg className="slds-illustration__svg" />,
  'no-setup-small': <NoSetupSmallSvg className="slds-illustration__svg" />,
  'page-not-available-large': (
    <PageNotAvailableLargeSvg className="slds-illustration__svg" />
  ),
  'page-not-available-small': (
    <PageNotAvailableSmallSvg className="slds-illustration__svg" />
  ),
  'no-templates-large': (
    <NoTemplatesLargeSvg className="slds-illustration__svg" />
  ),
  'no-templates-small': (
    <NoTemplatesSmallSvg className="slds-illustration__svg" />
  ),
  'hero-small': <HeroSvg />,
  'contact-small': <ContactSvg />,
};

export const Illustration = ({
  type = 'no-data',
  title,
  subtitle,
  size = 'small',
}: IIllustrationProps) => {
  const svg = `${type}-${size}`;

  return (
    <div className={`slds-illustration slds-illustration_${size}`}>
      {svgs[svg as SvgType]}
      <Box display="flex" justifyContent="center">
        <ContentWrapper className="slds-text-longform">
          {title &&
            (typeof title === 'string' ? (
              <Text
                variant="heading_medium"
                color="weak"
                align="center"
                mt="large"
              >
                {title}
              </Text>
            ) : (
              title
            ))}
          {subtitle &&
            (typeof subtitle === 'string' ? (
              <Text
                variant="body_regular"
                color="weak"
                align="center"
                mt="small"
              >
                {subtitle}
              </Text>
            ) : (
              subtitle
            ))}
        </ContentWrapper>
      </Box>
    </div>
  );
};
