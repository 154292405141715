import React, { useState, useEffect, useCallback } from 'react';
import ReactCrop, { Crop, ReactCropProps } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import type { Except } from 'type-fest';

import { uuid } from '@src/common/utils';
import { usePrevious } from '@src/common/utils/hooks';

import {
  getCroppedImg,
  minCropRequirements,
  getBase64StringMimeType,
  getMimeTypeExtension,
} from './utils';

type EnabledCropProps = Except<
  ReactCropProps,
  'onChange' | 'onComplete' | 'onImageLoaded' | 'crop'
>;

interface IImageCropProps extends EnabledCropProps {
  onCrop: (image: Blob, name: string) => void;
  cropOptions?: Partial<Crop>;
  /**
   * Image Crop options. Possible properties are outputWidth and outputHeight.
   *
   * If type of value provided for outputWidth and outputHeight is number, then
   * output image will be scaled to that value.
   *
   * If type of value provided for outputWidth and outputHeight is array of
   * numbers, then image will be scaled to keep minimum size (first item) and
   * maximum size (second item). If there is no second item in array, then
   * image won't be scaled. Crop in between min and max size will remain
   * unchanged
   *
   * @property {object} options
   * @property {number | number[]} options.outputWidth
   * @property {number | number[]} options.outputHeight
   */
  options: {
    outputWidth: number | number[];
    outputHeight: number | number[];
  };
}

const defaultCrop: Crop = {
  aspect: 1,
  unit: '%',
  width: 100,
  height: 0,
  x: 0,
  y: 0,
};

export const ImageCrop = ({
  onCrop,
  options,
  cropOptions,
  src,
  ...props
}: IImageCropProps) => {
  const initialCrop: Crop = { ...defaultCrop, ...cropOptions };
  const [crop, setCrop] = useState<Crop>(initialCrop);
  const [imageRef, setImageRef] = useState(new Image());
  const imageRefPrev = usePrevious<HTMLImageElement>(imageRef);

  const updateBlob = useCallback(
    async (
      imageElement: HTMLImageElement,
      newCrop: Crop,
      onBlobCrop: (image: Blob, name: string) => void
    ) => {
      if (imageElement && Boolean(imageElement.src) && newCrop) {
        const mimeType = getBase64StringMimeType(imageElement.src);
        const extension = getMimeTypeExtension(mimeType);
        const name = `${uuid()}.${extension}`;
        const updatedCrop = {
          ...initialCrop,
          ...newCrop,
          height: newCrop.height || 0,
        };

        if (updatedCrop.width > 0 && updatedCrop.height > 0) {
          const image = await getCroppedImg(
            imageElement,
            updatedCrop,
            mimeType,
            {
              width: options.outputWidth,
              height: options.outputHeight,
            }
          );
          onBlobCrop(image, name);
        }
      }
    },
    [initialCrop, options.outputHeight, options.outputWidth]
  );

  const handleChange = (newCrop: Crop) => {
    const { outputWidth, outputHeight } = options;

    const isResizable = !minCropRequirements({
      image: imageRef,
      crop: newCrop,
      minWidth: typeof outputWidth === 'number' ? outputWidth : outputWidth[0],
      minHeight:
        typeof outputHeight === 'number' ? outputHeight : outputHeight[0],
    });

    if (isResizable) {
      setCrop(newCrop);
    }
  };

  const handleResize = async () => {
    await updateBlob(imageRef, crop, onCrop);
  };

  const handleImageLoaded = (image: HTMLImageElement): void => {
    setImageRef(image);
  };

  useEffect(() => {
    if (imageRef && (!imageRefPrev || imageRef.src !== imageRefPrev.src)) {
      updateBlob(imageRef, crop, onCrop);
    }
  }, [imageRef, imageRefPrev, crop, onCrop, updateBlob]);

  return (
    <ReactCrop
      src={src}
      crop={crop}
      onChange={handleChange}
      onComplete={handleResize}
      onImageLoaded={handleImageLoaded}
      {...props}
    />
  );
};
