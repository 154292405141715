import React from 'react';

import { Box } from '../Box';
import { Illustration, IIllustrationProps } from '../Illustration';

export const EmptySection = ({
  title,
  subtitle,
  type = 'no-data',
  size,
}: IIllustrationProps) => {
  return (
    <Box pt="x-large" pb="x-large">
      <Illustration type={type} title={title} subtitle={subtitle} size={size} />
    </Box>
  );
};
