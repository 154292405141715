import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { PhoneFrameSvg } from '@src/assets';

type IType = 'dark' | 'light';

interface IPhoneFrameProps {
  children: ReactNode;
  type?: IType;
}

const backgroundMapper: Record<IType, string> = {
  dark: '#EBEBEB',
  light: '#E5DDD5',
};

const Wrapper = styled.div({
  fontFamily: 'AtlasGroteskWeb',
  position: 'relative',
  width: 352,
  margin: '0 auto',
});

const Content = styled.div<IPhoneFrameProps>(({ type = 'dark' }) => ({
  height: '95.5%',
  overflow: 'hidden',
  position: 'absolute',
  top: '2.3%',
  left: '4.4%',
  borderRadius: 20,
  backgroundColor: backgroundMapper[type],
}));

const ContentInner = styled.div({
  height: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  width: 320,
});

export const PhoneFrame = ({ children, type }: IPhoneFrameProps) => {
  return (
    <Wrapper>
      <PhoneFrameSvg />
      <Content type={type}>
        <ContentInner>{children}</ContentInner>
      </Content>
    </Wrapper>
  );
};
