import React, { ReactNode } from 'react';
import { DataTableCell as SFDataTableCell } from '@salesforce/design-system-react';
import styled from 'styled-components';

import { IWhatsAppMtOrMtCr, IWhatsAppMtTemplateContent } from '@shared/bff';

import { getCyItemIdentifier } from './utils';

interface ILanguagesCellProps {
  format?: (contents: IWhatsAppMtTemplateContent[]) => ReactNode;
  children?: ReactNode;
  // Note: Item is automatically sent to Salesforce LDS data cells as a prop.
  // Typescript however expects it to be sent in when it is being implemented therefor it's set as optional here.
  item?: IWhatsAppMtOrMtCr;
  formatTitle?: (contents: IWhatsAppMtTemplateContent[]) => string;
  columnId?: string;
}

const SFDataTableCellWrapper = styled(SFDataTableCell)({
  paddingLeft: '16px !important',
});

export const LanguagesCell = ({
  format,
  formatTitle,
  children,
  item,
  columnId,
  ...props
}: ILanguagesCellProps) => {
  const content = format && item ? format(item.contents) : children;
  const title = formatTitle && item ? formatTitle(item.contents) : '';

  return (
    <SFDataTableCellWrapper title={title} {...props}>
      {children && (
        <div
          {...(columnId
            ? { id: `${columnId}${getCyItemIdentifier(item)}` }
            : {})}
        >
          {content}
        </div>
      )}
    </SFDataTableCellWrapper>
  );
};

LanguagesCell.displayName = SFDataTableCell.displayName;
