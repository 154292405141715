import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Grid, GridItem } from '../Grid';

interface IComparisonValuesProps {
  value: ReactNode;
  comparisonValue?: ReactNode;
  showDiff: boolean;
  id?: string;
}

const CurrentValue = styled.div({
  wordBreak: 'break-word',
  marginRight: '0.25rem',
});

const ComparisonValue = styled.div({
  display: 'inline',
  backgroundColor: '#F6FFBB',
  padding: '5px',
  borderRadius: '5px',
  wordBreak: 'break-word',
});

export const ComparisonValues = ({
  value,
  comparisonValue,
  showDiff = false,
  id,
}: IComparisonValuesProps) => {
  return (
    <Grid>
      <GridItem cols={showDiff ? 6 : 12}>
        <CurrentValue id={id}>{value}</CurrentValue>
      </GridItem>
      {showDiff && (
        <GridItem cols={6}>
          <ComparisonValue id={`${id}-comparison`}>
            {comparisonValue}
          </ComparisonValue>
        </GridItem>
      )}
    </Grid>
  );
};
