import React from 'react';
import {
  Toast as LDSToast,
  ToastContainer,
} from '@salesforce/design-system-react';
import styled from 'styled-components';

import { IToastProps } from './types';

const ToastContainerWrapper = styled(ToastContainer)({
  pointerEvents: 'none',
});

const ToastWrapper = styled(LDSToast)({
  pointerEvents: 'all',
});

export const Toast = ({ variant, labels, onRequestClose }: IToastProps) => {
  return (
    <ToastContainerWrapper>
      <ToastWrapper
        labels={labels}
        variant={variant}
        onRequestClose={onRequestClose}
      />
    </ToastContainerWrapper>
  );
};
