import React from 'react';

import { t } from '@src/messages';

import { Box } from '../Box';
import { Card } from '../Card';
import { Illustration } from '../Illustration';

export const NotFound = () => {
  return (
    <Card hasNoHeader fullHeight>
      <Box display="flex" justifyContent="center" alignItems="center">
        <Illustration
          type="no-data"
          size="large"
          title={t.common.notFound.title()}
          subtitle={t.common.notFound.subtitle()}
        />
      </Box>
    </Card>
  );
};
