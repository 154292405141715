import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  VerticalNavigation,
  ICategory,
  ICategoryItem,
} from '@salesforce/design-system-react';

import { Channel } from '@src/common/types';
import {
  homePath,
  accountsPath,
  channelListPath,
  templateListPath,
} from '@src/common/utils';
import { t } from '@src/messages';
import { SinchBlackLogo } from '@src/assets';
import { Box } from '@src/common/components';
import { useFeatureFlags } from '@src/common/utils/hooks';

const SidebarWrapper = styled.div({
  height: '100%',
  backgroundColor: 'white',
  '& .slds-nav-vertical__item.slds-is-active .slds-nav-vertical__action': {
    textDecoration: 'underline',
    fontWeight: 'normal',
  },
});

export const Sidebar = () => {
  const { viberBusiness, kakaoTalk } = useFeatureFlags();
  const history = useHistory();
  const { pathname } = useLocation();

  const homeCategory: ICategory = {
    id: 'home',
    label: '',
    items: [
      {
        id: 'home',
        label: t.common.home(),
        url: homePath,
      },
    ],
  };

  const kakaoTalkCategory: ICategory = {
    id: 'kakaoTalk',
    label: t.common.channelNames.KakaoTalk(),
    items: [
      {
        id: `channels_${Channel.KakaoTalk}`,
        label: t.common.channels(),
        url: channelListPath(Channel.KakaoTalk),
      },
    ],
  };

  const viberCategory: ICategory = {
    id: 'viber',
    label: t.common.channelNames.ViberBusiness(),
    items: [
      {
        id: `accounts_${Channel.ViberBusiness}`,
        label: t.common.accounts(),
        url: accountsPath(Channel.ViberBusiness),
      },
      {
        id: `channels_${Channel.ViberBusiness}`,
        label: t.common.channels(),
        url: channelListPath(Channel.ViberBusiness),
      },
    ],
  };

  const whatsappCategory: ICategory = {
    id: 'whatsApp',
    label: t.common.channelNames.Whatsapp(),
    items: [
      {
        id: `accounts_${Channel.Whatsapp}`,
        label: t.common.accounts(),
        url: accountsPath(Channel.Whatsapp),
      },
      {
        id: `channels_${Channel.Whatsapp}`,
        label: t.common.channels(),
        url: channelListPath(Channel.Whatsapp),
      },
      {
        id: `templates_${Channel.Whatsapp}`,
        label: t.common.messageTemplates(),
        url: templateListPath(Channel.Whatsapp),
      },
    ],
  };

  const allCategoryItems = [
    ...homeCategory.items,
    ...kakaoTalkCategory.items,
    ...viberCategory.items,
    ...whatsappCategory.items,
  ];
  const selectedId = allCategoryItems.find(({ url }: ICategoryItem) =>
    pathname.toLowerCase().startsWith(url.toLowerCase())
  )?.id;

  const categoriesToShow = [
    homeCategory,
    ...(kakaoTalk ? [kakaoTalkCategory] : []),
    ...(viberBusiness ? [viberCategory] : []),
    whatsappCategory,
  ];

  return (
    <SidebarWrapper id="sidebar">
      <Box ml="large" mt="x-large" mb="xx-small">
        <SinchBlackLogo id="logo" />
      </Box>
      <VerticalNavigation
        categories={categoriesToShow}
        selectedId={selectedId}
        onSelect={(event, { item }) => {
          event.preventDefault();
          history.push(item.url);
        }}
      />
    </SidebarWrapper>
  );
};
