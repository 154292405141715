import React, { ReactNode } from 'react';
import { Card as SFCard } from '@salesforce/design-system-react';
import styled from 'styled-components';

import { Text } from '../Text';

type CardType = 'default' | 'darken';

const DARKEN_BACKGROUND_COLOR = '#F3F2F2';

interface IStyleProps {
  fullHeight?: boolean;
  type?: CardType;
}

interface ICardProps extends IStyleProps {
  heading?: ReactNode;
  description?: ReactNode;
  hasNoHeader?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  headerActions?: ReactNode;
  id?: string;
  secondDescription?: ReactNode;
  thirdDescription?: ReactNode;
}

const CardWrapper = styled(SFCard)(({ fullHeight, type }: IStyleProps) => {
  return {
    height: fullHeight ? '100%' : 'auto',
    backgroundColor: type === 'darken' ? DARKEN_BACKGROUND_COLOR : '#FFF',
    padding: '0.25rem 0 1rem',
  };
});

const DescriptionWrapper = styled.div({
  whiteSpace: 'initial',
});

export const Card = ({
  heading = '',
  description,
  hasNoHeader,
  fullHeight,
  icon,
  headerActions,
  children,
  id,
  secondDescription,
  thirdDescription,
  type = 'default',
  ...props
}: ICardProps) => {
  return (
    <CardWrapper
      id={id}
      hasNoHeader={hasNoHeader}
      heading={
        typeof heading === 'string' ? (
          <Text variant="heading_small" weight="bold" mt="xx-small">
            {heading}
          </Text>
        ) : (
          heading
        )
      }
      fullHeight={fullHeight}
      type={type}
      icon={icon}
      headerActions={headerActions}
      {...props}
    >
      {description && (
        <DescriptionWrapper>
          <Text variant="body_regular" mt="small" ml="medium" mr="medium">
            {description}
          </Text>
        </DescriptionWrapper>
      )}
      {children}
      {secondDescription && (
        <DescriptionWrapper>
          <Text variant="body_regular" mt="small" ml="medium" mr="medium">
            {secondDescription}
          </Text>
          <Text variant="body_regular" mt="small" ml="medium" mr="medium">
            {thirdDescription}
          </Text>
        </DescriptionWrapper>
      )}
    </CardWrapper>
  );
};
