import React, {
  useState,
  useEffect,
  ReactNode,
  ReactElement,
  useContext,
} from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { UserContext } from '../UserProvider';

interface ILaunchDarklyProviderProps {
  children: ReactNode;
}

export const LaunchDarklyProvider = ({
  children,
}: ILaunchDarklyProviderProps) => {
  const [provider, setProvider] = useState<ReactElement | undefined>(undefined);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const createLDProvider = async () => {
      const LDProvider = await asyncWithLDProvider({
        clientSideID: window.LD_CLIENT_ID,
        context: {
          key: user.userId,
          kind: 'user',
          custom: {
            enterpriseId: user.enterpriseId,
            memberId: user.memberId,
            stack: user.stack,
          },
        },
      });

      setProvider(<LDProvider>{children}</LDProvider>);
    };

    createLDProvider();
  }, []);

  if (!provider) {
    return null;
  }
  return provider;
};
