import React, { useMemo, useState, useContext } from 'react';

import { t } from '@src/messages';
import { CrState } from '@shared/bff';

import { Channel, ICrListItemFragment } from '../../types';
import {
  channelCrBotCompareBotPath,
  sortTableItems,
  formatDateTime,
  mapCrStateToType,
  isISenderCrListItemFragment,
} from '../../utils';
import {
  CellType,
  DataTable,
  IColumn,
  IDataTableSorting,
  SortDirection,
} from '../DataTable';
import { UserContext } from '../UserProvider';
import { LabelTag } from '../LabelTag';
import { EmptySection } from '../EmptySection';

interface IPendingChannelsProps {
  channelType: Channel;
  pendingChannels: ICrListItemFragment[];
  id: string;
}

interface ICrDataTableItem {
  id: string;
  state: CrState;
  name: string;
  channelId: string;
  updated: string;
}

const defaultSorting = {
  property: 'updated',
  sortDirection: SortDirection.DESCENDING,
};

export const PendingChannels = ({
  channelType,
  pendingChannels,
  id,
}: IPendingChannelsProps) => {
  const { user } = useContext(UserContext);
  const [sorting, setSorting] = useState<IDataTableSorting>(defaultSorting);

  const preparedItems = pendingChannels.map((pendingChannel) => {
    if (isISenderCrListItemFragment(pendingChannel)) {
      return {
        id: pendingChannel.id,
        state: pendingChannel.state,
        name: pendingChannel.sender.name,
        channelId: pendingChannel.senderId,
        updated: pendingChannel.updated,
      };
    } else {
      return {
        id: pendingChannel.id,
        state: pendingChannel.state,
        name: pendingChannel.bot.name,
        channelId: pendingChannel.botId,
        updated: pendingChannel.updated,
      };
    }
  });

  const [sortedItems, setSortedItems] = useState<ICrDataTableItem[]>(
    sortTableItems<ICrDataTableItem>(sorting, preparedItems)
  );

  const columns = useMemo<IColumn[]>(
    () => [
      {
        label: t.common.listings.columns.channelName(),
        property: 'name',
        width: '60%',
        type: CellType.Link,
        getPath: (item) => channelCrBotCompareBotPath(channelType, item.id),
        sortable: true,
        isSorted: sorting.property === 'name',
        sortDirection:
          sorting.property === 'name' ? sorting.sortDirection : undefined,
        columnId: `${id}-channel-name`,
      },
      {
        label: t.common.listings.columns.changeRequestStatus(),
        property: 'state',
        width: '20%',
        type: CellType.Formatted,
        formatTitle: (state: CrState) =>
          t.common.changeRequestStateLabels[state](),
        format: (state: CrState) => (
          <LabelTag
            type={mapCrStateToType(state)}
            showRaw={state === CrState.BOT_CR_UNSPECIFIED_STATE}
          >
            {t.common.changeRequestStateLabels[state]()}
          </LabelTag>
        ),
        columnId: `${id}-channel-status`,
      },
      {
        label: t.common.listings.columns.updated(),
        property: 'updated',
        width: '20%',
        type: CellType.Formatted,
        format: (item) =>
          formatDateTime(item, user.zoneInfo, user.locale, 'LL'),
        sortable: true,
        isSorted: sorting.property === 'updated',
        sortDirection:
          sorting.property === 'updated' ? sorting.sortDirection : undefined,
      },
    ],
    [sorting]
  );

  return (
    <DataTable
      items={sortedItems}
      columns={columns}
      noDataComponent={
        <EmptySection
          type="no-setup"
          title={t.common.noPendingChannels.title()}
          subtitle={t.common.noPendingChannels.description()}
        />
      }
      handleSort={(sort: IDataTableSorting) => {
        setSorting(sort);
        setSortedItems(sortTableItems<ICrDataTableItem>(sort, sortedItems));
      }}
      id={id}
    />
  );
};
