import React from 'react';
import {
  Button as LDSButton,
  IButtonProps as LDSButtonProps,
  Spinner,
} from '@salesforce/design-system-react';

interface IButtonProps extends LDSButtonProps {
  loading?: boolean;
  id?: string;
}

export const Button = ({
  loading = false,
  id,
  children,
  disabled,
  ...restProps
}: IButtonProps) => {
  return (
    <LDSButton disabled={disabled || loading} id={id} {...restProps}>
      {loading && (
        <Spinner
          size="x-small"
          variant="brand"
          containerStyle={{ display: 'contents' }}
        />
      )}
      {children}
    </LDSButton>
  );
};
