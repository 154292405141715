import { gql } from '@apollo/client';

export const ACTIVITY_FRAGMENT = gql`
  fragment ActivityFragment on Activity {
    activityId
    targetId
    targetType
    notificationTemplate
    comment
    created
    updated
  }
`;
