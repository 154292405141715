import React from 'react';

import { useFeatureFlags } from '@src/common/utils/hooks';

import { LabelTag } from '../LabelTag';

interface IChannelLimitTagProps {
  consumed: number;
  purchased: number;
  tag?: string;
  inverted?: boolean;
}

const getLabelType = (
  consumed: number,
  purchased: number,
  inverted: boolean
) => {
  if (purchased > 0 && consumed >= purchased) {
    return 'warning';
  } else if (inverted) {
    return 'inverted';
  }

  return 'default';
};

export const ChannelLimitTag = ({
  consumed,
  purchased,
  tag = '',
  inverted = false,
}: IChannelLimitTagProps) => {
  const { channelLimits } = useFeatureFlags();

  if (channelLimits) {
    return (
      <LabelTag
        type={getLabelType(consumed, purchased, inverted)}
        id={`channel-limit-${tag}`}
      >{`${consumed} / ${purchased}`}</LabelTag>
    );
  } else {
    return null;
  }
};
