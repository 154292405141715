import React, { ReactNode } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const GridWrapper = styled.div({
  height: '100%',
  width: '100%',
});

type GridFlow = 'horizontal' | 'vertical';
type GridVerticalAlignment = 'start' | 'center' | 'end';
type GridAlignment = GridVerticalAlignment | 'space' | 'spread';

interface IGridProps {
  children: ReactNode;
  flow?: GridFlow;
  alignment?: GridAlignment;
  verticalAlignment?: GridVerticalAlignment;
  stretch?: boolean;
}

export const Grid = ({
  children,
  flow = 'horizontal',
  alignment = 'start',
  verticalAlignment,
  stretch,
  ...props
}: IGridProps) => {
  const classes = classNames('slds-grid', `slds-grid_align-${alignment}`, {
    'slds-wrap': flow === 'horizontal',
    'slds-grid_vertical': flow === 'vertical' && !verticalAlignment,
    [`slds-grid_vertical-align-${verticalAlignment}`]:
      flow === 'vertical' && verticalAlignment,
    'slds-grid_vertical-stretch': stretch,
  });

  return (
    <GridWrapper className={classes} {...props}>
      {children}
    </GridWrapper>
  );
};
