import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ButtonGroup } from '@salesforce/design-system-react';

import { t } from '@src/messages';
import { useFeatureFlags } from '@src/common/utils/hooks';
import {
  IViberAspClient,
  IWhatsAppAspClient,
  IChannelLimits,
} from '@shared/bff';

import { Channel } from '../../types';
import { accountRegisterPath } from '../../utils';
import { Card } from '../Card';
import { PageHeader } from '../PageHeader';
import { Button } from '../Button';
import { Box } from '../Box';
import { ScopedNotification } from '../ScopedNotification';
import { ChannelLimitsContext } from '../ChannelLimitsProvider';
import { UserContext } from '../UserProvider';

import { PendingAspClients } from './PendingAspClients';
import { OnboardedAccounts } from './OnboardedAspClients';

const CardWrapper = styled(Card)({
  minHeight: '40vh',
});

interface IAspClientsListProps {
  channelType: Channel;
  pendingAspClients: IViberAspClient[] | IWhatsAppAspClient[];
  onboardedAspClients: IViberAspClient[] | IWhatsAppAspClient[];
}

export const AspClientsList = ({
  channelType,
  pendingAspClients,
  onboardedAspClients,
}: IAspClientsListProps) => {
  const history = useHistory();
  const { viberChannels, whatsAppChannels } = useContext(ChannelLimitsContext);
  const { allowAccountCreationWithNoPurchasedChannels } = useFeatureFlags();
  const { user } = useContext(UserContext);
  const MapChannelLimitations: Record<Channel, IChannelLimits> = {
    [Channel.ViberBusiness]: viberChannels,
    [Channel.Whatsapp]: whatsAppChannels,
    [Channel.RCS]: { consumed: 0, purchased: 0, registered: 0 },
    [Channel.KakaoTalk]: { consumed: 0, purchased: 0, registered: 0 },
  };

  const { consumed, purchased } = MapChannelLimitations[channelType];
  const noPurchasedChannels = purchased === 0;
  const channelsLimitReached = consumed >= purchased && purchased > 0;
  const readOnlyAccess = user.permissions.view && !user.permissions.update;
  const blockAccountCreation =
    noPurchasedChannels && !allowAccountCreationWithNoPurchasedChannels;
  return (
    <>
      <PageHeader
        channelType={channelType}
        sectionType="account"
        title={t.common.accounts()}
        actions={
          <ButtonGroup
            className="slds-align-middle"
            variant="list"
            id="button-group-page-header-actions"
          >
            <Button
              label={t.common.actions.newAspClient()}
              onClick={() => {
                history.push(accountRegisterPath(channelType));
              }}
              variant="brand"
              id="new-account-button"
              disabled={
                readOnlyAccess || channelsLimitReached || blockAccountCreation
              }
            />
          </ButtonGroup>
        }
      />
      {readOnlyAccess && (
        <>
          <Box mt="small" />
          <ScopedNotification variant="info">
            {t.common.readOnlyAccessNotification()}
          </ScopedNotification>
        </>
      )}
      {channelsLimitReached && !noPurchasedChannels && (
        <>
          <Box mt="small" />
          <ScopedNotification variant="warning">
            {t.common.channelLimitNotification()}
          </ScopedNotification>
        </>
      )}
      {noPurchasedChannels && (
        <>
          <Box mt="small" />
          <ScopedNotification variant="info">
            {t.common.noPurchasedAppsNotification()}
          </ScopedNotification>
        </>
      )}
      <Box mt="small" />
      <CardWrapper
        heading={t.common.listings.headers.pendingAspClients()}
        fullHeight
      >
        <PendingAspClients
          channelType={channelType}
          items={pendingAspClients}
          id="pending-asp-clients"
        />
      </CardWrapper>
      <Box mt="small" />
      <CardWrapper
        heading={t.common.listings.headers.onboardedAspClients()}
        fullHeight
      >
        <OnboardedAccounts
          channelType={channelType}
          items={onboardedAspClients}
          id="onboarded-asp-clients"
        />
      </CardWrapper>
    </>
  );
};
